.ctc-container {
    display: flex;
    align-items: center;
    background: #f7f8fe;
    border-radius: 8px;
 
  
}

.ant-input-affix-wrapper .password-input {
        border-radius: 2px;
        border: none;
        background: red;
        font-size: 28px;
     
    
}


