.zoom-meeting-modal.schedule-meeting .ant-modal-content {
    padding: 30px 0px;
    height: 80vh;
    width: 700px;
    overflow: auto;
}

.zoom-meeting-modal.schedule-meeting .ant-modal-body {
    width: 100%;
}

.zoom-meeting-modal.schedule-meeting .schedule-meeting {
    width: 520px;

    &::-webkit-scrollbar {
        width: 5px;
        height: 10%;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #ffffff49;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #ffffff7c;
    }
    &::-webkit-scrollbar-corner {
        background: #f0f0f0;
    }


}

.ant-select-item-option-selected {
    color: #000000 !important;
}

.schedule-meeting {

    h2 {
        margin-bottom: 20px;
        text-align: center;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: bold;
    }

    label {
        display: block;
        margin-bottom: 5px;
        color: #ffffff;
        font-weight: bold;
    }

    .text-white.label {
        color: #FFFFFF;
    }

    input[type="text"],
    input[type="number"],
    input[type="date"],
    input[type="time"],
    select {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 5px;
        color: #909090;
    }

    .date-select {
        width: 95%;
        padding: 8px 5px;
        border: 1px solid #909090;
        border-radius: 5px;
        color: #FFFFFF;
        background-color: #000000;
        input {
            color: #909090;
            &::placeholder {
                color: #909090;
            }
        }
        .date-arrow {
            color: #FFFFFF;
            font-size: 12px;
            cursor: pointer
        }
    }

    .date-time {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .wrapper {
            width: 100%;
            .fields-wrapper {
                // display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
    
                .fields {
                    display: inline-flex;
                    flex-direction: column;
                    width: 50%;
        
                    &:last-child{
                        color: red;
                        span{
                            margin-left: 2px;
                        }
                    }
        
                    .time-select {
                        width: 95%;
                        padding: 5px 0 0 0;
                        border: 1px solid #909090;
                        border-radius: 5px;
                        color: #909090;
                        height: 40px;
                        .ant-select-selector {
                            height: 0px;
                            border: none !important;
                            min-height: 30px;
                            background-color: #000000;
                            color: #909090;
                            .ant-select-selection {
                                color: #FFFFFF;
                            }
                            .ant-select-selection-search {
                                height: 30px;
                                margin-top: 7px;
                            }
                            .ant-select-selection-item {
                                line-height: 1;
                                height: 30px;
                                // margin-top: 7px;
                                display: flex;
                                align-items: center;
                               width: max-content;
                            }
                        }
                    }
                }
            }
        }

        input[type="date"] {
            width: 95%;
        }

        input[type="date"],
        input[type="time"]
        {
        height: 40px;
        &::-webkit-calendar-picker-indicator {
            cursor: pointer;
            background-image: url("./images/arrow-down.svg");
            background-repeat: no-repeat;
            background-size: 20px 20px;
            margin-top: -5px;
        }
    }

        input[type="time"] {
            width: 95%;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        margin-right: 8px;
        min-height: 18px;
        min-width: 18px;
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: 2px solid #CCCCCC;
        cursor: pointer;

    }

    input[type="checkbox"] {
        border-radius: 4px;

        &:checked {
            background-color: #6F2DDA;
            border-color: #6F2DDA;

            &::after {
                content: "✔";
                font-size: 14px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                line-height: 20px;
            }

        }
    }

    input[type="radio"] {
        border-radius: 50%;
        padding: 2px;
        position: relative;

        &:checked {
            border-color: #6F2DDA;

            &::after {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                background-color: #6F2DDA;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

        }
    }

    input[type="text"],
    input[type="date"],
    input[type="time"],
    select {
        background-color: transparent;
        border: 1px solid #909090;
        height: 40px;
    }

    .recurring-fields{
        display: flex;
        flex-direction: column;
        gap:5px;
        .recurring-select{
            width:100%;
            border-radius: 8px;
            .ant-select-selector,
            .ant-select-selector:hover{
                border: 1px solid #909090;
                align-items: center;
                background-color: #000000;
                color:#909090;
                border-color: #909090 !important;
            }
            .ant-select-selection-placeholder{
                color: #fff;
            }
            // .ant-input-number{
            //     .ant-input-number-input {
            //         color:red
            //     }
            //     background-color: #6A0DAD;
            //     color:red
            // }
           
            
        }
        .interval-input{
            border-radius: 8px;
            border: 1px solid #909090;
            background-color: #141414 !important;
            height:45px;
        }
        .ant-select-single {
            height: auto;
        }
    }

    .attendees-container {
        margin-bottom: 1rem;

        select {
            margin-bottom: 0;
        }

        .ant-select-selection-placeholder{
            color: #909090;
        }

    }

    fieldset {
        border: none;
        margin: 0;
        margin-bottom: 15px;
        padding: 0;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #909090 !important;

            input {
                margin: 0 !important;
            }
        }
    }

    fieldset.security-fieldset {
        flex-direction: column;
        align-items: start;
        .password-text{
            width: 112px;
            height: 40px;
        }
    }

    fieldset.timezone {
        gap: 8px;

        select {
            flex: 1;

            option {
                background-color: black;
                color: white;
            }
        }
    }

    legend {
        font-weight: bold;
        margin-bottom: 8px;
    }

    .link-container {
        display: flex;
        gap: 10px;
        border: 1px solid #909090;
        border-radius: 8px;
        height: 136px;
        align-items: start;
        justify-content: space-between;
        padding: 8px 16px;
        color: #909090;

        :first-child {
            display: flex;
            flex-direction: column;

            a {
                color: #909090;
                text-decoration: underline;
                margin-bottom: 1rem;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: end;
        margin-top: 60px;
        gap: 8px;
    
        button {
            cursor: pointer;
            background: #6A0DAD;
            color: #FFFFFF;
            padding: 8px 16px;
            border: none;
            height: 34px;
            border-radius: 25px;
        }
    
        .cancel {
            background-color: transparent;
            border: 1px solid #FFFFFF;
        }
    
        .loader {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 34px;
            background: none; 
            border: none;
        }
    }
} 
.actions .save.update.disabled-update {
    background-color: #b09cdc;
    cursor: not-allowed;
  }

.schedule-meeting-attendees {

    width: 100%;
    background-color: #141414;
    border: 1px solid #909090;
    border-radius: 8px;
    margin-bottom: 8px;

    .ant-select-clear {
        span.anticon.anticon-close-circle {
            background-color: black;

            svg {
                color: lightgoldenrodyellow;
            }
        }
    }

    .custom-tag {
        background-color: #333;
        color: #fff;
        padding: 5px 10px;
        border-radius: 4px;
        margin: 5px;
        display: inline-flex;
        align-items: center;
    }

    .custom-tag-close {
        margin-left: 8px;
        cursor: pointer;
        color: #aaa;
        transition: color 0.3s;
    }

    .custom-tag-close:hover {
        color: #fff;
    }

    .ant-select-selection-search-input {
        background-color: #141414 !important;
        color: #fff !important;
        border: none;
        min-width: 20px;
    }
    
    /* error validations */
    
    .error {
        border-color: #ff4d4f !important;
    }
    
    .error-message {
        position: absolute;
        top: 100%;
        left: 0;
        color: #ff4d4f;
        font-size: 9px;
        // transform: translateY(-4px);
        display: inline-block;
        margin-top: 2px;
        white-space: nowrap;
        height: 12px;;
    }
    
}

.ant-select-customize-input.error {
    .ant-select-selector {
        border-color: #ff4d4f !important;
    }
}

/* Css for text below password and waiting room */

.info-text {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(144, 144, 144, 1); 
    padding: 0px 25px 10px; 
}

span.zoom-meeting-text-white{
    background-color: #000000;
}


.recurring-select-option {
    background-color: #0e0e0e;
    color:#909090;
    .ant-select-item-option-content{
        color:#909090;
    }
    &.ant-select-item-option-selected{
        color:#000000 !important;
        background-color: #FFFFFF !important;
        .ant-select-item-option-content{
            color:#000000;
        }
    }
    &.ant-select-item-option-active {
        color:#909090;
        background-color: #000000;
    }
}
.recurring-select {
    &.ant-select-multiple {
        .ant-select-selection-item-remove {
            color:#909090 !important;
        }
    }
}
.recurring-select-multiple-option {
    background-color: #0e0e0e;
    color:#909090;
    .ant-select-item-option-content{
        color:#909090;
    }
    &.ant-select-item-option-active {
        color:#909090;
        background-color: #000000;
        .ant-select-item-option-content {
            color:#909090;
        }
    }
    &.ant-select-item-option-selected{
        color:#000000 !important;
        background-color: #FFFFFF !important;
        .ant-select-item-option-content{
            color:#000000;
        }
        .ant-select-item-option-state {
            color:#000000 !important;
        }
    }
}

.schedule-meeting .recurring-fields .interval-input{
    width: 50%
}

.repeat-every {
    display: flex;
    align-items: center;
    gap: 8px;
}

.success-modal-ok-button {
    background-color: #5454db;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 8px 16px;
    margin-right: 30px !important;
  }
  
  .success-modal-content {
    color: #8c8c8c;
  }
  
  .success-modal-title {
    font-weight: bold;
  }
  
.success-modal {
    background-color: #141414;
    color: #fff;
    border-radius: 8px;
  }
  
  .success-modal .ant-modal-content {
    background-color: #141414;
  }
  
  .success-modal-ok-button {
    background-color: #5454db;
    color: #FFFFFF;
    border: none; 
    padding: 10px 20px; 
    border-radius: 4px; 
    cursor: pointer; 
    transition: background-color 0.3s; 
}

.success-modal-ok-button:hover {
    background-color: #6161cd;
}

.ant-picker-dropdown {
    background-color: #000000;
    color: #FFFFFF;
    .ant-picker-panel-container {
        background-color: #000000;
        color: #FFFFFF;
        .ant-picker-panel-layout {
            background-color: #000000;
            color: #FFFFFF;
            .ant-picker-panel {
                .ant-picker-date-panel {
                    .ant-picker-header {
                        background-color: #000000;
                        button {
                            color: #FFFFFF;
                        }
                    }
                    .ant-picker-body {
                        background-color: #000000;
                        .ant-picker-cell {
                            color: #909090;
                            &.ant-picker-cell-in-view {
                                color: #FFFFFF;
                                &.ant-picker-cell-today {
                                    .ant-picker-cell-inner {
                                        &::before {
                                            border: 1px solid #6F2DDA;
                                        }
                                    } 
                                }
                                &.ant-picker-cell-range-start {
                                    .ant-picker-cell-inner {
                                        background: #6F2DDA !important;
                                    }
                                }
                                &.ant-picker-cell-range-end {
                                    .ant-picker-cell-inner {
                                        background: #6F2DDA !important;
                                    }
                                }
                            }
                            &.ant-picker-cell-disabled {
                                color: #909090;
                            }
                        }
                        button {
                            color: #FFFFFF;
                        }
                    }
                }
                .ant-picker-footer {
                    background-color: #000000;
                    a {
                        color: #FFFFFF;
                    }
                }
                .ant-picker-cell-in-view {
                    &.ant-picker-cell-selected {
                        .ant-picker-cell-inner {
                            background: #6F2DDA !important;
                        }
                    }
                }
            }
        }
    }
}
