.sidebar {
    height: calc(100vh - 80px);
    width: 357px;
    position:absolute;
    z-index: 10;
    right: 0%;
    bottom: 0%;
}

.sidebar-title {
    font-weight: 700;
    font-size: 30px;
    color: white;
}