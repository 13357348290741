main {
    min-height: 360px;
  }
  
  #left-column {
    float: left;
    width: 20%;
    margin-top: 18px;
  }
  #left-column ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  #left-column li {
    margin-bottom: 3px;
  }
  
  #right-column {
    width: 77%;
    margin-left: 22%;
    margin-top: 15px;
  }
  #right-column > .info {
    display: flex;
    justify-content: space-between;
  }
  .pagination {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .ais-RefinementList-labelText {
    margin-left: 5px;
  }
  .ais-RefinementList-searchBox {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .hit .avatar {
    float: right;
    margin-top: -5px;
  }
  .hit-email .ais-Highlight {
    word-break: break-word;
  }
  .hit-email .ais-Highlight-nonHighlighted {
    word-break: break-word;
  }
  