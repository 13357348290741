.zoom-meeting.device-test-result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-top: 20px;
  }
  
.zoom-meeting .head {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    margin-bottom: 0px;
  }
  
.zoom-meeting .result-item {
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  .zoom-meeting .result-item-speaker {
    width: 100%;
    padding: 20px 20px 0 20px;
  }
.zoom-meeting .result-title {
    font-size: 18px;
    color: #fff;
  }

  .zoom-meeting .result-title-speaker {
    font-size: 18px;
    color: #fff;
  }  
  
.zoom-meeting .success-style {
    font-size: 16px;
    color: #6F2DDA;
    display: flex;
    flex-direction: row;
  }
  
.zoom-meeting .check-mark {
    height: 25px;
    width: 25px;
    margin-left: 5px;
}

.zoom-meeting .end-button {
    color: white;
    border-radius: 25px;
    background-color: #6F2DDA;
    width: fit-content;
    align-self: center;
    margin: 15px;
    border-color: #000000;
}

.zoom-meeting .end-button:hover {
    background-color: #6F2DDA!important;
    color: white !important;
    border: 0px;
}