.meeting-room {
  background: #000000;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.meeting-room .zoom-meeting-main-content .sidebar {
  justify-content: right;
}

.zoom-meeting-content-shrink{
  height:1px;
  width:1px;
  visibility:hidden;
}

.zoom-meeting-main-content {
  height: calc(100% - 200px);
  display: flex;
}

.zoom-meeting-content-wrapper{
  display: flex;
}

.meeting-content{
  flex: 1;
  display: grid;
  grid-template-rows: calc(100% - 200px) 200px;
}

.black-theme-dropdown {
  background-color: #000000;
  color: #ffffff !important;
}

.black-theme-dropdown .ant-select-item-option {
  color: #ffffff;
  background-color: #000000;
}

.black-theme-dropdown .ant-select-item-option:hover {
  color: #000000;
  background-color: #ffffff;
}

.black-theme-dropdown .ant-select-item-option-active .ant-select-item-option-content,
.black-theme-dropdown .ant-select-item-option-selected .ant-select-item-option-content {
  color: #000000;
}
