
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "IBMPlexSans-Regular";
}

.container {
  width: 100%;
  float: left;
  position: relative;
  min-height: 100vh;
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 9;
  margin: 20px;
}

/* Navbar container */
.logo {
  float: left;
  position: absolute;
  margin: 5px 8px;
  width: 107px;
  height: 40px;
  background: transparent url("./Images/logo_PP_white_header.png") no-repeat;
  opacity: 1;
  cursor: pointer;
  background-size:contain ;
}
.logo-new {
  margin: 15px 30px;
  width: 107px;
  height: 40px;
  /* background: transparent url("./Images/logo_PP_white_header.png") no-repeat; */
  background: transparent url("./Images/logo_black.png") no-repeat;
  opacity: 1;
  cursor: pointer;
  background-size:contain;
  min-width: 290px;
  float: none;
  position: relative;
  width: calc(25% - 60px);
}

.logo-viewer {
  /* float: left; */
  left: 56px;
  position: absolute;
  margin: 5px 8px;
  width: 100px;
  height: 46px;
  background: transparent url("./Images/logo_PP_white_header.png") no-repeat;
  opacity: 1;
  cursor: pointer;
}

.header-viewer {
  font-family: "IBMPlexSans-Regular";
  height: 56px;
  background: #000;
  position: relative;
}
.app-header {
  font-family: "IBMPlexSans-Regular";
  min-height: 52px;
  background: #000;
  position: relative;
}
.header-new {
  font-family: "IBMPlexSans-Regular";
  min-height: 52px;
  /* background: #000; */
  background: #fff;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  /* box-shadow: 0px -1px 2px 0px; */
}

.navbar-wrapper{
  width: 100%;
  min-height: 51px;
  height: 71px;
  background: #fff;
  /* box-shadow: -2px 0 1px #fff, 0px -1px 2px 0px, 0px -1px 2px 0px; */
}

.footer {
  font-family: "IBMPlexSans-Regular";
  background: #000;
  /* height: 52px; */
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12vh;
}

/* Style the navigation bar */
.navbar {
  width: 75%;
  margin-left: 25%;
}
.navbar-new {
  width: 65%;
  position: relative;
  min-width: 400px;
  margin-left: 0px;
  flex-grow: 1;
}

.flex-navbar,
.team-admin-header {
  display: flex;
}

.team-admin-header {
  flex: 1;
  justify-content: center;
}

.team-admin-header-new {
  justify-content: flex-start !important;
}

.team-admin-stripe-menu {
  align-self: flex-end;
  width: 100%;
}

.team-admin-header>div {
  min-width: 10%;
}

.team-admin-header .viewer-icon {
  float: none;
  margin: 0 auto;
}

.team-admin-header-drop-down {
  margin: 10px 0 0 0;
}

.team-admin-header-name {
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.team-admin-header-name-new {
  color: #000000 !important;
}

.pipe-line {
  padding: 0 10px;
}

.pipe-line::after {
  content: " | ";
  color: gray;
  font-size: 32px;
  position: absolute;
  top: 30px;
}

/* Navbar links */
.navbar a {
  float: left;
  display: block;
  color: #fff;
  text-decoration: none;
  margin-right: 5px;
  border-bottom: 5px solid transparent;
  padding: 0px;
  transition: 0.7s;
}
.navbar-new a {
  padding: 1px 45px !important;
  height: 71px;
}


.superadmin-actionbutton {
  margin-left: 33%;
}

.showpublisher-actionbutton {
  margin-left: 33%;
}

/* Navbar links on mouse-over */
.navbar a:hover {
  /* background-color: #7070707a; */
  background-color: #1777ff0d;
}

/* Current/active navbar link */
.navbar a.active {
  background-color: #7070707a;
  border-bottom: 5px solid #fff;
}
.navbar-new a.active {
  background-color: #1777ff0d !important;
  border-bottom: 2px solid #1777ff !important;
}

.list-shows-icon {
  background-image: url("./Images/list_shows.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 30px 25px;
  background-position: center;
}

.list-shows-icon-new {
  background-image: url("./Images/list_shows-black.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 30px 25px;
  background-position: center;
}

.people-icon {
  background-image: url("./Images/People.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}
.people-icon-new {
  background-image: url("./Images/People-black.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  /* background-size: 35px 25px; */
  background-position: center;
}

.list-report-icon {
  background-image: url("./Images/report.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}
.list-report-icon-new {
  background-image: url("./Images/invite-users-black.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  /* background-size: 35px 25px; */
  background-position: center;
}

.md-icon {
  background-image: url("./Images/lm_user.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}
.md-icon-new {
  background-image: url("./Images/lm_user_black.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 22px;
  background-position: center;
}

.add-icon {
  background-image: url("./Images/Add_graphic.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.tv-icon {
  background-image: url("./Images/iconfinder_tv.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.list-icon {
  background-image: url("./Images/list.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.list-icon-new {
  background-image: url("./Images/invited-users-black.svg");
  /* background-image: url("./Images/list.svg"); */
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  /* background-size: 35px 25px; */
  background-position: center;
}

.subscribe-icon {
  background-image: url("./Images/subscribe.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.active-stream-icon {
  background-image: url("./Images/new_list_icon.svg");
  /* background-image: url("./Images/stream-black.svg"); */
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 27px 25px;
  background-position: center;
}
.active-stream-icon-new {
  background-image: url("./Images/list-stream-black.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  /* background-size: 27px 25px; */
  background-position: center;
}

.active-Show-admin-stream-icon {
  background-image: url("./Images/showAdminStreamList.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 27px 25px;
  background-position: center;
}

.active-Show-admin-stream-icon-new {
  background-image: url("./Images/showAdminStreamList_new.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 27px 25px;
  background-position: center;
}

.list-users {
  background-image: url("./Images/list_user_sa.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.publisher-icon {
  background-image: url("./Images/go_to_publisher.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.viewer-icon {
  background-image: url("./Images/go_to_viewer.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.viewer-icon-new {
  background-image: url("./Images/go_to_viewer_new.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.ppl-devices-icon {
  background-image: url("./Images/transmission.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

.vc-list-icon {
  background-image: url("./Images/vclist.svg");
  background-repeat: no-repeat;
  height: 52px;
  width: 52px;
  background-size: 35px 25px;
  background-position: center;
}

/* Nav Bar Right Image and Drop down */

.right-header-image {
  float: right;
  display: block;
  margin: 0px auto;
  border: 1px solid #fff;
  height: 42px;
  width: 42px;
  margin: 5px 10px;
  border-radius: 50%;
  background: transparent url("./Images/logo.png") 0% 0% no-repeat padding-box;
  opacity: 1;
  background-size: cover !important;
  background-position: center !important;
  z-index: 1000;
}

.right-header-image-new {
  border: 1px solid black !important;
  z-index: 0;
}

.right-image {
  float: right;
  display: block;
  margin: 0px auto;
  border: 1px solid #fff;
  height: 42px;
  width: 42px;
  margin: 5px 10px;
  border-radius: 50%;
  background: transparent url("./Images/logo.png") 0% 0% no-repeat padding-box;
  opacity: 1;
  background-size: cover;
  background-position: center;
  z-index: 1000;
}

.navbar .ui.floating.dropdown .menu {
  margin-left: -113px !important;
  width: 180px !important;
  border: 0px;
}

.ui.floating.dropdown .menu {
  margin-left: -113px !important;
  width: 180px !important;
  border: 0px;
}

.navbar .ui.floating.dropdown>.menu {
  margin-top: 0.3em !important;
  z-index: 1005;
}

.ui.floating.dropdown>.menu {
  margin-top: 0.3em !important;
  z-index: 1005;
}

.navbar .ui.dropdown .menu>.item {
  background: #000000 !important;
  color: #f4f4f4 !important;
}

.ui.dropdown .menu>.item {
  background: #000000 !important;
  color: #f4f4f4 !important;
}

.country-code .ui.selection.dropdown {
  white-space: nowrap !important;
  overflow: hidden !important;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.country-code .ui.selection.dropdown:hover {
  overflow: visible !important;
}

.country-code .ui.dropdown>.text {
  font-size: 16px;
}

.country-code .ui.dropdown .menu {
  top: 100% !important;
}

.navbar .ui.dropdown .menu>.item:hover {
  background: #45424b !important;
}

.ui.dropdown .menu>.item:hover {
  background: #45424b !important;
}

.navbar .ui.pointing.dropdown>.menu:after {
  content: none !important;
}

.ui.pointing.dropdown>.menu:after {
  content: none !important;
}

.navbar .ui.dropdown .menu {
  top: 70% !important;
  background: none !important;
  margin-right: -12px !important;
  width: 210px !important;
  z-index: 1050;
}

.ui.dropdown .menu {
  top: 70% !important;
  background: none !important;
  margin-right: -12px !important;
  width: 210px !important;
  z-index: 1050;
}

/* Container */

.left-content {
  width: 49.7%;
  float: left;
  padding-bottom: 1.5rem;
}

.left-content.ipad-view-show-list  {
  width: 35%;
}

.right-content {
  width: 50.2%;
  float: left;
  position: relative;
  padding-bottom: 1.5rem;
}

.right-container {
  margin: 5% 24px;
  position: relative;
  padding-bottom: 6.5rem;
  flex-grow: 1;
}
.right-container-new {
  padding: 5% 24px;
  position: relative;
  padding-bottom: 6.5rem;
  background: #f7f8fe;
  /* height: min-content; */
  flex-grow: 1;
  max-width: 100%;
}

.right-container-users {
  height: max-content;
}

.padding-bottom-140 {
  padding-bottom: 140px !important;
}

/* Common */

.error-message,
.error-message1,
.error-message2,
.error-message3,
.error-message4 {
  text-align: left;
  font-family: "IBMPlexSans-Regular";
  font-size: 11px;
  letter-spacing: 0.65px;
  color: #ff0000;
  opacity: 1;
  margin-left: 6px;
}

.form-field .error-border {
  border: 1px solid #ff0000 !important;
}

.success-message,
.success-message1,
.success-message2,
.success-message3,
.success-message4 {
  text-align: left;
  font-family: "IBMPlexSans-Regular";
  font-size: 11px;
  letter-spacing: 0.65px;
  color: green;
  opacity: 1;
  margin-left: 6px;
}

.font-14 {
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.alert-text-message {
  margin-top: 7px;
  margin-left: -5px;
}

.show-icon,
.show-icon1,
.show-icon2,
.show-icon3,
.show-icon4 {
  display: block;
}

.hide-icon,
.hide-icon1,
.hide-icon2,
.hide-icon3,
.hide-icon4 {
  display: none;
}

.forgot-message {
  font-family: "IBMPlexSans-Regular";
  font-size: 11px;
  letter-spacing: 0.65px;
  color: #000;
  opacity: 1;
  text-decoration: none;
  float: right;
  cursor: pointer;
}

.forgot-message:hover {
  color: #000;
}

.heading {
  margin: 72px 0px 40px;
}

.list-heading {
  margin: 63px 0 12px 14px;
}
.find-details {
  display: flex;
  justify-content: right;
  margin-right: 50px;
}
.find-details-button {
  font-weight: 600;
  font-family: "IBMPlexSans-Bold";
  font-size: 18px;
  color: #fff;
  background-color: #0096db;
  border-radius: 5px;
  border-width: 0px;
  padding: .3rem .5rem;
  transition: background-color .2s ease-out;
  outline: none;
}
.list-table-view {
  /* padding: 0 25px; */
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.list-table-view::-webkit-scrollbar {
  width: calc(100%-50px);
  height: 8px;
  background-color: #665588;
}

.list-table-view::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.list-table-view::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #707070;
  outline: 1px solid #000;
}

/* 
.list-table-view::-webkit-scrollbar:vertical {
  display: none;
} */
.heading-text {
  margin-left: 15px;
  text-align: left;
  font-family: "IBMPlexSans-Bold";
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #000000;
  opacity: 1;
}
.heading-text-new {
  margin-left: 0px;
}
.heading-text .sub-heading-text {
  text-align: left;
  font-family: "IBMPlexSans-Regular";
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #707070;
}

.heading-text-center {
  text-align: center;
  font-family: "IBMPlexSans-Bold";
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #000000;
  opacity: 1;
}

.grey-text {
  text-align: left;
  font-family: "IBMPlexSans-Regular";
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #707070;
}

.user_image {
  margin: 0px auto;
  border: 2px solid #6600ff;
  height: 98px;
  width: 98px;
  top: -30px;
  border-radius: 50%;
  opacity: 1;
}

.user_no_image {
  background: #ffffff url("./Images/pp_logoblack.svg") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

.user_list_image {
  margin: 0px auto;
  border: 2px solid #6600ff;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  opacity: 1;
}

.user_no_image {
  background: #ffffff url("./Images/pp_logoblack.svg") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

hr.top {
  border-bottom: 1.5px solid #6600ff;
}

hr.bottom {
  border: 1px solid #cdcbd1;
  top: 42px;
}

hr.terms {
  border-bottom: 1.4px solid #6600ff;
  width: 49%;
  float: left;
  margin-left: 15px;
}

.form-content {
  margin: 45px 30px;
  padding: 5px;
}

.form-field {
  clear: both;
  margin: 0 0 2em;
}
.guest-invite-field{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  gap:15px;
  margin-bottom: 25px;
}
.captcha-container{
  margin-top:30px;
  margin-bottom:30px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.guest-invite-field-name{
  width:100%;
}
.margin-50 {
  margin-right: 8% !important;
}

.form-input {
  box-shadow: 0 0 0 100px #f4f4f4 inset !important;
  border: 1px solid #707070 !important;
  border-radius: 0px !important;
  font-family: "IBMPlexSans-Regular" !important;
  font-size: 18px !important;
  letter-spacing: 1.03px !important;
  opacity: 1;
}

.form-input:disabled {
  opacity: 0.3;
  color: rgb(158, 158, 158) !important;
}

.checkbox-label{
  font-weight: 600 !important;
  opacity: 1;
}

.checkbox-button{
  margin-top: 15px;
}

.form-content .form{
  margin-top: 30px;
}

.form-input-label, .ant-form-item .ant-form-item-label > label {
  text-align: left;
  font-family: "IBMPlexSans-Medium" !important;
  /* font-size: 20px !important; */
  /* line-height: 27px !important; */
  letter-spacing: 0.86px;
  color: #707070;
  opacity: 1;
}

.form-textarea {
  box-shadow: 0 0 0 100px #f4f4f4 inset !important;
  border: 1px solid #707070 !important;
  border-radius: 0px !important;
  font-family: "IBMPlexSans-Bold" !important;
  letter-spacing: 1.2px !important;
}

.label-checkbox {
  text-align: center;
  font-family: "IBMPlexSans-Medium" !important;
  letter-spacing: 0.86px;
  color: #707070;
  opacity: 1;
  font-size: 18px;
  display: inline-block;
}

.margin-0 {
  margin: 0px;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-right-10 {
  margin: 10px;
}

.margin-right-50 {
  margin-right: 70px;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-left-30 {
  padding-left: 29%;
}

.flex {
  display: flex;
  justify-content: center;
  gap: var(--flex-gap, 0);
}

.flex.end {
  justify-content: flex-end;
}

h2 {
  margin: 30px 0 15px 50px;
  font-size: 28px;
  font-family: "IBMPlexSans-Bold";
  font-weight: 500;
}

.purple_text {
  color: #6600ff !important;
}

.bottom-lable {
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
}

.bottom-text {
  margin-top: 30px;
}

.alert-icon {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  background-image: url("./Images/alert.svg");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: 14px 13px;
  padding-left: 17px;
  font-weight: 600;
}

.body-content {
  margin: 55px 45px;
  padding: 5px;
}

.body-content p {
  font-family: "IBMPlexSans-Regular";
  font-size: 18px;
  font-weight: 500;
  color: #45424b;
}

.field-and-icon {
  width: 100%;
  float: left;
  clear: both;
  margin: 10px 0px;
}

.left-icon {
  width: 3%;
  height: 34px;
  float: left;
}

.icon-input-field {
  width: 94%;
  height: 75px;
  float: right;
  margin-top: -55px;
}

.hidden-checkbox {
  display: none !important;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -6px;
  left: -6px;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.contacheckbox-container iner:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: #6600ff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Icons */

.field-icon-tv {
  background-image: url("./Images/iconfinder_tv.svg");
  background-repeat: no-repeat;
  opacity: 1;
  /* height: 22px;
  width: 22px;
  background-size: 20px 22px;
  background-position: center; */
  height: 34px;
  width: 32px;
  background-position: bottom;
  margin-left: -4px;
}

.field-icon-dot {
  background-image: url("./Images/139-Code.svg");
  background-repeat: no-repeat;
  opacity: 1;
  /* height: 22px;
  width: 22px;
  background-size: 20px 22px;
  background-position: center; */
  height: 34px;
  width: 32px;
  margin-left: -4px;
  background-position: bottom;
}

.field-icon-subdomain {
  background-image: url("./Images/Show_Sub_Domain.svg");
  background-repeat: no-repeat;
  opacity: 1;
  /* height: 22px;
  width: 22px;
  background-size: 20px 22px;
  background-position: center; */
  height: 34px;
  width: 32px;
  margin-left: -4px;
  background-position: bottom;
}

.field-icon-customdomain {
  background-image: url("./Images/Show_custom_domain.svg");
  background-repeat: no-repeat;
  opacity: 1;
  /* height: 22px;
  width: 22px;
  background-size: 20px 22px;
  background-position: center; */
  height: 34px;
  width: 32px;
  background-position: bottom;
  margin-left: -4px;
}

.field-icon-note {
  background-image: url("./Images/note.svg");
  background-repeat: no-repeat;
  opacity: 1;
  /* height: 22px;
  width: 22px;
  background-size: 18px 19px;
  background-position: center; */
  height: 34px;
  width: 32px;
  margin-left: -4px;
  background-position: bottom;
}

.field-icon-name {
  background-image: url("./Images/name.svg");
  background-repeat: no-repeat;
  opacity: 1;
  /* height: 22px;
  width: 22px;
  background-position: center;
  background-size: 20px 22px; */
  height: 32px;
  width: 32px;
  background-position: bottom;
  margin-left: -5px;
}

.field-icon-email {
  background-image: url("./Images/email.svg");
  background-repeat: no-repeat;
  opacity: 1;
  /* height: 22px;
  width: 23px;
  margin-left: -3px;
  background-position: center;
  background-size: 23px 24px; */
  height: 32px;
  width: 40px;
  margin-left: -13px;
  background-position: center;
}

.field-icon-phone {
  background-image: url("./Images/phone.svg");
  background-repeat: no-repeat;
  opacity: 1;
  /* height: 22px;
  width: 22px;
  background-position: center;
  background-size: 18px 22px; */
  height: 34px;
  margin-left: -4px;
  width: 32px;
  background-position: bottom;
}

.field-icon-lock {
  background-image: url("./Images/lock.svg");
  background-repeat: no-repeat;
  opacity: 1;
  height: 32px;
  width: 32px;
  background-position: center;
  margin-left: -5px;
}

.user-image-icon {
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  border: 2px solid #fff;
  height: 38px;
  width: 38px;
  margin: -10px 10px;
  border-radius: 50%;
  float: left;
}

.gear-image-icon {
  background-image: url("./Images/Gear.svg");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  margin: -3px 10px;
  float: right;
  height: 25px;
  width: 23px;
  margin-top: 2px;
}

.gear-image-icon:hover {
  cursor: pointer;
}

.input-field {
  width: 90% !important;
  display: inline-block;
}

.overlay-vtry {
  position: absolute;
  width: 57%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 112px), -50%);
  -ms-transform: translate(calc(-50% - 112px), -50%);
  background-color: #000000;
  z-index: 2002;
  cursor: pointer;
  opacity: 0.5;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.pencil-on,
.eraser-on {
  background-color: #454248 !important;
  border-bottom: 3px solid #fff !important;
}

.pencil-on img,
.eraser-on img {
  filter: brightness(0) invert(1);
}

.canvas-pencil-on {
  cursor: url("./Images/pencil.png") 1 30, auto;
}

.canvas-eraser-on {
  cursor: url("./Images/erase.png") 1 30, auto;
}

.canvas-cursor-auto {
  cursor: auto;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
  cursor: pointer;
  opacity: 0.9;
}

.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.overlay-text {
  text-align: center;
  /* margin: 20px; */
  clear: both;
  display: block;
  margin-left: -60px;
  font-family: "IBMPlexSans-Medium";
  font-size: 18px;
  /* line-height: 46px; */
  letter-spacing: 1.5px;
  color: #17171a;
  opacity: 1;
}

.overlay-vtry-text {
  text-align: center;
  margin: 10px 5px;
  clear: both;
  display: block;
  padding: 2%;
  font-family: "IBMPlexSans-Medium";
  font-size: 14px;
  /* line-height: 46px; */
  letter-spacing: 1.5px;
  color: #fff;
  opacity: 1;
}

.violet-button {
  width: 280px;
  height: 50px;
  border-radius: 0 !important;
  background-color: #6600ff;
  border-radius: 0px !important;
  text-align: center !important;
  font-family: "IBMPlexSans-Regular" !important;
  letter-spacing: 1.07px;
  color: #ffffff;
  opacity: 1;
  font-size: 18px !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.violet-button:active,
.violet-button:hover {
  background-color: #000 !important;
  color: #ffffff;
  text-shadow: none;
}

.violet-button-list {
  width: 280px;
  height: 50px;
  background-color: #6600ff;
  border-radius: 0px !important;
  text-align: center !important;
  font-family: "IBMPlexSans-Regular" !important;
  letter-spacing: 1.07px;
  color: #ffffff;
  opacity: 1;
  font-size: 14px !important;
}

.violet-button-list:active,
.violet-button-list:hover {
  background-color: #000 !important;
  text-shadow: none;
}

.red-button {
  width: 90%;
  height: 50px;
  background-color: #ccc !important;
  border-radius: 0px !important;
  text-align: center !important;
  font-family: "IBMPlexSans-Regular" !important;
  letter-spacing: 1.07px;
  opacity: 1;
  font-size: 14px !important;
  margin: auto;
}

.red-button:active,
.red-button:hover {
  background-color: rgb(216, 5, 5) !important;
  color: #ffffff;
  text-shadow: none;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.inverted-violet-button {
  text-align: center !important;
  border-radius: 0px !important;
  background-color: #fff !important;
  box-shadow: 0 0 0 2px #6600ff inset !important;
  color: #000;
  margin-right: 20px;
  width: 110px;
  height: 40px;
  font-family: "IBMPlexSans-Medium";
  /* font-size: 24px;
  line-height: 32px; */
}

.inverted-violet-button:active,
.inverted-violet-button:hover {
  background-color: #6600ff !important;
  color: #fff !important;
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-left-20 {
  margin-left: 20px;
}

.div-2 {
  width: 49%;
  margin-right: 1%;
  float: left;
}

/* Side Navbar */
.left-nav {
  width: 25%;
  float: left;
  padding: 0;
  margin-top: -14px;
  /* height: 940px; */
  background-color: black;
}
.manage-show-viewers-wrapper .left-nav {
  height: unset !important;
}

.manage-show-viewers-wrapper .left-nav .publisher-invite-users {
  margin: 0 !important;
  width: 100%;
  gap: unset;
}
.left-nav-new {
  width: 25%;
  float: left;
  padding: 0;
  margin-top: -14px;
  min-height: calc(100vh - 226px);
  height: auto !important;
  background-color:none !important;
}
.left-nav-pub {
  margin-top: -30px !important;
}
.show-admin-left-nav {
  min-width: 350px;
}
.show-admin-page-layout .ant-select-single .ant-select-selector .ant-select-selection-item .ant-select-selection-placeholder {
  padding: 10px;
  min-height: 54px;
  padding-top: 10px;
}
.show-admin-page-layout {
  display: flex;
  flex-grow: 1;
}
.country-code-dropdown .ant-select-selection-search-input {
  min-height: 54px;
}
.country-code-dropdown .ant-select-selector .ant-select-selection-item {
  padding-top: 10px;
}
.right-form-container {
  width: 74%;
  float: left;
  padding: 0 10%;
  padding-bottom: 2.5rem;
}
.right-form-container-new {
  width: 74%;
  float: left;
  padding: 0 2%;
  padding-bottom: 2.5rem;
  background: #f7f8fe;
  height: -webkit-fill-available;
}

.vertical-menu {
  width: 100%;
  background-color: #000;
  padding-bottom: 20px;
  padding-top: 40px;
  height: 100%;
}
.vertical-menu-new {
  background-color: #fff !important;
  padding-top: 20px !important;
}
.vertical-menu li:last-child {
  border-bottom: 1px solid #707070;
}

.vertical-menu::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid #000;
  /* should match background, can't be transparent */
  background-color: #707070;
  height: 50px;
}

.vertical-list {
  color: #fcfcfc !important;
  display: block;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  height: 60px;
  border-top: 1px solid #707070;
  text-decoration: none;
}

.vertical-list:hover {
  background-color: #707070;
}

.vertical-list a {
  color: #fcfcfc !important;
  text-decoration: none;
  font-size: 18px;
  margin-left: 10px;
  font-family: "IBMPlexSans-Regular";
}

.vertical-list>span {
  display: flex;
}

.vertical-list > span > span.lm-user-name {
  margin-top: 10px;
  }
  .vertical-list > span > span.lm-user-container {
    height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    padding-right: 15px;
    }
.vertical-list > span > div.info{
  display: flex;
  flex-direction: column;
}
.vertical-list > span > div > div.user-info{
  height: 30px;
  white-space: nowrap; 
  max-width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vertical-list > span > div > div.user-mail{
  height: 30px;
  white-space: nowrap; 
  max-width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.vertical-list > span > img {
  min-height: 38px;
  min-width: 38px;
  margin-top: 2px;
}
.invited-users-search-result{
   color:  #fcfcfc;
   font-size: 18px;
   padding-left: 100px;
}
.margin-top-0 {
  margin-top: 0px;
}

.invite-user-left-nav-scroll::-webkit-scrollbar-track {
  background-color: #d4d4d5;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.invite-user-left-nav-scroll {
  overflow-y: auto;
 /* height: calc(100vh - 200px); */
  max-height: 665px; 
}
.vertical-header {
  color: white;
  text-align: left;
  font-size: 16px;
  display: block;
  width: 100%;
  height: 48px;
  border-top: 1px solid #707070;
  padding: 10px 2px 10px 20px;
  font-family: "IBMPlexSans-Regular";
  letter-spacing: 0px;
  color: #a9a9a9;
  opacity: 1;
}

.fixed-header-left {
  /*position: absolute;
  z-index: 10;
  width: 75% !important;*/
  background-color: #000;
}

/* .modal .actions .ui.button:first-child {
  display: none !important;
} */

/* Table View */
.table {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
}

th {
  background: #262131 !important;
  color: #f4f4f4 !important;
}

.ui.inverted.table {
  background: #fff !important;
  color: #262131 !important;
}

.ui.table tr td {
  border-top: none !important;
}

.ui.inverted.table tbody tr td.selectable:hover,
.ui.selectable.inverted.table tbody tr:hover {
  background: #6600ff !important;
}

.ui.table thead th {
  border-bottom: none !important;
}

.ui.table thead th:last-child {
  background: #262131 !important;
  color: #f4f4f4 !important;
  border-right: none;
}

.ui.inverted.table tr td:last-child {
  border-left: 5px solid #fff !important;
  }

.ui.table thead tr:first-child>th:first-child {
  border-radius: 0 !important;
}

.ui.table thead tr:first-child>th:last-child {
  border-radius: 0 !important;
}

.overflow-text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-cell-without-border {
  background-color: #f4f4f4;
  border-collapse: separate;
  border-spacing: 0 1px;
  border: 1px solid #f4f4f4 !important;
}

.custom-border {
  position: relative;
}

.custom-border:after {
  content: " ";
  position: absolute;
  border-left: 1px solid #f4f4f4;
  top: 5%;
  bottom: 5%;
  right: 0;
  height: 80%;
  margin-top: auto;
  margin-bottom: auto;
}

/* Icons */

.list-icon-name {
  background-image: url("./Images/new_user_icon.svg") !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  height: 28px;
  width: 28px;
  padding: 0 25px !important;
  background-position: center !important;
  background-size: 19px 22px !important;
}

.list-icon-user {
  background-image: url("./Images/list_user.svg") !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  height: 28px;
  width: 28px;
  padding: 0 25px !important;
  background-position: center !important;
  background-size: 28px 28px !important;
}

.list-icon-user:hover {
  cursor: pointer;
}

.delete-icon {
  background-image: url("./Images/delete.svg") !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  height: 22px;
  width: 22px;
  background-position: center !important;
  background-size: 18px 18px !important;
}

/* Auth & 2FA */
/* .auth-heading-text {
  margin: 15px;
  text-align: left;
  font-family: "IBMPlexSans-Regular";
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #000000;
  opacity: 1;
} */
/*old auth heading */

.login_image {
  background: transparent url("./Images/loginbg.jpg") 60% 100% no-repeat padding-box;
  opacity: 1;
  background-position: center;
  width: 50%;
  height: 90%;
  position: absolute;
  background-size: cover;
}

.login-terms {
  text-align: left;
  font-size: 14px;
  font-family: "IBMPlexSans-Regular";
  letter-spacing: 0px;
  color: #45424b;
}
.login-terms > b {
  margin: 0 3px;
  }

.twofa_image {
  background: transparent url("./Images/2fa_image.png") 0% 0% no-repeat padding-box;
  width: 50%;
  height: 90%;
  position: absolute;
  background-size: cover;
}

.twofa-terms {
  text-align: left;
  font-size: 14px;
  font-family: "IBMPlexSans-Regular";
  letter-spacing: 0px;
  color: #45424b;
}

/************ 13/05/20 ***********/
.auth-heading {
  margin: 50px 0px;
}

.auth-heading-text {
  text-align: center;
  font-family: "IBMPlexSans-Regular";
  font-size: 30px;
  line-height: 52px;
  letter-spacing: 1.2px;
  color: #000000;
  opacity: 1;
}

.auth-button {
  width: 100%;
  display: flex;
}

.auth-centered-buttons-3 {
  left: 16.5% !important;
}

.auth-centered-buttons-2 {
  left: 24.5% !important;
}

.auth-violet-button {
  background-color: #6600ff;
  border-radius: 0px !important;
  width: 280px;
  height: 50px;
  font-family: "IBMPlexSans-Regular" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 0.75px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.auth-violet-button:active,
.auth-violet-button:hover {
  background-color: #000 !important;
  color: #fff;
  text-shadow: none;
}

.center-button {
  width: 100%;
  justify-content: center;
  vertical-align: middle;
}

.center-button-otp {
  width: 100%;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.auth-inverted-violet-button {
  background-color: #fff !important;
  box-shadow: 0 0 0 2px #6600ff inset !important;
  color: #6600ff !important;
  width: 120px;
  height: 50px;
  font-family: "IBMPlexSans-Medium" !important;
  text-align: center !important;
  border-radius: 0px !important;
  font-size: 16px ;
  font-weight: 500 !important;
}

.auth-inverted-violet-button:active,
.auth-inverted-violet-button:hover {
  background-color: #6600ff !important;
  color: #fff !important;
}

.call-button {
  width: 170px;
}
.call-icon {
  /* position: absolute; */
 width: 30px;
 /* margin-right: -35px; */
 /* padding-top: 10px;  */
} 

@media screen and (max-width: 320px) {
  .call-button{
      font-size: 12px; /* Smaller text for narrow screens */
      padding: 6px 12px; /* Reduce padding */
      max-width: 100px; /* Adjust max width for button to wrap into two lines */
      white-space: normal; /* Allow wrapping */
  }

  .call-icon {
      display: none !important; /* Hide the icon on very small screens */
  }
}

@media screen and (max-width: 621px) {
  .call-button {
      font-size: 14px !important;/* Adjust font size */
      padding: 2px 10px !important;
      white-space: normal; /* Allow wrapping on smaller screens */
      max-width: 120px; /* Set max width to control line breaks */
      text-align: center; /* Center text */
  }
  .call-icon {
    display: none ; /* Hide icon on smaller screens */
}
}
.bold-text {
  color: #000;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 1px;
  padding: 15px;
}

.otp-resend-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 20px;
}

.form-input-number {
  box-shadow: 0 0 0 100px #f4f4f4 inset !important;
  border-radius: 0px !important;
  font-family: "IBMPlexSans-Regular" !important;
  font-size: 22px !important;
  font-weight: 600;
  letter-spacing: 1.2px !important;
  height: 46px;
  margin-top: 5px !important;
  border: 1px solid #707070 !important;
}

/* Super Admin */
.create-show {
  background: transparent url("./Images/create_show.png") 0% 0% no-repeat padding-box;
  /* opacity: 1;
  background-size: cover;
  height: 90vh;
  background-position: center; */
  opacity: 1;
  background-size: cover;
  width: 49.8%;
  height: 90%;
  position: absolute;
  background-position: center;
}

.invite_show_admin {
  background: transparent url("./Images/invite_show_admin.png") 0% 0% no-repeat padding-box;
  /* opacity: 1;
  background-size: cover;
  height: 90vh;
  background-position: center; */
  opacity: 1;
  background-size: cover;
  width: 49.8%;
  height: 90%;
  position: absolute;
  background-position: center;
}

.superadmin-list-shows {
  padding-bottom: 2.5rem;
  display: grid;
}
.superadmin-list-shows .ui.inverted.table tr td:last-child {
  border-left: none !important;
}

.superadmin-list-streams {
  padding-bottom: 2.5rem;
  display: grid;
}

.superadmin-list-streams .ui.inverted.table tr td:last-child {
  border-left: none !important;
}

.superadmin-list-heading {
  margin: 63px 0 0 14px;
  display: inline-flex;
  width: 98%;
}

.users-group-list {
  margin: 5px 0 24px 0px;
}
.users-group-list-invited-users {
  margin: 5px 0 0px 0px;
}

.superadmin-table-view {
  padding: 0 25px;
}

/* Intermediate Screen */
.intermediate_image {
  background: transparent url("./Images/intermediate_new.png") 0% 0% no-repeat padding-box;
  opacity: 1;
  background-size: cover;
  /* height: 90vh; */
  background-position: center;
  width: 35%;
  height: 90%;
  position: absolute;
}

.left-content-intermediate {
  width: 35%;
  float: left;
}

.right-content-intermediate {
  width: 65%;
  float: right;
  padding-bottom: 6.5rem;
}

.right-list-container {
  margin: 24px;
  position: relative;
  padding-bottom: 6.5rem;
}

.intermediate-list-heading {
  margin: 63px 0px 0px 3px;
}

.inetermediate-show-list {
  margin: 0 0 20px 20px;
}

.inetermediate-show-list .ui.inverted.table tr td:last-child {
  border-left: none !important;
}

.inetermediate-show-list .ui.table thead th:first-child,
.inetermediate-show-list .ui.inverted.table tr td:first-child {
  padding: 0 35px !important;
}

.play-icon {
  background-image: url("./Images/Play_Button.svg") !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  background-position: 10px !important;
  background-size: 18px 18px !important;
}

.play-icon:hover {
  background-image: url("./Images/Play_Button_hover.svg") !important;
}

.teamAdmin-img {
  width: 22px;
}

/* Show Admin */
.right-show-admin-createuser {
  margin: 24px;
  position: relative;
  padding-bottom: 6.5rem;
}

.first-name {
  width: 49%;
  margin-right: 1%;
  float: left;
}

.last-name {
  width: 49%;
  margin-left: 1%;
  float: left;
}

.list-show-users .list-content .ui.inverted.table tr td:nth-last-child(2) {
  border-left: 5px solid #fff !important;
}

.list-show-users .page-buttons {
  margin-top: 35px;
}

.list-show-users .admin-user-search {
  width: 50%;
  margin-left: 28px;
}

.country-code {
  width: 29%;
  margin-right: 1%;
  float: left;
}

.phone-number {
  width: 69%;
  margin-left: 1%;
  float: left;
}

/* Show Publisher */
.create-stream {
  background: transparent url("./Images/publisher_tv_image.png") 0% 0% no-repeat padding-box;
  opacity: 1;
  background-size: cover;
  width: 49.8%;
  height: 90%;
  position: absolute;
  background-position: center;
}

.invite-users-to-stream {
  width: 75%;
  height: 35px;
  line-height: 0px !important;
}

.semantic-date-input .field {
  display: block !important;
}

.field-icon-calender {
  background-image: url("./Images/calender.svg");
  background-repeat: no-repeat;
  opacity: 1;
  background-position: center;
  height: 34px;
  width: 32px;
  margin-left: -4px;
}

.grey-header {
  color: #707070;
}

.deep-grey-text {
  color: #17171a;
  font-weight: 400;
}

.add-image-icon {
  background-image: url("./Images/Add_graphic.svg");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  margin: 0px 10px;
  float: right;
  height: 22px;
  width: 22px;
}

.add-checkbox {
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  margin-right: 20px;
  float: right;
  height: 22px;
  width: 22px;
  padding-top: 10px;
}

.add-checkbox-header {
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  margin-right: 38px;
  margin-top: 5px;
  float: right;
  height: 22px;
  width: 22px;
}

.stream-users-list .list-heading {
  margin: 62px 0 12px -15px;
}

.list-streams-table-view {
  padding: 0 25px;
}

.right-border {
  border-right: 3px solid #fff !important;
}

.left-border {
  border-left: 2px solid #fff !important;
}

.list-pub-streams .list-content .ui.inverted.table tr td {
  border-color: #fff !important;
}

.list-pub-streams .list-content .ui.table .button-padding-0 {
  padding: 0px !important;
}

.list-pub-streams .list-content .ui.button {
  padding: 0px 10px;
}

.list-pub-streams .list-content .ui.table thead th {
  font-size: 16px;
  font-weight: 500;
}

.list-pub-streams .list-content .full-button-width {
  width: 100%;
  text-align: center !important;
}

.stream-name-th {
  background-image: url("./Images/stream_name_th.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  background-size: 24px 25px !important;
  width: 180px !important;
}

.key-th {
  background-image: url("./Images/key.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  background-size: 24px 25px !important;
  width: 180px !important;
}
#advanced-settings-th{
  background-image: url("./Images/Gear.svg")!important;;
  background-position: center !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  background-size: 24px 25px !important;
  width: 180px !important;
}

.advanced-settings-wrapper{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  gap:5px;
}
.advanced-settings-wrapper .copy-button{
  width:200px;
}

#delete-th {
  background-image: url("./Images/delete_th.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  background-size: 24px 25px !important;
  width: 180px !important;
}

.list-pub-streams .list-content .ui.inverted.table tbody tr td.selectable:hover,
.list-pub-streams .list-content .ui.selectable.inverted.table tbody tr:hover {
  /* background: #6600FF!important; */
  background: #f4f4f4 !important;
  color: black !important;
}

/* Not Found Page */
.not-found-text {
  margin-top: 20px;
  text-align: center;
  color: #000000;
  margin-left: 15px;
  font-family: "IBMPlexSans-Regular";
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  opacity: 1;
}

.un-authorised-text {
  margin-top: 20px;
  text-align: center;
  color: #ff0000;
  margin-left: 15px;
  font-family: "IBMPlexSans-Regular";
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  opacity: 1;
}

.center-link {
  cursor: pointer;
  color: #0477cc;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  font-size: 18px;
  font-family: "IBMPlexSans-Regular";
}

.eye-icon-pwd {
  position: absolute;
  right: 5px;
  margin-top: 12px !important;
  cursor: pointer;
}

.ex-icon {
  position: absolute;
  right: 5px;
  margin-top: 18px !important;
}

.emailError {
  border-color: red !important;
}

/* footer */
.footerMain {
  background: #000;
  min-height: 155px;
  /* position: absolute;
  bottom: 0;
  left: 0; */
  box-sizing: border-box;
  z-index: 100;
  width: 100%;
}

.footerRowLogo {
  margin-bottom: 100px;
  margin-top: 20px;
}

.footerLogo {
  float: left;
  position: absolute;
  margin: 5px 8px;
  height: 100px;
  width: 164px;
  background: transparent url("./Images/logo_PP_whiteFooter.png") no-repeat;
  opacity: 1;
}

.blockArea {
  width: 100%;
  position: relative;
  display: block;
}

.footerNav {
  padding: 15px 0;
  border-top: 1px solid #707070;
  margin-bottom: 20px;
}

.inlineMenu {
  padding: 0;
  list-style-type: none;
  margin: 0 auto;
}

.inlineMenu li {
  float: left;
  padding: 0px 16px;
}

.inlineMenu li.footer-text {
  color: #ffffff;
  font-size: 15px;
}

.inlineMenu li a {
  color: #ffffff;
  font-size: 15px;
  line-height: 19px;
  font-weight: normal;
  text-decoration: none;
}

.inlineMenu li a:hover {
  color: #a9a9a9;
  cursor: pointer;
}

.list-content {
  margin-bottom: 30px;
}

.margin-right-20 {
  margin-right: 20px;
}

.update-show-modal {
  background: #ffffff !important;
  padding: 2%;
}

.update-show-modal .icon-label-field {
  margin-top: -22px;
  height: 25px;
}

.update-show-modal .form-content {
  margin: 30px;
}

.update-show-modal .content {
  min-height: 535px;
  padding: 1%;
}

.update-show-modal .violet-button {
  position: initial;
  transform: none;
}

.red-button {
  background: red !important;
  color: #ffffff;
}

.terms-policy:hover {
  cursor: pointer;
}

.purple-button {
  display: flex !important;
  background-color: #6435c9 !important;
  color: #ffffff !important;
  margin: 0 auto !important;
}

/* MEDIA QUERY FOR PHONES */
@media only screen and (max-width: 768px) {

  .left-content,
  .left-content-intermediate {
    display: none;
  }

  .right-content,
  .right-content-intermediate {
    width: 95%;
    padding-bottom: 6.5rem;
  }

  .form-content {
    margin: 2%;
  }

  .form-content .ui.violet.button,
  .ui.violet.buttons {
    width: 100%;
    margin-right: 0px;
    text-align: center !important;
  }

  .form-content .ui.inverted.violet.button,
  .form-content .ui.inverted.violet.buttons .button {
    width: 100%;
    text-align: center !important;
    margin-right: 0px;
  }

  hr.terms {
    width: 90%;
  }

  .alert-icon {
    display: inline-block;
    margin-top: 15px;
  }

  .error-message {
    margin-top: 20px;
    position: absolute;
  }

  .input-field {
    width: 82% !important;
    display: inline-block;
  }

  .navbar .right-image {
    margin: 7px;
    height: 38px;
    width: 38px;
  }
}

.popup-menu {
  position: relative;
  display: inline-block;
}

.popup-menu-content {
  position: absolute;
  background-color: #000000;
  min-width: 250px;
  margin-top: 25px;
  z-index: 1900;
  border: 1px solid #d4d4d5;
}

.popup-menu-content:before {
  content: "";
  border-bottom: 10px solid #bababc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -30px;
  left: 15px;
  position: relative;
}

.volume-control {
  position: fixed;
  background: none;
  height: 50px;
  min-width: 250px;
  margin: -28px 0 0 25px;
  border: 5px;
  z-index: 1900;
  padding: auto;
}

input[type="range"] {
  background: linear-gradient(to right,
      #0477cc 0%,
      #0477cc 100%,
      #fff 0%,
      #fff 0%);
  border:5px #003860;
  border-radius: 8px;
  height: 20px;
  outline: none;
  position: fixed;
  -webkit-appearance: none;
}

img {
  width: fit-content;
  margin: auto;
  max-height: 100%;
}

.cameraMenu {
  color: black;
  background-color: white;
  height: 25px;
  width: 100px;
  padding: 15px;
  font-size: 15px;
  line-height: 19px;
  font-weight: normal;
  text-decoration: none;
}

.ui.dropdown .cameraMenu {
  top: 40px !important;
  height: auto;
  border: 1px solid #454248;
  margin-left: -100px;
}

.ui.dropdown .cameraMenu>.item .dropdown.icon {
  margin: -7px !important;
}

.cameraMenu .ui.selection.active.dropdown .menu {
  margin-left: 18px !important;
  height: auto !important;
  width: 300px !important;
  border: 1px solid #707070;
  top: 30px !important;
  left: -155px;
  white-space: nowrap;
}

.cameraMenu .ui.selection.dropdown .menu>.item {
  padding: 15px 0px !important;
  border-bottom: 1px solid #707070 !important;
  border-top: none;
  background: #000 !important;
  white-space: nowrap;
}

.cameraMenu .ui.selection.dropdown .menu>.item:last-child {
  border-bottom: none !important;
}

.cameraMenu .ui.selection.dropdown .menu>.item:first-child {
  border-top: 1px solid #707070 !important;
}

.cameraMenu .ui.selection.dropdown .menu>.item .text {
  opacity: 1;
  color: #f4f4f4;
  background: #000;
  width: 100%;
  height: 100%;
}

.cameraMenu .ui.selection.dropdown .menu>.item .text:hover {
  background: #454248;
}

.cameraMenu .centerMenuTop ul li span::before {
  content: none !important;
}

#cameraDropdown {
  color: black;
  background-color: white;
  height: 25px;
  width: 100%;
  padding: 15px;
}

#cameraDropdown.ui.dropdown:not(.button)>.default.text {
  color: #000000;
}

.invited-users-list-wrapper::-webkit-scrollbar-track {
  background-color: #d4d4d5;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.invited-users-list-wrapper{
  scrollbar-width: 20px;
  overflow-y: auto;
  max-height: 635px; 
}
.invite-users-list-header {
  display: flex;
  justify-content: space-between;
}

.invite-user-icon {
  background-image: url("./Images/add-alt.svg");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  margin: 0 27px;
  float: right;
  height: 20px;
  width: 20px;
}

.invite-user-icon:hover {
  cursor: pointer;
}

.duplicate-icon {
  float: left;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background: transparent url("./Images/copy.svg") no-repeat center;
  opacity: 1;
  margin: 0 5px 0 0;
  cursor: pointer;
  fill: #ffffff;
}

.guest-user-info .duplicate-icon {
  float: right;
}

.publisher-panel-action .disabled-action {
  pointer-events: none;
  opacity: 0.5;
}
.publisher-invite-users {
  width: 75%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.publisher-invite-users .publisher-user-search {
  border-top: 1px solid #707070;
  /*margin-top: 60px;*/
  margin-top: 10px;
  padding: 10px 2px 10px 20px;
  margin-bottom: -15px;
  display: flex;
  justify-content: space-between;
}

.publisher-invite-users .publisher-user-search .ui.search {
  width: 77%;
}

.show-admin-users .show-admin-user-search {
  border-top: 1px solid #707070;
  padding: 10px 2px 10px 20px;
  display: flex;
  justify-content: space-between;
}

.show-admin-users .show-admin-user-search .ui.search {
  width: 77%;
}

.publisher-invite-users .vertical-menu {
  padding-top: 0px;
}

.publisher-user-search .search.icon,
.show-admin-user-search .search.icon {
  right: 0 !important;
  color: #ffffff;
  font-size: 1.5em;
  opacity: 1 !important;
  width: 1em !important;
  margin-right: 25px;
}

.search-icon-wrapper {
  display: flex;
  align-items: center;
}

.publisher-user-search .times.icon,
.show-admin-users .times.icon {
  cursor: pointer !important;
  pointer-events: unset !important;
  margin-right: 25px !important;
}

.publisher-panel-search .times.icon {
  cursor: pointer !important;
  pointer-events: unset !important;
  margin-right: 25px !important;
}

.publisher-panel-search .search.icon {
  right: 0 !important;
  color: #ffffff;
  font-size: 1.5em;
  opacity: 1 !important;
  width: 1em !important;
  margin-right: 25px;
}

.publisher-panel-search .ui.search {
  width: 80%;
}

.publisher-panel-search {
  border-top: 1px solid #707070;
  padding: 10px 2px 10px 20px;
  margin-bottom: -15px;
  display: flex;
  justify-content: space-between;
}

.country-code>.dropdown .menu {
  width: 170px !important;
}

.userrole-col {
  display: flex;
  align-items: center;
}

.team-admin-image {
  margin-left: 10px;
}

.disableddiv {
  pointer-events: none;
  opacity: 0.8;
}

.showuser-button {
  width: 42%;
  margin: 2px 10px !important;
  border-radius: 0px !important;
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
}

.layout-body-wrapper {
  display: flex;
  flex-grow: 1;
}

.manage-show-viewers-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
}

.manage-show-viewers-wrapper .right-form-container {
  min-height: calc(100vh - 226px);
}

.auth-inverted-violet-button {
  width: 180px !important;
}


  /* .call-icon {
    margin-right: -105px;
  } */

/* .select-all .checkbox {
  display: flex;

} */

/* MEDIA QUERY FOR <1180px */
@media only screen and (max-width: 1180px) {
  /* .menuTopRight{
  margin-top: -56px; 
} */
}

.form-input[type="password"]::-ms-reveal {
  display: none;
}

label.mandatory::before {
  content: '* ';
  color: red;
}

.status-wrapper{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.status-header {
  margin-left: 20%;
}

.statusLinks{
  width:30%;
  margin-left: 20%;
  margin-right: 40%;
  padding:20px;
  display: flex;
  border: groove;
  flex-basis:50%;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="statusLink"] {
      width: 60%;
    }
  }

.mouse_hover:hover{
    color: green;
    text-decoration: underline;
    margin-left: 20px;
  
}

.modal-footer-show-pub {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.select-all {
  margin: 12px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.select-all-left {
  margin: 2px 4px;
}
.select-all .ui.checkbox label {
  padding-left: 0;
  padding-right: 1.85714em;
}
.add-btn-check .ui.checkbox label {
  padding-right: 0;
}

.select-all .ui.checkbox label:before, .select-all .ui.checkbox label:after {
  right: 0 !important;
  left: unset;
  border-color: #000000 !important;
}

.select-all-cb {
  margin-right: 24px;
}

.action-col-stream-list {
  display: flex;
  gap: 24px;
}

.action-icon {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

div.search-alogolia-no-list-msg {
  text-align: center;
  font-weight: bold;
}

.antd-table-new .ant-table-thead .ant-table-cell {
  background-color: #C1C4D5 !important;
}
.invite-btn .ant-btn.ant-btn-icon-only {
  width: 54px;
}

.otp-trust-device {
  margin-left: 30%;
  margin-top: 20px;
}
.trusted-device-list .ant-table-wrapper {
  margin-left: 30px;
  margin-right: 30px;
  width: 96%;
}
.trusted-device-content {
  width: 100%;
}
.trusted-device-list .ant-table-thead .ant-table-cell {
color: white !important;
}
.btn-deregister {
float: right;
height: 30px;
background: #f4f4f4;
border: 1px solid #d4d4d5;
border-radius: 6px;
cursor: pointer;
margin-top: 10px;
position: absolute;
right:2%;
}
.trusted-device-list-heading {
margin-left: 30px;
display: flex;
padding-top: 30px;
white-space: nowrap;
width: calc(100% - 60px);
}
.deregister-trust-device {
text-align: center;
display: flex;
width: 40%;
justify-content: space-between;
margin-left: 60%;
}
.btn-close{
  margin-top: 12px;
}
.trusted-device-confirm > input {
  margin-top: 10px;
  margin-right: 10px;
}
.trusted-device-confirm-btn {
  float: left;
  margin:0px 0px 30px 10px;
}
.device-name-field{
  display:flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 5px;
}
.add-device-btn-container{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.add-device-btn-container .add-device-button{
  width: 280px;
  height: 50px;
  border-radius: 0 ;
  background-color: #6600ff;
  border-radius: 0px;
  text-align: center ;
  font-family: "IBMPlexSans-Regular" ;
  letter-spacing: 1.07px;
  color: #ffffff;
  opacity: 1;
  font-size: 18px ;
}
.add-device-btn-container .add-device-button:disabled{
  background-color: #B9A4E7;
}
.device-list-container{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  gap:10px;
  width:100%;
}
.device-list-searchbar-container{
  width:100%;
display: flex;
flex-direction: column;
align-items: center;
padding:0px 30px 0px 30px;
}
.device-list-searchbar{
  display:block;
  width:100%;
  height:26px;
  border:1px solid #c4c8d8;
  border-radius: 5px;
  /* margin-left: (0px,30px,0px,30px) */
}
.add-device-modal-close{
  float:right;
}
.add-device-modal-close .close-modal-btn{
  font-weight:700;
  font-size: medium;
  border:none;
  background-color: #fff;
  cursor: pointer;
}
.bold-font {
  font-weight: bold;
}
.profile-image-property {
  border-radius: 50%;
  margin: -4px -3px;
  margin-right: 30px;
  width: 50px;
  height: 50px;
  padding: 3px
}
.empty-device-name-field{
  display:flex;
  justify-content: left;
}
.add-device-checkbox-item{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.device-tooltip-list-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:10px;
  width:100%;
}
.add-device-btn{
  margin:0px 15px 0px 0px;
}
.device-action-btn{
  width: 116px;
  height:36px;
  font-weight: 700;
  color:#fff;
  background-color: #6435C9;
  font-family: "IBMPlexSans-Regular";
  text-align: center;
  line-height: 1em;
  padding: 0%;
}
.device-action-btn:hover{
  color:#fff;
}
.device-action-btn:disabled{
  background-color: #B9A4E7;
  cursor: not-allowed;
}
.ppl-device-list .ant-table-thead .ant-table-cell {
  color: white !important;
  }
.ppl-device-list .ant-table-wrapper .ant-table-column-sorter {
  color: white !important;
}  
.ppl-device-list .ant-table-wrapper {
  margin-left: 30px;
  margin-right: 30px;
  width: 96%;
}
.delete-show-confirm.ant-modal .ant-modal-body {
  width: 90%;
}

.menuTopRight {
  img {
    width: 21.5px;
  }
}

@media (width: 1024px) {
  .inlineMenu {
    margin: 0;
    width: 72%;
  }
}

/* iPad vertical-specific styles */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (not (max-width: 767px)) {

  .Layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .twofa_image {
    background: url("./Images/2fa_image.png") no-repeat center / cover;
    width: 100%;
    height: 75%;
    position: relative;
  }

  .left-content.ipad-view-show-list {
    width: 100% !important;
  }

  .right-content {
    width: 100%;
    height: 65vh;
    overflow-y: auto;

    &.ipad-view {
      margin-top: 350px;
    }
  }
  .canvas-cursor-auto {
    padding-left: 67px;
    padding-right: 43px;
  }
  
  .right-container {
    padding: 20px;
    max-width: 90%;
    margin: 0 auto;
  }

  .auth-heading-text {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 30px 0;
  }

  .otp-resend-container p:first-child {
    display: flex;
    align-items: center;
  }  

  .user_image {
    width: 80px;
    height: 80px;
    margin: 0 auto 15px;
  }

  .form-content {
    margin-top: 20px;
  }

  .form-field {
    margin-bottom: 15px;
  }

  .lable-text {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .form-input {
    font-size: 16px;
    padding: 10px;
    height: 40px;
    width: 100%;
  }

  .auth-button {
    margin-top: 20px;
    text-align: center;
  }

  .auth-violet-button,
  .auth-inverted-violet-button {
    font-size: 16px;
    height: 45px;
    width: 100%;
    max-width: 280px;
  }

  .center-button-otp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .Button {
      margin: 5px 0;
      width: calc(50% - 5px);
    }
  }

  .bottom-lable {
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
    min-height: 160px;
  }

  .twofa-terms {
    font-size: 12px;
    line-height: 1.3;
    margin-top: 20px;
    text-align: center;
  }

  .otp-trust-device {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }

  .ui.large.modal {
    width: 90%;
    margin: 0 auto;
  }

  .ui.tiny.modal {
    width: 80%;
    margin: 0 auto;
  }

  .left-content {
    height: 40vh;
    width: 100%;
    z-index: 200;
  }

  .layout-body-wrapper {
    display: grid;
    grid-template-rows: 0 30% 0 70%;
    z-index: 100;
  }

  .layout-wrapper{
    position: fixed;
  }

  .auth-heading-text {
    font-size: 30px;
  }

  .lable-text {
    font-size: 24px;
  }

  .twofa-terms {
    font-size: 20px;
  }

  .right-content {
    height: 55vh;
    margin-top: 50px;
  }

  .bottom-lable {
    font-size: 20px;
    min-height: 50px;
  }

  .intermediate_image {
    width: 100%;
    height: 80%;
    position: relative;
  }

  p.heading-text {
    font-size: 32px;
  }

  tr {
    font-size: 20px;
  }

  .right-content-intermediate.ipad-view {
    width: 100%;
    margin-top: 40px;
  }

  input.form-input {
    border-radius: 10px !important;
    height: 60px;
  }

  .form-content {
    margin-top: 0;
    margin-bottom: 0;
  }

  .inlineMenu li {
    a {
      font-size: 18px;
    }

    &.footer-text {
      font-size: 18px;
    }
  }

  .footerNav {
    padding: 12px 0;
  }

  /* .right-header-image .ipad {
    margin: 15px 10px;
    height: 62px;
    width: 62px;
  } */

  .app-header {
    min-height: 82px;
  }

  .logo {
    margin: 15px 10px;
    width: 188px;
  }
/* 
  .profile-image-property {
    margin: 5px 15px 15px;
  } */

  

  .login_image {
    width: 100%;
    height: 26%;
  }

  .error-message.alert-text-message {
    justify-content: center;
    margin-left: 0;
  }

  .right-content.ipad-forgot-password{
   margin-top: 350px;
  }

  .right-list-container.show-selection {
    overflow-y: auto;
    overflow-x: hidden;
    height: 55vh;
    margin: 0px;
  }
}

@media only screen 
  and (device-width: 820px) 
  and (device-height: 1180px) 
  and (orientation: portrait) {
  .right-list-container.show-selection {
    height: 55vh;
    margin: 0;
    overflow: auto;
    width: 70vh;
 }
 tr {
   font-size: 15px;
 }

 .inetermediate-show-list .ui.table thead th:first-child,
 .inetermediate-show-list .ui.inverted.table tr td:first-child {
   padding: 0 0 0 35px !important;
 }
}

/* iPad Horizontal-specific styles */
/* @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-height: 1366px) 
  and (orientation: landscape) 
  and (not (max-width: 767px)) {

  .app-header {
    min-height: 72px;
  }

  .logo {
    margin: 15px 10px;
    width: 177px;
  }

  .profile-image-property {
    margin: 5px 15px 10px;
  }

  .ui.table {
    font-size: 1.2em;
  }

  .right-header-image .ipad {
    margin: 15px 10px;
  }
} */