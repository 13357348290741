.intermediate-container {
  background: #000000 url('../Images/video_bg_3.jpg') 0% 0% no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.intermediate-container .intermediate-sidemenu {
  position: fixed;
  width: 100px;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: black;
  transition: width 0.2s ease-out;
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
  gap: 24px;
  z-index: 100;
  display: grid;
  grid-template-rows: max-content auto;
  /* align-items: center; */
}

.intermediate-sidemenu .top-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.intermediate-sidemenu .bottom-content {
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 360px;
}

.intermediate-sidemenu:hover .bottom-content {
  max-width: 360px;
  overflow-y: scroll;
}

.intermediate-sidemenu .bottom-content .loading-spinner {
  width: 100px;
  display: flex;
  justify-content: center;
}

/* Custom scrollbar for WebKit browsers */
.intermediate-sidemenu .bottom-content::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.intermediate-sidemenu .bottom-content::-webkit-scrollbar-track {
  background: transparent;
  /* Background of the scrollbar track */
}

.intermediate-sidemenu .bottom-content::-webkit-scrollbar-thumb {
  background: #ffffff49;
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

.intermediate-sidemenu .bottom-content::-webkit-scrollbar-thumb:hover {
  background: #ffffff7c;
  /* Color when hovered */
}

/* Optional: Style for the scrollbar corner */
.intermediate-sidemenu .bottom-content::-webkit-scrollbar-corner {
  background: #f0f0f0;
  /* Corner color for both vertical and horizontal scrollbars */
}

.intermediate-container .intermediate-sidemenu .menu-toggle {
  padding-left: 30px;
  cursor: pointer;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap:2px; */
}

.intermediate-container .intermediate-sidemenu .menu-toggle .back-to-home-wrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 360px;
  cursor:pointer;
  gap:10px;
  transition: all 0.2s ease-out
}

.intermediate-container .intermediate-sidemenu .menu-toggle .back-to-home-wrapper .menu-toggle-label {
  font-size: 16px;
  font-weight: 500;
  color: white;
  line-height: 24px;
  margin-top: 15px;
}

.intermediate-container .intermediate-sidemenu .menu-toggle p {
  display: none;
}

.intermediate-container .intermediate-sidemenu:hover .menu-toggle p,
.intermediate-container .intermediate-container-open .menu-toggle p
{
  display: inline-block;
}

.intermediate-container .intermediate-sidemenu .menu-icon,
.intermediate-container .intermediate-sidemenu .back-chevron {
  width: 42px;
  height: 42px;
  margin: 0px;
  transform: translateY(12px);
}

.intermediate-container .intermediate-sidemenu:hover,
.intermediate-container .intermediate-container-open {
  width: 360px;
  /* height: 100vh; */
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.intermediate-container .intermediate-div {
  height: 200px;
  width: 100%;
  background-color: #000000;
  position: fixed;
}

.intermediate-container .intermediate-div-top {
  top: 0px;
  height: 80px;
  width: 100%;
  background-color: #000000;
  position: fixed;
}

.intermediate-container .intermediate-div-bottom {
  bottom: 0px;
  height: 200px;
  width: 100%;
  background-color: #000000;
  position: fixed;
}

.intermediate-container .intermediate-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(20, 20, 20, 0.6) 30%, rgba(20, 20, 20, 0) 100%);
}

.intermediate-container .intermediate-content .welcome-text {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  margin: 0px 0px 4px 0px;
}

.intermediate-container .intermediate-content .stream-selection-text {
  font-size: 32px;
  color: white;
  font-weight: bold;
  margin: 0px 0px 24px 0px;
}

.intermediate-container .intermediate-content .hover-instruction-text {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px 0px 24px 0px;
}

.intermediate-container .streams-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
}

.intermediate-container .streams-container .stream-button {
  width: 42px;
  height: 42px;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  min-width: 42px;
  min-height: 42px;
  background-color: #1d1d1d;
}

.intermediate-container .intermediate-sidemenu .intermediate-separator {
  height: 1px;
  background-color: #282829;
  width: 70px;
  margin: 0px 16px 0px 16px;
  box-sizing: border-box;
}

.intermediate-container .intermediate-sidemenu:hover .intermediate-separator,
.intermediate-container .intermediate-container-open .intermediate-separator {
  width: 320px;
}

.intermediate-container .intermediate-sidemenu .section-title {
  letter-spacing: 2px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  padding-left: 16px;
}

/* .show-new-viewer .sidemenu-item:hover .stream-button {
  background-color: #282829;
}

.show-new-viewer .sidemenu-item:hover .stream-button img {
  filter: brightness(0.7);
}

.show-new-viewer .stream-selected-active .stream-button {
  background-color: #6a29ff !important;
}

.show-new-viewer .stream-selected-active .stream-button img {
  filter: brightness(1) !important;
}

.show-new-viewer .stream-selected-inactive .stream-button img {
  filter: brightness(0.5);
}

.show-new-viewer .sidemenu-stream-name {
  font-size: 16px;
  font-weight: 700;
}

.show-new-viewer .stream-selected-inactive .sidemenu-stream-name {
  color: #ccc;
} */

/* css for no meetings text before hover */


.intermediate-container .intermediate-sidemenu .meeting-list.no-meetings {
  display: none;
}

.intermediate-container .intermediate-sidemenu:hover .meeting-list.no-meetings,
.intermediate-container .intermediate-container-open .meeting-list.no-meetings {
  display: block;
}