@charset "utf-8";
/* CSS Document */
@media only screen and (max-width:1839px) {
  .imageGroup {
    padding: 0px 40px;
  }
  .imageGroup img {
    max-width: 100%;
  }
  .form-bottom .wraparea, .contentList-bottom .wraparea {
    padding: 0px 40px;
  }
  .areaListContent ul li p {
    margin-top: 15px;
    letter-spacing: -.02em;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding-bottom: 0;
  }
  .areaListContent ul li {
    margin-bottom: 20px;
  }
  
  .banner-main .captionText h1 {
	top: 70px;
}
.section01 .content-area img {
	min-width: 500px;
	margin: 0px 80px;
}
.dataAreaNewDesign .contentRight {
	max-width: 700px;
}
}
@media only screen and (max-width:1599px) {
  .form-bottom .wraparea, .contentList-bottom .wraparea {
    padding: 0px 15px;
  }
  .imageGroup {
    padding: 0px 15px;
  }
  .buttonGroup button {
    margin-left: 15px;
  }
  .main-nav li {
    padding: 0px 15px;
  }
  .form-bottom .wraparea .half:last-child {
    max-width: 600px;
  }
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
  h2 {
    font-size: 40px;
  }
  .section01 .content-area h2 {
    margin-bottom: 15px;
    line-height: 1;
  }
  .section01 .imagewrap {
    margin-right: 50px;
  }
  .section01 .border-content p {
    font-size: 15px;
    line-height: 1.4;
  }
  .logosCenter .logosItem {
    margin: 0px 50px;
  }
  .video {
    max-width: 700px;
  }
  .videoSection .headingText {
    max-width: 900px;
  }
  .videoSection .headingText img {
    max-width: 100%;
  }
  .devicesArea ul li {
    padding: 0px 135px;
  }


  .form-bottom .wraparea .half img {
    margin-bottom: 0;
    max-width: 85px;
  }
  .copyrightArea ul li {
    margin-right: 40px;
  }
  .support {
    padding: 160px 0 140px;
  }
  h3 {
    font-size: 25px;
  }
  .contentList-bottom .wraparea .textArea p {
    margin-top: 30px;
  }
}
@media only screen and (max-width:1365px) {
  .main-nav {
    margin-left: 50px;
  }
  .main-nav li {
    padding: 0 7px;
  }
  .main-nav li a {
    font-size: 14px;
  }
  .banner-main .captionText a {
    font-size: 13px;
    padding: 15px 25px;
  }
  .banner-main .captionText h1 {
    margin-top: 0;
    font-size: 30px;
    line-height: 40px;
  }
  .section01 .content-area h3 {
    margin-bottom: 5px;
  }
  .section03 {
    background-size: 1722px 2070px;
    background-position: calc(-141px) calc(-488.27px);
  }
  .headingText p {
    font-size: 20px;
  }
  .areaListContent ul li p {
    font-size: 16px;
  }
  .sliderArea .item {
    padding-left: 10px;
  }
  .sliderArea .item img{
    width: 300px;
  }
  .section05 {
    background-size: 1235px 824px;
    background-position: calc(0px) calc(-333.15px);
  }
  .section05 .textareaCenter p {
    font-size: 20px;
    line-height: 1.4;
    padding: 25px;
  }
  .section06 .textareaCenter p {
    font-size: 22px;
  }
  .devicesArea ul li {
    padding: 0px 70px;
  }

  .copyrightArea ul li a, .copyrightArea p {
    font-size: 12px;
  }
  .form-bottom .wraparea .half:last-child {
    max-width: 400px;
  }
  .main-logo img {
    max-width: 100px;
  }
  .buttonGroup button {
    margin-left: 10px;
    padding: 12px 15px;
    font-size: 10px;
  }
  .areaListContent ul {
    padding: 0;
    margin: 0;
  }
  .video {
    max-width: 500px;
    margin-top: 40px;
  }
  .section06 {
    padding: 50px 0;
  }
  .copyrightArea {
    align-items: center;
  }
  .form-bottom h2 {
    font-size: 30px;
    line-height: 40px;
    margin-top: 15px;
  }
  .section01 {
    padding-bottom: 70px;
  }
  h2 {
    font-size: 30px;
  }
  .supportData .dataText h2 {
    margin-bottom: 25px;
    font-size: 35px;
    margin-bottom: 0;
  }
  .supportData .dataText p {
    font-size: 17px;
    line-height: 26px;
  }
  .form-bottom .wraparea input[type="submit"] {
    width: 185px;
    height: 50px;
  }
  .footer-main img {
    max-width: 100px;
  }
}
@media only screen and (max-width:1199px) {
  .main-nav ul {
    padding: 0 15px;
  }
  .main-nav li a {
    font-size: 14px;
  }
  .buttonGroup button {
    margin-left: 10px;
    padding: 14px 20px;
    font-size: 13px;
  }
  .main-logo img {
    max-width: 120px;
  }
  .main-header .wraparea.flex {
    align-items: center;
  }
  body {
    padding-top: 90px;
  }
  .banner-main .captionText h1 {
    margin-top: 0;
    font-size: 40px;
    line-height: 50px;
  }
  .banner-main .captionText a {
    font-size: 15px;
    padding: 15px 35px;
  }

  .section01 .imagewrap {
    box-shadow: 15px 15px 0px #4D089A;
  }
  .flex {
    align-items: flex-start;
  }
  .section01 .content-area h2 {
    font-size: 25px;
  }
  .section01 .border-content p {
    font-size: 15px;
  }
  .logosCenter .logosItem img {
    max-height: 60px;
    max-width: 150px;
  }
  .logosCenter {
    margin-top: 45px;
  }
  .section02 {
    padding: 50px 15px;
  }

  .contentList-bottom .wraparea .textArea p {
    margin-top: 10px;
    font-size: 17px;
    line-height: 20px;
  }
  .contentList-bottom .wraparea .textArea * {
    color: #FFF;
    font-size: 18px;
  }
  .contentList-bottom .wraparea .textArea img {
    margin-bottom: 20px;
    max-height: 50px;
  }
  .supportData {
    padding: 0px 50px;
  }

  .supportData img {
    min-width: 195px;
  }

  .devicesArea ul li span {
    font-size: 15px;
    margin-top: 20px;
  }
  .contentList-bottom {
    padding-top: 0;
  }
  .banner-main .captionText a{
	  bottom:65px;
  }
  .supportData .dataText h2 {
	margin-bottom: 25px;
}
}
@media only screen and (min-width:768px) {
  .mobile {
    display: none !important;
  }
}
@media only screen and (max-width:767px) {
  .pp-live-video {
    height: 190px;
  }
  .pp-live-video video {
    width: 320px;
  }
  .main-header .flex {
    justify-content: start;
  }
  .main-logo {
    margin: 0 auto;
  }
  .main-nav {
    display: none;
  }
  .sliderArea .item {
    width: 100%;
    padding-left: 0px !important;
    padding-top: 10px;
  }
	.textStyle h3{
		font-size:18px;
	}
	.textStyle h2{
		margin-bottom:20px;
	}
  .mobile {
    display: none !important;
  }
  .toogleBtn.mobile{
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
  .main-nav .buttonGroup.mobile {
    display: block !important;
  }
	.main-nav.open {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
	.main-nav ul {
    padding: 0;
    margin: 0 auto 25px;
  }
	.main-nav ul li{
		width: 100%;
	}
	.main-nav li::before {
    display: none;
  }
	.buttonGroup button {
    margin-left: 0;
    padding: 12px 15px;
    width: 100%;
    margin-bottom: 15px;
    font-size: 15px;
  }
	.main-nav li a {
    font-size: 15px;
  }
  .toogleBtn.mobile {
    background: none;
    border: 0 none;
    cursor: pointer;
  }
  .banner-main img.desktop {
    display: none;
  }
  
.banner-main {
	position: relative;
	height: auto !important;
  padding: 210px 0;
}
.banner-main .captionText {
  margin-left: 0px;
  max-width: 100%;
  position: absolute;
  transform: translate(0);
  text-align: center;
  padding: 0px ;
}
.banner-main .captionText h1 {
	margin-top: 0;
	font-size: 33px !important;
	line-height: 1.2;
	padding: 0 15px;
}
.banner-main .captionText .logoInDemo {
  left: 30%;
  top: 61%;
  position: absolute;
}
.banner-main .logoInDemo img {
  min-width: 150px;
  height: 90px;
}
  .banner-main .captionText a {
    bottom: auto;
	  top: 365px;
    font-size: 13px;
    padding: 15px 25px;
  }
  .banner-main img.mobile {
    max-width: 90%;
    margin: 0 auto 40px;
  }
  .section01 .wraparea.flex {
    flex-direction: column;
  }
  .section01 .content-area {
    width: 95%;
    flex-direction: column;
  }
  .section01 .imagewrap {
    flex: 0 0 90%;
    width: 90%;
    margin-right: 50px;
    margin: 0 auto 60px;
  }
  .section01 .border-content::after {
    bottom: 0px;
    content: "";
    left: 50%;
    margin-left: -50px;
  }
  .section01 .border-content {
    padding: 0 0 40px;
    width: auto;
  }
  .section02 h2 {
    font-size: 30px;
    line-height: 1;
    margin-bottom: 40px;
  }
  .logosCenter {
    margin-top: 20px;
    flex-direction: column;
  }
  .logosCenter .logosItem {
    margin: 0px 30px 40px;
  }
  .logosCenter .logosItem:last-child {
    margin-bottom: 0px;
  }
  h2 {
    font-size: 26px;
  }
  .headingText p {
    font-size: 20px !important;
    line-height: 1.4;
  }
  .areaListContent ul li p {
    margin-top: 15px;
    font-size: 15px;
  }
  .section03 {
    background-size: 1939px 2500px;
    background-position: calc(-558.5px) calc(-491.32px);
  }
  .areaListContent .dataDiv {
    width: calc(100% - 65px);
    margin: 0 auto;
  }
  .areaListContent ul li {
    margin-bottom: 50px;
  }
  .section03 .mobile {
    max-width: 90%;
    margin: 70px 5% 40px;
  }
  .section05 .textareaCenter {
    margin: 70px auto 0;
  }
  .section05 .textareaCenter h2 {
    font-size: 24px;
  }
  .section05 .textareaCenter p {
    font-size: 18px;
    line-height: 1.4;
    margin-top: 10px;
  }
  .headingText p {
    padding: 0 15px !important;
  }
  .contentList-bottom .wraparea {
    flex-direction: column;
  }
  .contentList-bottom .wraparea .textArea {
    max-width: 100%;
    margin-top: 20px;
  }
  .contentList-bottom .wraparea .textArea h3 {
    font-size: 22px;
  }
  .contentList-bottom .wraparea .textArea p {
    font-size: 14px;
    margin-top: 25px;
  }
  .videoSection .headingText p {
    padding: 0px 0;
  }
  .devicesArea ul {
    flex-direction: column;
  }
  .devicesArea ul li {
    padding: 10px;
    text-align: center;
  }
  .devicesArea ul li span {
    font-size: 12px;
  }
  .collaborate {
    width: 95%;
    margin: 0 auto;
  }
  .supportData {
    flex-direction: column;
    padding: 0 15px;
    text-align: center;
  }
  .supportData img {
    min-width: inherit;
    margin: 0 auto;
    max-width: 300px;
  }
  .supportData .dataText h2 {
    font-size: 30px;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 25px;
  }
  .supportData .dataText p {
    font-size: 15px;
    line-height: 30px;
  }
.support {
	background-size: auto;
	background-size: cover;
	padding: 0 0 400px;
}
.pricing, .prices {
  padding: 0 0 0 7px!important;
}
.prices .book_demo {
  left: 33%;
}
.pricing_video video {
  object-fit: fill !important;
  height: 650px;
  width: 500px;
}
.section06 {
	padding: 50px 0 0;
}
.section06 h2 {
	font-size: 33px;
}
	.form-bottom .wraparea {
	display: flex;
	justify-content: center;
	flex-direction: column;
	justify-items: center;
}
	.form-bottom h2 {
	text-align: center;
}
	.form-bottom .wraparea .half img {
	margin-bottom: 0;
	max-width: 85px;
	margin: 0 auto 25px;
	display: table;
}
	.form-bottom .wraparea input[type="submit"] {
	font-size: 15px;
	height: 50px;
	width: 100%;
		margin-top: 0px;
}
	.form-bottom .wraparea label {
	font-size: 15px;
	line-height: 24px;
}
	.copyrightArea {
	align-items: flex-start;
	flex-direction: column;
}
	.copyrightArea ul li {
	margin-right: 0;
	width: 100%;
	margin-bottom: 15px;
	}
	.copyrightArea p {
	margin-top: 40px;
}
	.footer-main {
    padding-left: 15px;
    padding-right: 15px;
		
}
.areaListContent ul li h3 {
	letter-spacing: 0;
}
.section05 {
  width: 100%;
	height: auto !important;
  margin: 0;
  padding: 180px 0 !important;
	/* background-size: 800px !important; */
	background-position: center center !important;
	background-size: cover !important;
}
body {
	padding-top: 70px;
}
.hideM {
	display: none;
}
.section01 .content-area img {
	min-width: inherit;
	margin: 0px 25px;
	margin-bottom: 40px;
}
.section01 .border-content::after {
	display: none;
}
.section01 {
	padding: 0;
  margin-top: 40px;
}
.section03 .hideD {
	margin: 0 auto;
	display: table;
	max-width: 100%;
}
.dataAreaNewDesign {
	padding-bottom: 20px;
}
.dataAreaNewDesign {
	flex-direction: column;
}
.section06 img {
	max-width: 100%;
	margin: 0 0 40px;
  width: auto;
  height: auto;
}
.section06 {
	padding: 50px 20px 0;
}
.contentList-bottom {
  margin-left: 0px;
}
.collaborate p {
  padding: 0px !important;
}
.dataAreaNewDesign .contentRight .headingText {
	max-width: 100%;
	text-align: center;
	margin-bottom: 0;
}
.headingText h2 {
	font-size: 35px !important;
}
.form-bottom .wraparea .half:last-child {
	max-width: 100%;
}
.section03::before, .section03::after {
	height: 10px;
	background-size: cover;
}
.section04 {
  margin: 70px 0 0;
  padding: 0;
}
.section04 .speed_video {
  overflow: unset;
  max-height: 250px;
}
.section04 .headingText {
  margin: 0;
}
.speed_video video {
  width: auto;
  height: auto;
}
.section04 h2 img {
	max-height: 19px;
}
}
@media only screen and (max-width:768px){
	.section01 .content-area img.hideD {
	display: none;
}
.section03 img.hideD {
	display: none !important;
}
}
@media only screen and (max-width:768px) {
  .main-nav li a {
    font-size: 11px;
  }
}