.showadmin-list-heading {
    margin: 5px 0 0 14px;
    display: inline-flex;
    width: 98%;
  }
  .excel-button{
      justify-content: right;
      background: transparent url("../../Images/csv-export.png") 0% 0% no-repeat
        padding-box;
      opacity: 1;
      background-size: cover;
      width: 32px;
      height: 32px;
      z-index: 1;
      background-position: center;
      cursor: pointer;
      margin-top: 6px;
    }
  .inviteViewerlist-heading{
      width: 97%;
  }
  @media only screen and (max-width: 430px){
      .inviteViewerlist-heading {
          width: 87%;
      }
      }
      @media only screen and (max-width: 768px) {
        .inviteViewerlist-heading{
          width: 90%;
        }
      }
      @media only screen and (max-width: 1024px) and (min-width: 766px) {
        .inviteViewerlist-heading{
          width: 95%;
        }
      }
      @media only screen and (min-width: 1445px){
        .inviteViewerlist-heading{
          width: 99%;
        }
      }
  