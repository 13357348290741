.zoom-meeting-screen-share-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    padding: 16px;
}

.zoom-meeting-screen-share-video {
    width: 100%;
    max-width: none;
    /* height: 100%; */
}