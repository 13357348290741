.ui.modal>.actions{
 border: none;
 background: #fff;
}
.heading-center{
    text-align: center;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: bolder;
    margin: 20px auto;
    color: #000;
    font-family: "IBMPlexSans-Regular";
}
.content-wrapper{
    margin: 50px;
}
.content-wrapper p{
    text-indent: 30px;
    color: #000;
    font-family: "IBMPlexSans-Regular";
    margin: 12px 0px 0px;
}
.content-wrapper ol{
    padding: 0px 15px;
    color: #000;
}
.content-wrapper ol li{
    text-indent: 30px;
    color: #000;
    font-family: "IBMPlexSans-Regular";
    margin: 30px 0px;
}
.content-wrapper ul li{
    text-indent: 30px;
    list-style-type: none;
    margin:10px 0px;
} 