/* el newshowvieweeer */

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap");

*:not(i) {
  font-family: "Nunito Sans", sans-serif !important;
}

.show-new-viewer {
  background: #000000 url("../../Images/video_bg_1.jpg") 0% 0% no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.tvos-header-icon {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #1d1d1d;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  visibility: hidden;
}
.participant-image {
  width: 75px;
  height: 75px;
  position: relative;
  bottom: 15px;
}
.show-new-viewer .chat-icon {
  background-image: url("../../Images/chat_icon.svg") !important;
  background-repeat: no-repeat;
  background-size: 20px 18px !important;
  background-position: 10px !important;
}

.show-new-viewer .cross-icon {
  background-image: url("../../Images/cross_icon.svg") !important;
  background-repeat: no-repeat;
  background-size: 25px 13px !important;
  background-position: 10px !important;
  bottom: 5px;
}

.show-new-viewer .ui.input > input {
  background: #3d3d3d;
  border: 1px solid white;
  color: white;
}

.show-new-viewer .fa-2x {
  font-size: 2em;
}

.show-new-viewer .fa {
  position: relative;
  display: table-cell;
  width: 56px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
}

.show-new-viewer .main-menu {
  background: #000000;
  position: absolute;
  top: -57px;
  bottom: 0;
  left: 0;
  width: 56px;
  overflow: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.show-new-viewer .main-menu-right {
  background: #000000;
  position: fixed;
  top: 0px;
  bottom: 0;
  right: 0;
  float: right;
  width: 56px;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 900;
}

.show-new-viewer .main-menu-right-chat {
  background: #141414;
  color: white;
  display: flex;
  flex-direction: column;
  -webkit-transition: width 0.05s linear;
  transition: all 0.5s;
  height: 100%;
  width: 100%;
  min-width: 360px;
  position: relative;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
}

.show-new-viewer .main-menu-right-chat::-webkit-scrollbar {
  height: 12px !important;
  width: 12px !important;
  background: #000 !important;
}

.show-new-viewer .main-menu-right-chat::-webkit-scrollbar-thumb {
  background: #1b1b1b !important;
  -webkit-border-radius: 1ex !important;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75) !important;
}

.show-new-viewer .main-menu-right-chat::-webkit-scrollbar-corner {
  background: #000 !important;
}

.show-new-viewer .main-menu-right-chat-users {
  background: black;
  position: absolute;
  top: 56px;
  /* bottom: 47px; */
  right: 0;
  margin-right: 0px;
  color: white;
  float: right;
  width: 344px;
  /* overflow: scroll; */
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
  padding-left: 20px;
}

.show-new-viewer .chat-h1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1d1d;
}

.show-new-viewer .chat-h2 {
  position: sticky;
  top: 50px;
  margin-top: 10px;
  height: 55px;
  width: 344px;
  background: #1c1d1d;
}
.guest-count {
  width: 40px;
  font-size: 12px;
  letter-spacing: 2px;
}
.show-new-viewer .chat-text {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding: 20px;
  letter-spacing: 2px;
}

.show-new-viewer .chat-options-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 16px;
}

.show-new-viewer .chat-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.show-new-viewer .chat-list-item:first-child {
  margin-top: auto !important;
}

.show-new-viewer .chat-list-item {
  display: flex;
  padding: 8px 16px;
}

.show-new-viewer .chat-notification {
  width: 100%;
  text-align: center;
  color: #4a4b51;
  font-size: 14px;
  font-weight: 400;
}

.show-new-viewer .chat-local-message {
  justify-content: flex-end;
}

.show-new-viewer .chat-local-message-body {
  background-color: #282829 !important;
}

.show-new-viewer .chat-image {
  background: transparent url("../../Images/logo.png") 0% 0% no-repeat
    padding-box;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background-size: cover !important;
  background-position: cover !important;
  border: 1px solid #fff;
  display: inline-table;
  min-width: 42px;
}

.show-new-viewer .chat-message-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  padding-right: 32px;
  min-width: 65%;
  width: max-content;
  max-width: 100%;
  transition: all 0.2s ease-out;
  background-color: #1d1d1d;
  border-radius: 8px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  position: relative;
  flex-direction: column;
  margin-left: 12px;
}

.show-new-viewer .chat-message-body .local-message {
  background-color: #282829;
}

.show-new-viewer .chat-heading {
  margin: 0;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 700;
}

.show-new-viewer .chat-message-grey-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  background: #454248;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}

.show-new-viewer .annote-chat-message-grey-bg {
  word-break: break-word;
  white-space: pre-wrap;
  font-weight: 400;
  color: #8d8f9c;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
  min-width: 250px;
}

.show-new-viewer .annote-stream-label-grey-bg {
  word-break: break-word !important;
  white-space: pre-wrap !important;
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
  padding: 16px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: 200px !important;
  line-height: 1.5 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-align: left !important;
  background: #454248 !important;
}

.show-new-viewer .stream-label-edit-parent {
  display: flex;
  gap: 2px;
}

.show-new-viewer .stream-label-edit-box {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  margin-top: 3px;
  flex: 1;
  margin-right: 4px;
}

.show-new-viewer .chat-message-white-bg {
  font-size: 14px;
  font-weight: 400;
  color: #8d8f9c;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
  word-break: break-word;
  white-space: pre-wrap;
}

.show-new-viewer .chat-message-green-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  font-size: 15px;
  background: rgb(48, 97, 43);
  color: #f4f4f4;
  min-width: 250px;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}

.show-new-viewer .chat-message-red-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  font-size: 15px;
  background: rgb(129, 12, 4);
  color: #f4f4f4;
  min-width: 250px;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}

.show-new-viewer .chat-timestamp-and-message-wrapper {
  position: relative;
  z-index: 3;
}

.show-new-viewer .chat-message-hover-tools-row {
  display: none;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 4px;
  background-color: #141414;
  width: max-content;
  height: max-content;
  padding: 4px;
}

.show-new-viewer .chat-message-tool-icon-container {
  filter: brightness(0.4);
}

.show-new-viewer .chat-message-tool-icon-container:hover {
  filter: brightness(1);
}

.show-new-viewer .chat-message-body:hover .chat-message-hover-tools-row {
  display: flex;
}

.show-new-viewer .chat-message-body:hover {
  margin-bottom: 16px;
}

.show-new-viewer .chat-message-body:hover .chat-time {
  bottom: -40px;
  opacity: 1;
}

.show-new-viewer .chat-message-body:hover .chat-time-box-parent {
  bottom: -24px;
  opacity: 1;
}

.show-new-viewer .chat-message-body:hover .from-message-body-fix {
  bottom: -24px;
}

.show-new-viewer .chat-message-body:hover .timestamp-in-file-message {
  bottom: -40px;
}

.show-new-viewer .timestamp-in-file-message {
  bottom: -12px;
}

.show-new-viewer .chat-time {
  transition: all 0.2s ease-out;
  font-size: 10px;
  position: absolute;
  bottom: -12px;
  left: 0px;
  z-index: 0;
  opacity: 0;
}

.show-new-viewer .chat-annote {
  font-size: 10px;
  float: right;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: purple;
  color: #000000;
}

.show-new-viewer .chat-typing-indicator {
  padding-left: 5px;
  padding-bottom: 10px;
  font-size: 10px;
}

.show-new-viewer .tray-btns {
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d1d1d;
  width: 44px;
  height: 200px;
}

.show-new-viewer .chat-heading-user {
  margin-left: 20px;
  width: 155px;
  font-size: 15px;
  font-weight: 300;
  margin-top: 15px;
}

.show-new-viewer .chat-message-user {
  margin-left: 20px;
  width: 155px;
  font-size: 12px;
  padding-top: 10px;
}

/* .profile_img11 {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: absolute;
  object-fit: fill;
  z-index: 1;
  margin-left: 20%;
  margin-top: 10px;
  box-shadow: 0px 0px 22px -10px #f4f4f4;
  -moz-box-shadow: 0px 0px 22px -10px #f4f4f4;
  -webkit-box-shadow:0px 0px 22px -10px #f4f4f4;
} */
.show-new-viewer .profile_img11 {
  width: 98%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.show-new-viewer .hide-profile {
  display: none;
}

.show-new-viewer ul.chat-content li {
  height: 50px;
}

.show-new-viewer li.main-bar a {
  cursor: pointer;
}

.show-new-viewer .chat-msg-input {
  display: flex;
  margin: 0px;
  align-items: center;
  justify-content: space-around;
  background-color: #000;
  padding: 12px;
}

.show-new-viewer .chat-msg-input input[type="text"] {
  border: none !important;
  background-color: #282829 !important;
  color: white !important;
  padding: 12px 0px;
  border-radius: 0px;
}

/* .chat-msg-input input[type="text"]:active {
  color: white;
} */
.show-new-viewer .annotation-chat-msg-input {
  display: flex;
  left: 0px;
  right: 21px;
  bottom: 40px;
  margin: 0px;
  padding: 12px;
  align-items: flex-end;
  position: sticky;
  background-color: #141414;
}

.show-new-viewer .annotation-chat-msg-input .sendTxt {
  display: flex;
  align-items: center;
}

.show-new-viewer .annotation-chat-msg-input input[type="text"] {
  border: none !important;
  background-color: #282829 !important;
  color: white !important;
  padding: 12px;
  border-radius: 4px;
}

.show-new-viewer .annotation-chat-btn {
  display: flex;
  left: 0px;
  bottom: 0px;
  margin: 0px;
  align-items: flex-end;
  position: sticky;
  background-color: #141414;
}

.show-new-viewer .sendTxt {
  width: 330px;
}

.show-new-viewer .editTxtArea {
  resize: none;
  border: none;
  border-radius: 10px;
  width: 250px;
  background: #454248;
  color: white;
  outline: none;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 400;
  border-top-left-radius: 0;
  padding: 7px;
}

.show-new-viewer .upload-btn {
  background-color: #282829;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.show-new-viewer .upload-btn:hover {
  cursor: pointer;
}

.show-new-viewer .send-chat-btn {
  background-color: #6435c9 !important;
  height: 42px !important;
  width: 42px !important;
  padding: 0px !important;
  border-radius: 4px !important;
  margin: 0px 0px 0px 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-new-viewer .cancel-chat-btn {
  background: transparent url("../../Images/cancel-chat.svg") 0% 0% no-repeat
    padding-box !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 28px 34px !important;
  padding: 10px !important;
  background-color: #6435c9 !important;
  height: 33px;
  width: 29px;
  margin-top: 10px !important;
}

.show-new-viewer .giphy-btn {
  background: transparent url("../../Images/giphy.svg") 0% 0% no-repeat
    padding-box !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 28px 34px !important;
  padding: 10px !important;
  background-color: transparent !important;
  height: 33px;
  width: 29px;
  margin-top: 10px !important;
  margin-right: 5px;
}

.show-new-viewer .user-icon-text {
  color: white;
  cursor: pointer;
}

.show-new-viewer .main-menu-right-close {
  display: none !important;
}

.show-new-viewer .main-menu-right-show {
  display: table !important;
}

.show-new-viewer .leftBar li,
.show-new-viewer .main-menu-right li {
  position: relative;
  display: block;
  width: 250px;
  height: 48px;
}

.show-new-viewer .leftBar li:first-child {
  position: relative;
  display: block;
  width: 246px;
  height: 48px;
  margin-top: 4px;
}

.show-new-viewer .leftBar li > a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.show-new-viewer .main-menu-right li > a {
  /* float: right; */
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.show-new-viewer .leftBar .nav-text,
.show-new-viewer .main-menu-right .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 250px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 25px;
}

.show-new-viewer .leftBar > ul.menu-bottom,
.show-new-viewer .main-menu-right > ul.menu-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

.show-new-viewer .menuBorder {
  margin: 15px auto 25px;
  width: calc(100% - 30px);
  background: #707070;
  height: 1px;
  border: 0 none;
  position: relative;
  z-index: 99;
}

.show-new-viewer .no-touch .scrollable.hover {
  overflow-y: hidden;
}

.show-new-viewer .no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

.show-new-viewer a:hover,
.show-new-viewer a:focus {
  text-decoration: none;
}

.show-new-viewer nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.show-new-viewer nav ul,
.show-new-viewer nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.show-new-viewer .leftBar li.items-list:hover > a,
.show-new-viewer .main-menu-right li.items-list:hover > a,
.show-new-viewer nav.leftBar li.active > a,
.show-new-viewer .dropdown-menu > li > a:hover,
.show-new-viewer .dropdown-menu > li > a:focus,
.show-new-viewer .dropdown-menu > .active > a,
.show-new-viewer .dropdown-menu > .active > a:hover,
.show-new-viewer .dropdown-menu > .active > a:focus,
.show-new-viewer .no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.show-new-viewer .dashboard-page nav.dashboard-menu ul li.active a {
  color: #fff;
  background-color: #2d2935;
}

.show-new-viewer .leftBar li.main-bar .fa:hover,
.show-new-viewer .main-menu-right li.main-bar .fa:hover {
  /* background-color: #2d2935; */
  color: #fff;
}

.show-new-viewer .leftBar:hover .x {
  display: inline !important;
}

.show-new-viewer .leftBar:hover .bars {
  display: none !important;
}

.show-new-viewer .leftBar li.items-list.selected {
  border-left: #9f5fff 3px solid;
}

.show-new-viewer .leftBar li.items-list:hover {
  border-left: #9f5fff 3px solid;
}

.show-new-viewer .viewerstream {
  margin-top: 0px;
  background: black;
  height: 100%;
  width: 100%;
  position: relative;
}

.show-new-viewer .vieweraspectratio {
  padding-bottom: 62.5%;
  position: absolute;
}

.show-new-viewer .viewcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
}

.show-new-viewer .viewcontainer .videoparticipant video {
  width: 100% !important;
}

.show-new-viewer .viewerstream-video {
  outline: none;
  width: 100%;
  height: 100%;
}

.show-new-viewer .viewerstream-video-withChat {
  width: 77%;
  outline: none;
  height: 75vh;
  position: relative;
}

.show-new-viewer .overlay-video {
  position: absolute;
  top: 4%;
  left: 43%;
  z-index: 1;
  font-size: 25px;
  padding: 5px;
  background: black;
  color: white;
}

.show-new-viewer .multi-video-container {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #000000;
}

.show-new-viewer .multi-video-container .viewerstream {
  height: 33.33%;
  padding: 5px 0;
}

.show-new-viewer .multi-video-container .viewerstream .viewcontainer .video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.show-new-viewer .multi-video-container .viewerstream .viewcontainer .video-container .viewerstream-video {
  cursor: pointer;
}

.show-new-viewer .multi-video-container .viewerstream .viewcontainer .video-container .inactive-stream-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-new-viewer .multi-video-container .viewerstream .viewcontainer .video-container .inactive-stream-section .inactive-stream-text {
  color: #FFFFFF;
  font-size: 14px;
}

.show-new-viewer .videoTrayNames {
  margin-left: 50px;
  color: white;
  font-size: 15px;
  font-weight: 500;
}

.show-new-viewer .ui.grid {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  overflow-x: scroll;
  white-space: nowrap;
  background: #000000;
  margin-top: 0px !important;
}

.show-new-viewer .ui.grid > .row > .column > img,
.show-new-viewer .ui.grid > .row > img {
  height: 95px !important;
  width: 205px !important;
}

.show-new-viewer .video-tiles-row-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  height: 200px;
  justify-content: space-between;
  position: relative;
  bottom: 0px;
  transition: bottom 1s;
}

.show-new-viewer .expand-collapse-btn {
  width: 42px;
  height: 42px;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #FFFFFF;
  background-color: #141414;
  cursor: pointer;
  z-index: 100;
  margin-top: -42px;
}

.show-new-viewer .inverse-layout .expand-collapse-btn {
  margin-top: 0px;
  top: 0;
}

.show-new-viewer .expand-collapse-btn .expand-collapse-icon {
  width: 20px;
  height: 20px;
}

.show-new-viewer .video-tiles-row-wrapper.collapse-tray {
  bottom: -200px;
}

.show-new-viewer .video-tiles-row-wrapper.inverse-layout.collapse-tray {
  top: -201px;
}

.show-new-viewer .empty-video-tiles-placeholder {
  width: 100%;
  background-color: #000;
  height: 200px;
}

.show-new-viewer .video-tray-collapse-bg {
  width: 100%;
  background-color: #000;
  height: 200px;
  position: absolute;
  bottom: 0;
}

.show-new-viewer .video-tray-collapse-bg.inverse-layout {
  position: absolute;
  top: 0;
}

.show-new-viewer .video-tiles-row-wrapper.inverse-layout {
  width: calc(100% - 90px);
  margin-left: 90px;
  top: 0px;
  transition: top 1s;
  z-index: 10;
}

.show-new-viewer .videotray {
  background-color: #141414;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding: 16px;
  width: 100%;
  height: 100%;
  gap: 16px;
}

.show-new-viewer .videotray::-webkit-scrollbar {
  width: 100px;
  height: 5px;
}

.show-new-viewer .videotray::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
}

.show-new-viewer .videotray::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #707070;
  outline: 1px solid slategrey;
}

.show-new-viewer .videotray::-webkit-scrollbar:vertical {
  display: none;
}

.show-new-viewer .videotray:last-child {
  padding-right: 56px;
}

.show-new-viewer .videoparticipant {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  background-color: #141414;
  border-radius: 8px;
  border: 4px solid #1d1d1d;
  max-width: 240px;
}

.show-new-viewer .videoparticipant:first-child {
  margin-left: 0px;
}

.show-new-viewer .videoparticipant .audio-button {
  /* position: absolute; */
  background-color: red;
  /* left: 2px; */
  /* top: 3px; */
  /* z-index: 101; */
}

.show-new-viewer .videoparticipant .video-button {
  /* position: absolute; */
  background-color: red;
  /* left: 60px; */
  /* top: 3px; */
  /* z-index: 101; */
}

.show-new-viewer .bg-red {
  background-color: red !important;
}

.show-new-viewer .bg-green {
  background-color: green !important;
}

.show-new-viewer .prevent-textcopy {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.show-new-viewer .videoparticipant .overlay-text {
  color: #fff;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin: 0px;
  margin-right: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;
  overflow: hidden;
  letter-spacing: normal;
}

.show-new-viewer .videoparticipant .overlay-text-red {
  color: #fff;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin: 0px;
  margin-right: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;
  overflow: hidden;
  letter-spacing: normal;
}

.show-new-viewer .backup-name {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Nunito Sans", sans-serif !important;
  padding-bottom: 30px;
  color: white;
  position: absolute;
  top: 0;
  display: flex;
  z-index: 4;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0px;
  justify-content: center;
  border-radius: 4px;
  background-color: #141414;
}

.show-new-viewer .videoparticipant .overlay-video-on-tray {
  position: relative;
  bottom: 0;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/video_on.svg") 0% 0% no-repeat
    padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.show-new-viewer .videoparticipant .overlay-fullscreen-on-tray {
  position: relative;
  bottom: 0;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/fullscreen_on.svg") 0% 0% no-repeat
    padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.show-new-viewer .videoparticipant .overlay-fullscreen-off-tray {
  display: none;
  position: relative;
  bottom: 0;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/fullscreen_off.svg") 0% 0% no-repeat
    padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.show-new-viewer .overlay-fullscreen-off-tray:hover,
.overlay-fullscreen-on-tray:hover {
  cursor: pointer;
}

.show-new-viewer .videoparticipant .overlay-video-off-tray {
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/redvideo-off.svg") 0% 0% no-repeat
    padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  background-position: center;
}

.show-new-viewer .overlay-video-off-tray:hover,
.overlay-video-on-tray:hover {
  cursor: pointer;
}

.show-new-viewer .disabled-button {
  pointer-events: none;
}

.show-new-viewer .videoparticipant .overlay-audio-on {
  position: relative;
  bottom: 0;
  z-index: 2000;
  margin-left: 5px;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/microphone.svg") 0% 0% no-repeat
    padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.show-new-viewer .videoparticipant .overlay-audio-off {
  position: relative;
  bottom: 0;
  z-index: 2000;
  margin-left: 5px;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/redmic-off.svg") 0% 0% no-repeat
    padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.show-new-viewer .overlay-audio-off:hover,
.overlay-audio-on:hover {
  cursor: pointer;
}

.show-new-viewer .videoparticipant .menu-vertical {
  position: absolute;
  top: 13px;
  right: 0;
  margin-right: 5px;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/menu-vertical.svg") 0% 0% no-repeat
    padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: right;
  cursor: pointer;
}

.show-new-viewer .videoparticipant .overlay-signal {
  position: absolute;
  z-index: 2;
  top: 0px;
  right: 0px;
  background-size: 16px 16px;
  height: 16px;
  color: #ccc;
}

.show-new-viewer .videoparticipant .overlay-high-signal {
  background: transparent url("../../Images/green-signal.png") 0% 0% no-repeat
    padding-box;
}

.show-new-viewer .videoparticipant .overlay-medium-signal {
  background: transparent url("../../Images/medium-strength.png") 0% 0%
    no-repeat padding-box;
}

.show-new-viewer .videoparticipant .overlay-low-signal {
  background: transparent url("../../Images/red-signal.png") 0% 0% no-repeat
    padding-box;
}

.show-new-viewer .videoparticipant video {
  height: 100%;
  width: 100%;
  border-radius: 4px;

  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
  object-fit: cover;
}

.show-new-viewer .dominant-videoparticipant video {
  height: 100%;
  width: 100%;
  border-radius: 4px;

  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
  object-fit: cover;
}

.show-new-viewer .overlay-vtray {
  position: absolute;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  padding: 12px;
  border-radius: 0 0 4px 4px;
  bottom: 0;
}

.show-new-viewer .overlay-participant-connected {
  position: absolute;
  width: 65%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-color: #5bb36f;
  border-color: #5bb36f;
  opacity: 0.6;
  z-index: 2002;
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.show-new-viewer .overlay-participant-disconnected {
  position: absolute;
  width: 65%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-color: #e4404e;
  border-color: #e4404e;
  opacity: 0.6;
  z-index: 2002;
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

/*Header icons new css*/

.show-new-viewer .centerMenuTop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-basis: 100%;
  justify-content: center;
  /* overflow: hidden; */
  padding: 12px 0px;
  gap: 12px;
}

.show-new-viewer .centerMenuTop .top-menu-button-item {
  min-width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  transition: 0.4s;
  background-color: #141414;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
}

.centerMenuTop .top-menu-button-item.noise-cancellation-btn {
  border: 2px solid transparent;
  border-bottom: 3px solid transparent;
}

.centerMenuTop
  .top-menu-button-item.noise-cancellation-btn.noise-cancellation-on {
  border: 2px solid #4c7947;
  border-bottom: 3px solid #4c7947;
}

.show-new-viewer .call-options-dropdown > div > i {
  display: none !important;
}

.show-new-viewer .call-options-dropdown > div {
  position: unset !important;
}

.show-new-viewer .pencil-tool-dropdown > div > i {
  display: none !important;
}

.show-new-viewer .pencil-tool-dropdown > div {
  position: unset !important;
}

.show-new-viewer .audiovisual-source-dropdown > div > i {
  display: none !important;
}

.show-new-viewer .audiovisual-source-dropdown > div {
  position: unset !important;
}

.show-new-viewer .centerMenuTop .top-menu-button-item > span {
  display: none;
}

.show-new-viewer .centerMenuTop .top-menu-button-item:hover {
  background-color: #454248;
}

.show-new-viewer .centerMenuTop .top-menu-button-item:hover img {
  filter: brightness(0) invert(1);
}

.show-new-viewer .centerMenuTop .top-menu-button-item:hover > span {
  height: 24px;
  background: #000;
  position: absolute;
  display: initial;
  bottom: -100%;
  font-size: 14px;
  color: #fff;
  padding: 0 5px;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  z-index: 10;
}

.show-new-viewer .centerMenuTop .top-menu-button-item:hover span {
  bottom: -35px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
}

.show-new-viewer .centerMenuTop .top-menu-button-item:hover .volumeSpan {
  margin-left: 70px;
}

.show-new-viewer .centerMenuTop .top-menu-button-item span::before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid black;
  content: "";
  top: -7px;
  position: absolute;
  left: 50%;
  margin-left: -5px;
}

.show-new-viewer .centerMenuTop .top-menu-button-item a {
  height: 28px;
  position: absolute;
  padding: 0 25px;
  color: #fff;
}

.show-new-viewer .centerMenuTop .top-menu-button-item a:hover {
  color: #b2b2b2;
}

.show-new-viewer .dividervertical {
  height: 22px;
  background-color: #707070;
  width: 1px;
  float: left;
  margin-top: 16px;
}

.show-new-viewer .menuTopRight {
  width: max-content;
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.show-new-viewer .menuTopRight ul {
  list-style-type: none;
  padding: 0px;
  margin: 0 auto;
}

.show-new-viewer .menuTopRight ul li {
  float: left;
  width: 49px;
  height: 70px;
}

.show-new-viewer .menuTopRight ul li a {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-bottom: 3px solid transparent;
  position: relative;
  margin-top: 3px solid transparent;
  cursor: pointer;
}

.show-new-viewer .menuTopRight ul li.account-settings {
  width: 60px;
}

.show-new-viewer .menuTopRight ul li a:hover,
.show-new-viewer .menuTopRight ul li a.active {
  background-color: #454248;
  border-color: #6600ff;
}

.show-new-viewer .menuTopRight ul li a.disabled {
  pointer-events: none;
}

.show-new-viewer #invitePeoples img.closeinvite,
.show-new-viewer #chatArea img.closeinvite,
.show-new-viewer #annotationChatArea img.closeinvite,
.show-new-viewer #roomCreatedcount img.closeinvite {
  display: none;
}

.show-new-viewer #invitePeoples.active img,
.show-new-viewer #chatArea.active img,
/* #annotationChatArea.active img, */
.show-new-viewer #roomCreatedcount.active img {
  display: none;
}

.show-new-viewer #roomCreatedcount.active .memberCount {
  display: none;
}

.show-new-viewer #invitePeoples.active img.closeinvite,
.show-new-viewer #chatArea.active img.closeinvite,
.show-new-viewer #annotationChatArea.active img.closeinvite,
.show-new-viewer #roomCreatedcount.active img.closeinvite {
  display: block;
}

.show-new-viewer .memberCount {
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 3px;
  top: 2px;
  max-width: 14px;
  line-height: 15px;
}

/*leftmenu*/
/* .leftBar{
  background-color:rgba(0, 0, 0,0.8);
  width: 56px;
  position: absolute;
  left: 0;
  top: -38px;
  height: 105%;
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
} */
.show-new-viewer .leftBar {
  background-color: rgba(0, 0, 0, 0.8);
  width: 56px;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0px;
  /* height: 598px; */
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}

.show-new-viewer .leftBar:hover,
.show-new-viewer .leftBar:active {
  width: 244px;
}

.show-new-viewer .leftBar::before {
  width: 56px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #000;
}

.show-new-viewer .left-bar-temp-width {
  width: 244px;
}

.show-new-viewer .menuSlide {
  padding: 0;
  list-style-type: none;
  margin: 0 auto;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.show-new-viewer .openSidebar {
  width: 244px;
}

.show-new-viewer .menuSlide.hideMain {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.show-new-viewer .menuSlide li {
  width: 244px;
  height: 48px;
  position: relative;
  cursor: pointer;
}

.show-new-viewer .menuSlide li:first-child:hover {
  background-color: transparent;
}

.show-new-viewer .menuSlide li:hover {
  background-color: #454248;
}

.show-new-viewer .menuSlide li:active {
  background-color: #ccc;
}

.show-new-viewer .menuSlide li:hover:after,
.show-new-viewer .menuSlide li.active:after {
  width: 3px;
  height: 100%;
  background-color: #6600ff;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.show-new-viewer .menuSlide li a {
  float: left;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  font-weight: normal;
  text-decoration: none;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.show-new-viewer .menuSlide li i {
  width: 56px;
  height: 48px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}

.show-new-viewer .menuSlide li span {
  padding-left: 10px;
}

.show-new-viewer .slideMenuRight {
  position: fixed;
  right: -100%;
  height: 100vh;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.show-new-viewer .slideMenuRight.showSub {
  right: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.show-new-viewer .slideMenuRight.showSub .menuSlide {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.show-new-viewer .menuBorder.innerArea {
  margin-bottom: 0;
  margin-top: 20px;
}

.show-new-viewer .menuSlide span.areaTitle {
  color: #fff;
  font-size: 14px;
  width: calc(100% - 30px);
  display: block;
  padding: 5px 3px 0;
  line-height: 1;
  margin-bottom: 15px;
  position: relative;
}

.show-new-viewer .stream-name-labels {
  height: 55vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.show-new-viewer .menuBorder.innerArea.bottom15 {
  margin-bottom: 15px;
}

.show-new-viewer .slider {
  position: absolute;
  z-index: 99;
  padding: 0px 50px;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
}

.show-new-viewer .slider .flexslider.carousel {
  margin: 0 auto;
  background: #333333;
  border-color: #333;
  border-top-width: 10px;
  border-radius: 0;
}

.show-new-viewer .slider .flex-direction-nav .flex-prev {
  left: -54px !important;
  opacity: 1 !important;
  background: #000;
  height: 165px;
  top: 10px;
  width: 56px;
  /*background-image: url(../images/chevron-left_big.svg);*/
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -999px;
  overflow: hidden;
}

.show-new-viewer .slider .flex-direction-nav .flex-next {
  right: -54px !important;
  opacity: 1 !important;
  background: #000;
  height: 165px;
  top: 10px;
  width: 56px;
  /*background-image: url(../images/chevron-right_big.svg);*/
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: 1500px;
  overflow: hidden;
  background-size: 44px;
}

.show-new-viewer .slider .flex-direction-nav .flex-prev::before,
.show-new-viewer .slider .flex-direction-nav .flex-next::before {
  display: none;
}

.show-new-viewer .slider .flexslider .slides > li {
  position: relative;
}

.show-new-viewer .guestlist .listPeoples li {
  padding: 13px 40px;
  position: relative;
}

.show-new-viewer .guestlist .listPeoples li .posAdmin {
  border: 0 none;
  background: none;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  margin-top: -5px;
}

.show-new-viewer .invitePeopleHeadingLarge {
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  padding: 0px 40px;
  margin-bottom: 15px;
}

.show-new-viewer span.subHeading {
  color: #fff;
  padding: 0px 40px;
  margin: 10px auto 40px;
  display: block;
}

.show-new-viewer .guestlist .listPeoples li .namePeople {
  margin-left: 0px;
}

.show-new-viewer .popDesign {
  background: #454248 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  width: 200px;
  height: 100px;
  position: absolute;
  top: 30px;
  right: 15px;
  padding: 10px 25px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
}

.show-new-viewer .popDesign.active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
}

.show-new-viewer .popDesign .closeBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.show-new-viewer .popDesign ul {
  padding: 0;
}

.show-new-viewer .guestlist .listPeoples .popDesign ul li {
  padding: 0;
}

.show-new-viewer .popDesign ul a {
  color: #b2b2b2;
  text-decoration: none;
  font-size: 15px;
  line-height: 25px;
}

.show-new-viewer .popDesign ul a:hover {
  color: #fff;
}

.show-new-viewer .chatForm {
  padding: 0px 15px;
  width: 100%;
  margin-top: 0px;
  float: left;
  position: relative;
}

.show-new-viewer .chatForm .chatForm {
  padding: 0;
  margin-top: 0;
}

.show-new-viewer .chatForm input[type="text"] {
  width: 100%;
  height: 46px;
  background: #333;
  border: 1px solid #fff;
  margin-bottom: 10px;
  color: #fff;
  padding: 0 15px;
  font-size: 15px;
}

.show-new-viewer .chatForm button.smileyBtn {
  position: absolute;
  background: none;
  border: 0 none;
  right: 10px;
  top: 12px;
  cursor: pointer;
}

.show-new-viewer .chatForm input[type="submit"] {
  background: #6600ff;
  color: #fff;
  border: 0px none;
  font-size: 14px;
  height: 36px;
  float: right;
  padding: 0px 20px;
  cursor: pointer;
}

.show-new-viewer .hide {
  display: none;
}

.show-new-viewer .ui.popup {
  /* padding: 0px !important; */
  border-radius: 0px;
}

.show-new-viewer .ui.popup > .ui.grid:not(.padded) {
  width: 100%;
}

.show-new-viewer .ui.grid {
  margin: 0px !important;
}

.show-new-viewer .ui.bottom.popup:before {
  background: #000 !important;
}

.show-new-viewer .ui.bottom.popup {
  margin: 1.3em;
}

.show-new-viewer .tc_image {
  width: 25px;
  height: 25px;
  margin-top: 15px;
}

.show-new-viewer .transmission_img {
  width: 25px;
  height: 25px;
  margin: 15px;
}

.show-new-viewer .download_tc {
  position: absolute;
  right: 20px;
}

.show-new-viewer .cursor-pointer {
  cursor: pointer;
}

.show-new-viewer .editPencil-img {
  width: 15px;
  height: 15px;
}

.show-new-viewer .editPencil-padding {
  /* padding-left: 75px !important; */
}

.show-new-viewer .greenTick-img,
.show-new-viewer .cancelTick-img {
  width: 27px;
  height: 27px;
}

.show-new-viewer .stream-label-submit {
  background: none;
  width: 27px;
  height: 27px;
  border: none;
  margin: 0;
  padding: 0;
}

.show-new-viewer .stream-label-submit:disabled {
  opacity: 0.2;
}

.show-new-viewer .stream-label-helper-text {
  font-size: 10px;
  display: flex;
  color: #fff;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}

.show-new-viewer .stream-label-error-text {
  color: red;
  font-size: 10px;
  display: flex;
  font-weight: bold;
}

.show-new-viewer .float-right {
  float: right;
}

.show-new-viewer .annotation-chat-btn .ui.button {
  padding: 5px 10px !important;
  line-height: 35px !important;
}

.show-new-viewer .chat-settings {
  position: relative;
  float: right;
  display: block;
  height: 30px;
  width: 30px;
  opacity: 1;
  z-index: 1002;
  margin: 15px 15px 0 0;
}

.show-new-viewer .clear-chat-button {
  position: relative;
  opacity: 1;
  cursor: pointer;
  transition: 0.3s;
}

.show-new-viewer .clear-chat-button:hover {
  filter: brightness(2);
}

.show-new-viewer .chat-settings .clear-image {
  position: relative;
  top: 4px;
}

.show-new-viewer .annotation-chat-footer {
  position: sticky;
  bottom: 0;
  background-color: #141414;
}

.show-new-viewer .publisher-panel-content {
  margin-top: 16px;
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  padding-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.show-new-viewer .publisher-panel-item {
  padding: 10px 20px;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #212222;
}

.show-new-viewer .publisher-panel-image {
  width: 20%;
}

.show-new-viewer .publisher-panel-user {
  width: 40%;
  color: white;
}

.show-new-viewer .publisher-panel-action {
  width: 20%;
  display: flex;
  justify-content: center;
}

.show-new-viewer .publisher-panel-status {
  width: 20%;
  display: flex;
  justify-content: center;
}

.show-new-viewer .publisher-panel-status-circle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: #fff;
  display: inline-block;
}
.show-new-viewer .publisher-actions {
  display: flex;
  justify-content: center;
}
.show-new-viewer .watermark-actions {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
}

.show-new-viewer .action-clear-changes {
  padding: 8px 12px;
  background-color: #282829;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.show-new-viewer .action-save-changes {
  padding: 8px 12px;
  background-color: #9f5fff;
  border-radius: 8px;
  cursor: pointer;
}

.show-new-viewer .action-save-changes > button {
  padding: 8px 12px;
  border-radius: 0px;
  background-color: transparent !important;
  outline: none !important;
  border: 0px solid black !important;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.show-new-viewer .action-clear-changes > button {
  padding: 8px 12px;
  border-radius: 0px;
  background-color: transparent !important;
  outline: none !important;
  border: 0px solid black !important;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.show-new-viewer .action-clear-changes:hover {
  background-color: #9f5fff !important;
}
.show-new-viewer #publisher-panel-tray .ui.secondary.pointing.menu .item {
  color: #ffffff;
}

.show-new-viewer
  #publisher-panel-tray
  .ui.secondary.pointing.menu
  .active.item {
  color: #ffffff;
  border-color: #ffffff;
}

.show-new-viewer #publisher-panel-tray .ui.tab.active,
.show-new-viewer .ui.tab.open {
  background: #1c1d1d;
}

.show-new-viewer #publisher-panel-tray .ui.segment {
  padding: 0 !important;
}

.show-new-viewer #publisher-panel-tray .chat-h1 {
  position: unset !important;
}

.show-new-viewer .publisher-panel-action-button {
  background: gray;
  border-radius: 5px;
  padding: 5px;
  border: none;
}

.show-new-viewer .tabs-wrapper {
  display: flex;
}

.show-new-viewer .publisher-panel-tab-button {
  border: none;
  flex-basis: 100%;
  background: none;
  color: #ffffff44;
  min-width: 100px;
  line-height: 40px;
  outline: none;
  cursor: pointer;
}

.show-new-viewer .publisher-panel-tab-button.active {
  border-bottom: 2px solid #8a2be2;
  color: white;
}

.show-new-viewer #guest-invite-tray .chat-h1 {
  display: flex;
}

.show-new-viewer #guest-invite-tray .guest-invite-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 10px;
}

.show-new-viewer #guest-invite-tray .guest-invite-header .chat-text {
  flex-grow: 1;
  margin: unset;
  position: unset;
  display: flex;
  justify-content: center;
}

.show-new-viewer #guest-invite-tray .guest-invite-content {
  min-height: calc(100vh - 293px);
}

.show-new-viewer .guest-invite-action-button {
  background: #8a2be2;
  border-radius: 5px;
  padding: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.show-new-viewer .guest-invite-modal-trigger {
  font-size: 25px;
}

.show-new-viewer .guest-invite-modal-trigger:hover {
  text-decoration-color: #8a2be2 !important;
  text-decoration: underline;
  cursor: pointer;
}

.show-new-viewer .guest-invite-modal-header {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-new-viewer .guest-invite-modal-content {
  padding: 16px;
  border-radius: 8px;
  background-color: #1c1d1d !important;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.show-new-viewer .guest-invite-modal-content .header {
  background-color: #1c1d1d;
  margin: 12px 0px;
  height: 24px;
}

.show-new-viewer .guest-invite-modal-content .content {
  width: 100%;
}

.show-new-viewer .guest-invite-modal-content .publisher-actions {
  justify-content: space-around;
}

.show-new-viewer .guest-invite-modal-content .form-input-error-msg {
  color: red;
}

.show-new-viewer .ui.dimmer {
  z-index: 1002;
}

.show-new-viewer .guest-user-info {
  margin: 1em 0;
}

.show-new-viewer .guest-invite-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.show-new-viewer .guest-invite-form .form-field {
  margin: 1em 0;
  width: 100%;
}

.show-new-viewer .guest-invite-modal-content .actions > div {
  display: flex;
  gap: 12px;
  align-items: center;
}

.show-new-viewer
  .guest-invite-modal-content
  .actions
  > div
  .guest-invite-action-button {
  padding: 8px 12px;
}

.show-new-viewer .guest-invite-form .form-input-label {
  display: block;
  margin: 0 0 1em 0;
}

.show-new-viewer .guest-invite-form .form-input {
  width: 100%;
  color: #ffffff;
  padding: 8px 12px !important;
  border-radius: 4px !important;
  background-color: #141414 !important;
  border: 0px solid black !important;
  box-shadow: unset !important;
  font-size: 14px !important;
}

.show-new-viewer .guest-invite-form input[type="text"],
input[type="number"] {
  color: #ffffff !important;
}

.show-new-viewer .guest-invite-form .expiryTime .form-input-label {
  display: inline;
}

.show-new-viewer .guest-invite-form .expiryTime .form-input {
  min-width: 50px;
  width: min-content;
}

.show-new-viewer .guest-invite-content .delete_image {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.show-new-viewer .guest-invite-content .delete_image.disabled {
  cursor: unset;
}

.show-new-viewer input::-webkit-outer-spin-button,
.show-new-viewer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.show-new-viewer input[type="number"] {
  -moz-appearance: textfield;
}

.show-new-viewer .emoji-img-wrapper {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
  background-color: #282829;
}

.show-new-viewer .emoji-mart {
  max-width: 100% !important;
}

.show-new-viewer .chat-message-body .ui.card {
  margin: 0 !important;
  width: 250px;
}

.show-new-viewer .file-message-grey-bg .ui.card {
  background-color: #141414;
  border-radius: 8px;
  border: 0px solid black;
  text-overflow: ellipsis;
  box-shadow: none;
}

.show-new-viewer .file-message-white-bg .ui.card {
  background-color: white;
}

.show-new-viewer .file-message-grey-bg .file-name {
  color: #8d8f9c;
}

.show-new-viewer .file-message-white-bg .file-name {
  color: black;
}

.show-new-viewer .emoji-img {
  cursor: pointer;
}

.show-new-viewer .download-file-icon {
  cursor: pointer;
}

.show-new-viewer .chat-message-body .duplicate-icon {
  background: transparent url("../../Images/copy_white.svg") no-repeat center;
  height: 20px;
  width: 20px;
  margin: 0px;
}

.show-new-viewer .chat-message-body .edit-message-icon {
  background: transparent url("../../Images/edit_message.svg") no-repeat center;
  height: 20px;
  width: 20px;
  margin: 0px;
}

.show-new-viewer .dnd-icon {
  width: 16px;
  height: 16px;
  margin-top: 0px !important;
}

.show-new-viewer .diagnostics-icon {
  width: 16px;
  height: 16px;
  margin-top: 0px !important;
}

.show-new-viewer .d-none {
  visibility: hidden;
  z-index: -1;
}

.show-new-viewer .dominant-speaker-view {
  /* display: flex; */
  margin: 0 auto;
  outline: none;
  max-height: 100%;
  max-width: calc(100% - 290px);
  position: absolute;
  left: 0px;
}

.show-new-viewer .dominant-speaker-icon {
  height: 16px;
  width: 16px;
  margin: 0 !important;
  margin-right: 12px !important;
}

.show-new-viewer .annotate-color-container {
  display: flex;
}

.show-new-viewer .annotate-color-radio {
  margin: 0 3px;
}

.show-new-viewer .annotate-color-label {
  width: 18px;
  height: 18px;
  position: relative;
  border: 1px solid #d5dade;
  transition: all 0.4s ease;
  border-radius: 50%;
  padding: 2px;
  background-clip: content-box !important;
  display: block;
}

.show-new-viewer .annotate-color-radio input:checked + label {
  border-color: #4898d4;
  border-width: 2px;
}

.show-new-viewer .annotation-chat-color-btn {
  margin-left: 10px;
  border: none;
  padding: 5px 10px;
  line-height: 35px;
  cursor: pointer;
  border-radius: 50%;
}

.show-new-viewer .hidden {
  visibility: hidden;
}

/* @media screen and (max-width: 995px),
screen and (max-height: 700px) {
  .videotray {
    height: 26vh;
  }

  .show-viewer {
    height: 92vh;
  }
  
} */
@media only screen and (max-width: 768px) {
  .show-new-viewer .centerMenuTop ul li,
  .show-new-viewer .menuTopRight ul li {
    width: 50px;
  }
}


@media only screen and (max-width: 1023px) {
  .show-new-viewer .centerMenuTop {
    max-width: 60%;
  }
}

.show-new-viewer .fullscreen-img {
  width: fit-content;
  height: fit-content;
}

.show-new-viewer .dnd-img {
  width: 24px;
}

.show-new-viewer .timeCodePopup > .actions > :first-child {
  display: none;
}

.background-selector-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.background-selector {
  border: 2px solid #ffffff;
  display: flex;
  margin: 5px;
}

.background-selector:hover {
  border: 2px solid blue;
}

.background-selector.active {
  border: 2px solid rgb(9, 255, 0);
}

.background-selector img {
  max-height: 120px !important;
  width: 150px !important;
}

.show-new-viewer .show-viewer-leftmenu {
  position: fixed;
  width: 100px;
  top: 0px;
  left: 0px;
  height: calc(100vh - 200px);
  background-color: black;
  transition: width 0.2s ease-out;
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
  gap: 24px;
  z-index: 100;
}
.show-new-viewer .show-viewer-leftmenu-guestViewer {
  position: fixed;
  width: 90px;
  top: 0px;
  left: 0px;
  height: calc(100vh - 200px);
  background-color: black;
  transition: width 0.2s ease-out;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  gap: 24px;
  z-index: 100;
}

.show-new-viewer .full-height-leftmenu {
  height: 100vh;
}

.show-new-viewer .show-viewer-leftmenu.inverse-layout {
  height: 100vh;
  z-index: 100;
}
.show-new-viewer .show-viewer-leftmenu-guestViewer.inverse-layout {
  height: 100vh;
  z-index: 100;
}
.show-new-viewer .sidemenu-container-open,
.show-new-viewer .show-viewer-leftmenu:hover {
  width: 360px;
  height: 100vh;
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.show-new-viewer .show-viewer-leftmenu-guestViewer:hover {
  /* width: 360px;
  height: 100vh;
  backdrop-filter: blur(30px); 
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;*/
  background-color: black;
}
.show-new-viewer .sidemenu-item {
  display: flex;
  gap: 12px;
  align-items: center;
  height: 42px;
  padding-left: 24px;
  padding-right: 12px;
  cursor: pointer;
  flex-shrink: 0;
}

.show-new-viewer .sidemenu-item-border:hover {
  border-left: 2px solid white;
}

.show-new-viewer .sidemenu-hide-collapsed {
  font-size: 16px;
  color: white;
  white-space: nowrap;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.2s ease-out;
}

.show-new-viewer .sidemenu-hide-collapsed.sidemenu-stream-name {
  pointer-events: none;
}

.show-new-viewer .sidemenu-container-open .sidemenu-hide-collapsed,
.show-new-viewer .show-viewer-leftmenu:hover .sidemenu-hide-collapsed {
  display: flex;
  transform: translateX(0%);
  opacity: 1;
}

.show-new-viewer .sidemenu-title {
  font-weight: 700;
}

.show-new-viewer .sidemenu-subtitle {
  font-weight: 400;
}

.show-new-viewer .sidemenu-item-icon {
  height: 42px;
}

.show-new-viewer .sidemenu-separator {
  height: 1px;
  background-color: #282829;
  width: calc(100% - 32px);
  margin: 0px 16px;
  box-sizing: border-box;
}

.show-new-viewer .sidemenu-spaced-header {
  letter-spacing: 2px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  padding-left: 16px;
}

.show-new-viewer .streams-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
}

.show-new-viewer .streams-container-disabled {
  pointer-events: none;
}

.show-new-viewer .stream-button {
  width: 42px;
  height: 42px;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  min-width: 42px;
  min-height: 42px;
  background-color: #1d1d1d;
}

.show-new-viewer .sidemenu-item:hover .stream-button {
  background-color: #282829;
}

.show-new-viewer .sidemenu-item:hover .stream-button img {
  filter: brightness(0.7);
}

.show-new-viewer .stream-selected-active .stream-button {
  background-color: #6a29ff !important;
}

.show-new-viewer .stream-selected-active .stream-button img {
  filter: brightness(1) !important;
}

.show-new-viewer .stream-selected-inactive .stream-button img {
  filter: brightness(0.5);
}

.show-new-viewer .sidemenu-stream-name {
  font-size: 16px;
  font-weight: 700;
}

.show-new-viewer .stream-selected-inactive .sidemenu-stream-name {
  color: #ccc;
}

.show-new-viewer .main-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.show-new-viewer .topbar {
  display: flex;
  justify-content: space-between;
  width: calc(100vw - 90px);
  height: 80px;
  margin-left: 90px;
  background-color: #000;
  z-index: 100;
}

.show-new-viewer .content-row {
  display: flex;
  flex-direction: row;
  z-index: 99;
}

.show-new-viewer .viewer-column {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.show-new-viewer .viewer-column--with-chat,
.show-new-viewer .viewer-column--with-publisher-panel,
.show-new-viewer .viewer-column--with-locators-for-avid,
.show-new-viewer .viewer-column--with-watermarkPanel,
.show-new-viewer .viewer-column--with-guest-viewer {
  max-width: calc(100% - 360px);
}

.show-new-viewer .viewer-column.inverse-layout {
  flex-direction: column-reverse;
}

.show-new-viewer .player-wrapper {
  display: flex;
  height: calc(100% - 200px);
  width: calc(100% - 90px);
  margin-left: 90px;
}

.show-new-viewer .player-wrapper-full-height {
  height: 100%;
}

.show-new-viewer .chat-wrapper {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100vh - 80px);
}

.show-new-viewer .publisher-panel-wrapper {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100vh - 80px);
}

.show-new-viewer .guest-panel-wrapper,  .show-new-viewer .encoder-panel-wrapper{
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100vh - 80px);
}

.show-new-viewer .avid-locators-panel-wrapper {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100vh - 80px);
}

.show-new-viewer .participant-wrapper {
  height: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  background-color: #1d1d1d;
  border-radius: 8px;
  min-width: 240px;
}

.show-new-viewer .participant-content {
  display: flex;
  border-radius: 4px;
  background-color: #141414;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100%;
}

.show-new-viewer .video-off-name-backup {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}

.show-new-viewer .participant-video-overlay {
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: space-between;
}

.show-new-viewer .participant-name {
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.show-new-viewer .participant-options {
  cursor: pointer;
}

.show-new-viewer .options-drawer {
  position: absolute;
  bottom: 4px;
  right: 4px;
  border-radius: 4px;
  border: 1px solid #282829;
  background-color: #282829;
  display: flex;
  gap: 1px;
  flex-direction: column;
  overflow: hidden;
  width: max-content;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
}

.show-new-viewer .options-drawer-option {
  display: flex;
  padding: 16px;
  padding-right: 24px;
  background-color: #1d1d1d;
  color: white;
}

.show-new-viewer .options-drawer-option:hover {
  background-color: #282829;
}

.show-new-viewer .volume-control {
  position: absolute;
  background: none;
  z-index: 1900;
  background: #141414;
  top: calc(100% + 1px);
  display: flex;
  width: 6px !important;
  min-width: unset;
  height: calc(122px + 8px);
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  margin: unset;
}

.show-new-viewer input[type="range"] {
  background: linear-gradient(
    to right,
    #e5e5e5 0%,
    #e5e5e5 100%,
    #282829 0%,
    #282829 0%
  );
  /* border: solid 1px #003860; */
  box-sizing: border-box;
  border-radius: 8px;
  height: 6px;
  width: 122px;
  outline: none;
  -webkit-appearance: none;
  transform: rotate(270deg);
}

.show-new-viewer .cameraMenu {
  color: black;
  background-color: white;
  font-size: 15px;
  font-weight: normal;
  text-decoration: none;
}

.show-new-viewer .ui.dropdown .cameraMenu {
  top: 55px !important;
  left: 0px;
}

.show-new-viewer .ui.dropdown .visible.cameraMenu {
  display: flex !important;
  flex-direction: column;
  padding: 12px;
  background-color: #1d1d1d !important;
  gap: 12px;
  border: 1px solid #454248;
}

.show-new-viewer ui.dropdown .cameraMenu > .item .dropdown.icon {
  margin: -7px !important;
}

.show-new-viewer .cameraMenu .ui.selection.active.dropdown .menu {
  /* margin-left: 18px !important; */
  /* height: auto !important; */
  /* width: 300px !important; */
  border: 1px solid #707070;
  /* top: 30px !important; */
  /* left: -155px; */
  /* white-space: nowrap; */
}

.show-new-viewer .cameraMenu .ui.selection.dropdown .menu > .item {
  border-bottom: 1px solid #707070 !important;
  border-top: none;
  white-space: nowrap;
}

.show-new-viewer .cameraMenu .ui.dropdown .menu > .item {
  padding: 0px !important;
  display: flex;
  align-items: center;
  background: white !important;
  border-radius: 4px;
}

.show-new-viewer .cameraMenu .ui.dropdown .menu > .item > button {
  min-width: 100%;
  width: max-content;
  display: flex;
  align-items: center;
}

.show-new-viewer .cameraMenu .ui.selection.dropdown .menu > .item:last-child {
  border-bottom: none !important;
}

.show-new-viewer .cameraMenu .ui.selection.dropdown .menu > .item:first-child {
  border-top: 1px solid #707070 !important;
}

.show-new-viewer .cameraMenu .ui.selection.dropdown .menu > .item .text {
  opacity: 1;
  color: #f4f4f4;
  background: #000;
  width: 100%;
  height: 100%;
}

.show-new-viewer .cameraMenu .ui.selection.dropdown .menu > .item .text:hover {
  background: #454248;
}

.show-new-viewer .cameraMenu .centerMenuTop ul li span::before {
  content: none !important;
}

.show-new-viewer #cameraDropdown {
  color: black;
  background-color: white;
  height: 25px;
  width: 100%;
  padding: 15px;
}

.show-new-viewer #cameraDropdown > div.visible.menu.transition {
  top: 100% !important;
  left: 0px;
}

.show-new-viewer #cameraDropdown > div.visible.menu.transition > div.item {
  padding: 12px !important;
  background-color: #1d1d1d !important;
}

.show-new-viewer
  #cameraDropdown
  > div.visible.menu.transition
  > div.active.selected.item {
  background-color: #282829 !important;
}

.show-new-viewer
  #cameraDropdown
  > div.visible.menu.transition
  > div.item
  > span.text {
  background-color: transparent !important;
  color: #ccc !important;
}

.show-new-viewer
  #cameraDropdown
  > div.visible.menu.transition
  > div.active.selected.item {
  color: white !important;
}

.show-new-viewer .search-icon-wrapper {
  display: flex;
  object-fit: cover;
}

.show-new-viewer .publisher-panel-search .ui.search {
  width: 100%;
}

.show-new-viewer .publisher-panel-search .ui.search input {
  background-color: transparent !important;
  padding: 0px;
  outline: none !important;
  padding-left: 12px;
  width: 100%;
  color: #8d8f9c !important;
  border: none !important;
  outline: none !important;
}

.show-new-viewer .publisher-panel-search {
  min-width: 280px;
  padding: 8px 12px;
  margin: 12px;
  border-radius: 48px;
  background-color: #282829;
  display: flex;
}

.show-new-viewer .publisher-panel-search:hover {
  background-color: #2a2a2b;
}

.invite-guest-confirm {
  background: #1b1b1b !important;
  color: #707070;
}
.invite-guest-confirm .header {
  display: none;
}

.newguest-invite-modal {
  height: 200px !important;
  background-color: #1b1b1b;
  color: #707070;
  width: 450px;
}
.newguest-invite-modal .guest-content {
  height: 140px;
  padding: 20px 25px;
  line-height: 26px;
  letter-spacing: 1.4px;
  font-size: 16px;
  width: 100%;
}

.newguest-invite-modal .guest-action-buttons {
  float: right;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  gap:8px;
}
.newguest-invite-modal .guest-button:hover {
  cursor: pointer;
  background-color: #6a29ff;
  color: #f4f4f4;
}
.newguest-invite-modal .guest-button {
  background: #e0e1e2 none;
  font-weight:700;
  border: none;
  border-radius: 0.28571429rem;
  color: rgba(0,0,0,.6);
  cursor: pointer;
  display: inline-block;
  height: 36px;
  width:86px;
}
.guest-invite-loader {
  height: 200px;
  width: 360px;
}
.guest-users-list-title {
  font-weight: 700;
}

.guest-users-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.guest-user-remove-btn-wrapper {
  display: flex;
  align-items: center;
}
.guest-user-list-remove-btn {
  color: #ffffff;
  background-color: #6f2d88;
  font-weight: 600;
  height: 25px;
  width: 100px;
  border: none;
  cursor: pointer;
}
.guest-viewer-url-container {
  height: 60px;
  border-bottom: 1px solid #212222;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items:center; */
  padding: 0px 20px 0px 20px;
}
.guest-viewer-url-container-hidden {
  height: 60px;
  border-bottom: 1px solid #212222;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items:center; */
  padding: 0px 20px 0px 20px;
  visibility: hidden;
}
.guest-user-status-circle {
  padding-top: 8px;
  border-right: 4px solid #141414;
}
.guest-url-status-active {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: #41ff00;
  display: inline-block;
}
.guest-url-status-expired {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: red;
  display: inline-block;
}
.guest-user-status-waiting {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: #f39200;
  display: inline-block;
}
.guest-invite-modal-trigger-btn {
  color: #ffffff;
  background-color: #1d1d1d;
  border: none;
  font-size: 25px;
  cursor: pointer;
}
.guest-invite-modal-trigger-btn-disabled {
  color: #333;
  background-color: #1d1d1d;
  border: none;
  font-size: 25px;
  cursor: not-allowed;
}
.guest-viewer-url-container-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.guest-invite-modal {
  /*background-color: #1c1d1d;*/
  z-index: 1005;
}
.remove-btn-status-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}
.copy-icon {
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #ae2aff;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.toggle-buttons {
  float: right;
  margin-left: 36%;
  width: 20%;
  margin-top: -20px;
}
.allowGuestToggleOn {
  background-image: url("../../Images/toggleOn.svg") !important;
  background-repeat: no-repeat;
  /* position: absolute; */
  z-index: 1001;
  height: 30px;
  width: 45px;
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.allowGuestToggleOff {
  background-image: url("../../Images/toggleOff.svg") !important;
  background-repeat: no-repeat;
  /* position: absolute; */
  z-index: 1001;
  height: 30px;
  width: 45px;
  margin-top: 8px;
  border-left: 5px solid #141414;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.url-holder {
  border: none;
  background-color: #141414;
  color: #fff;
  cursor: auto;
}
.url-holder-expired {
  border: none;
  background-color: #141414;
  color: #fff;
  cursor: auto;
  text-decoration: line-through;
}
.passcode-holder {
  border: none;
  background-color: #141414;
  color: #fff;
  cursor: auto;
  font-weight: 700;
}
.passcode-holder-expired {
  border: none;
  background-color: #141414;
  color: #fff;
  cursor: auto;
  font-weight: 700;
  text-decoration: line-through;
}

/* watermark css */
.show-watermark-ui {
  height: auto;
  min-height: 50px;
  /* width: 100%; */
  position: absolute;
  z-index: 1;
  color: #ffffff;
}
.watermark-tab-button {
  border: none;
  flex-basis: 100%;
  background: none;
  color: #f4f4f4;
  min-width: 100px;
  line-height: 40px;
  outline: none;
  cursor: pointer;
}

.watermark-tab-button.active {
  background: #8a2be2;
  color: #f4f4f4;
}

.watermark-panel-user {
  width: 70%;
  color: white;
}

.watermark-toggle-button {
  width: 25%;
  display: flex;
  justify-content: center;
}

.allowToggleOn {
  background-image: url("../../Images/toggleOn.svg") !important;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1001;
  height: 30px;
  width: 45px;
  cursor: pointer;
  margin-top: -10px !important;
}
.allowToggleOff {
  background-image: url("../../Images/toggleOff.svg") !important;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1001;
  height: 30px;
  width: 45px;
  cursor: pointer;
  margin-top: -10px !important;
}
.margin10 {
  margin-top: 10px !important;
}
.watermark-panel-checkbox {
  padding: 5px;
  display: flex;
  align-items: center;
}

.watermark-panel-lable {
  width: 70%;
  color: white;
  margin-left: 10px;
}

.watermarkInput-field {
  width: 100%;
  height: 45px;
  padding: 10px;
  border-radius: 10px solid #0c0c0c;
}

.watermark-input-left {
  width: 49%;
  color: white;
  margin: 10px;
  float: left;
}

.watermark-input-right {
  width: 49%;
  color: white;
  margin: 10px;
  float: right;
}

.publisher-panel-content .ui.form .field > label {
  font-size: 1rem;
  font-weight: 700;
  text-transform: none;
  color: white;
  margin: 10px;
}

.publisher-panel-content .ui.form .inline.fields .field > label {
  font-weight: 700;
  text-transform: none;
  color: #000;
  background: white;
  height: 45px;
  width: 100%;
  position: absolute;
  border: 5px solid #000;
  padding: 8px;
  font-size: 16px;
}

.color-picker {
  position: absolute;
  margin: 5px 12px;
  border: none;
  width: 25px;
}

.color-hex-value {
  width: 170px !important;
}

.opacity-range {
  position: absolute;
  margin-top: 20px !important;
  left: 100px;
  border: 1px solid #8a2be2 !important;
  width: 185px !important;
  transform: rotate(360deg) !important;
}

.watermark-action-button {
  padding: 8px 12px;
  background-color: #282829 !important;
  border-radius: 8px;
  outline: none !important;
  border: 0px solid black !important;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.watermark-action-button:hover {
  background-color: #9f5fff !important;
}
.opacity-percentage {
  color: #000;
  position: absolute;
  right: 10px;
  top: 117px;
}
.location-label {
  margin: 40px 10px 10px !important;
}
.watermark-panel-checkbox .ui.checkbox label,
.ui.checkbox + label {
  color: #fff !important;
}
.ui.selection.dropdown.watermark-dropdown {
  padding: 8px !important;
  border-radius: 5px !important;
  min-height: 38px !important;
}
.watermark-dropdown.ui.selection.dropdown > .dropdown.icon {
  padding: 10px !important;
}
.watermark-dropdown.ui.search.dropdown > .text {
  font-size: 14px;
}
/* :root{
  --color: red;
  --alpha: 40%;
  --font-size-small: 12px;
  --font-size-normal: 14px;
  --font-size-large: 16px;
  --font-size: var(--font-size-normal);
}
#watermark {
  text-align: left;
  font-size: var(--font-size);
  color: rgba(var(--color), var(--alpha));
} */

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.ant-spin-nested-loading .ant-spin-blur::after {
  opacity: 1;
  z-index: 10000;
  background-color: #ffffff;
}

.pulsating-icon {
  width: 30px;
  height: 30px;
  margin-top: 12px;
  background-image: url("../../Images/guest_whitebg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: pulsate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes pulsate {
  0% {
    background-image: url("../../Images/guest_whitebg.svg");
    transform: scale(1);
  }
  50% {
    background-image: url("../../Images/guestRed.svg");
    transform: scale(1.2);
  }
  100% {
    background-image: url("../../Images/guest_whitebg.svg");
    transform: scale(1);
  }
}

.guest-invite-notification-modal .ant-modal-content{
  padding: 0% 0% 0% 0%;
  width:450px;
}

.guest-invite-notification-modal .ant-modal-footer{
  padding: 0% 0% 0% 0%;
}

.miniheader-modal .ant-modal-content {
  padding: 2px;
  border:2px solid #141414;
  border-radius: 8px;
  background-color: black; /* Added background color */
}

.miniheader-btn {
  width: 56px;
  height: 100%;
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  transition: 0.4s;
  background-color: #141414;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
}

.miniheader-btn-right {
  padding: 5px;
}

.miniheader-modal .ant-modal-footer{
  padding: 0% 0% 0% 0%;
}

.miniheader-modal-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:5px
}
.miniheader-modal-row{
  display: flex;
  flex-direction: row;
  gap:5px;
  justify-content: center;
}

.viewcontainer .ant-spin-blur {
  opacity: 0;
}
.mediaStatsMenu {
  float: left;
  font-size: 1em;
  cursor: pointer;
}

.hudStats-layout {
  width: 30%;
  position: absolute;
  float: left;
  margin-left: 8%;
  top:11%;
  z-index: 50;
  background-color: rgba(76, 76, 76, 0.85);
}

.hud-stats {
  display: flex;
  align-items: center;
  gap: 50%;
  margin-top: 30px;
  padding: 10px;
}

.stat {
  display: flex;
  align-items: center;
  color: white;
}
.media-stats-scroll {
  overflow-y: auto;
  max-height: 420px;
  margin-top: 30px;
}
.media-stats-scroll::-webkit-scrollbar-track {
  background-color: #d4d4d5;
}
.value {
  font-weight: bold;
}

.label {
  font-size: 12px;
}

.layout-close {
  float: right;
  padding: 5px;
  color: white;
}
.stats-table {
  width:100%;
  border-collapse: separate;
  border-spacing:0;
  margin: 20px;
}
.stats-table td + td{
  padding-right: 10px; 
}
.stats-table tr + tr {
  margin-top :15px;
}

  /* iPad vertical-specific styles */
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (not (max-width: 767px)) {

    .show-new-viewer .main-menu-right-chat {
      height: 60%;
      width: 92%;
      min-width: 320px;
    }
    .show-new-viewer .video-tiles-row-wrapper{
      position: fixed;
      z-index: 100;
    }
    .show-new-viewer .expand-collapse-btn{
      position: fixed;
    }
    .show-new-viewer .menuTopRight ul li {
      margin-right: 0px;
    }
    .show-new-viewer .chat-msg-input{
      background-color: #141414;
    }
    .show-new-viewer #guest-invite-tray .guest-invite-content{
      min-height: auto;
    }
    .show-new-viewer .chat-wrapper{
      background-color: black;
      align-items: flex-end;
    }
    .show-new-viewer .video-tiles-row-wrapper.inverse-layout{
      top: 150px;
      width: 49%;
    }
    .show-new-viewer .inverse-layout .expand-collapse-btn{
      margin-top: 0px;
      top: 70px;
      position: absolute;
    }
    .show-new-viewer .player-wrapper{
      width: 100%
    }
    .guest-panel-wrapper.show-viewer{
      background-color: black;
      align-items: flex-end;
    }
    .show-new-viewer .viewerstream-video{
    width: 85%;
    position: fixed;
    padding: 50px;
    margin-right: 100px;
    }
    .show-new-viewer .avid-locators-panel-wrapper{
      background-color: black;
    }
    .show-new-viewer .video-tray-collapse-bg{
      height: 210px !important;
    }
    .show-new-viewer .publisher-panel-wrapper{
      background-color: black;
      align-items: flex-end;
    }
    input.color-hex-value{
      font-size: 1.06em !important;
    }
  }

  /* iPad Horizontal-specific styles */
  /* @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-height: 1366px) 
  and (orientation: landscape) 
  and (not (max-width: 767px)) {
    .show-new-viewer .main-menu-right-chat {
      margin-top: 100px;
      height: 60%;
    }
    .show-new-viewer .video-tiles-row-wrapper{
      position: fixed;
    }
    .show-new-viewer .expand-collapse-btn{
      position: fixed;
    }
  } */
@media only screen 
and (max-device-width: 1000px) 
and (max-device-height: 1000px) 
and (orientation: landscape)
 {
 .show-new-viewer .show-viewer-leftmenu {
    position: fixed;
    width: 90px;
    top: 0px;
    left: 0px;
    height: calc(100vh - 200px);
    background-color: black;
    transition: width 0.2s ease-out;
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 0;
    gap: 24px;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}


/* MEDIA QUERY FOR PIXEL PHONES */
@media only screen and (max-width: 600px) {

  .show-new-viewer .main-menu-right-chat,
  .zoom-viewer .main-menu-right-chat{
    height: 60%;
    width: 70%;
    position: fixed;
    right: 0;
    margin-right: 10px;
    min-width: 0px;
  }
  .show-new-viewer .publisher-panel-content {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .show-new-viewer .guest-panel-wrapper{
    background-color: #000;
  }
}
