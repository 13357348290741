.masv-body-content {
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.masv-body-content-left {
  width: 25%;
  height: inherit;
  background: rgb(123, 122, 122);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
}
.masv-body-content-right {
  width: 70%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 30px;
}

.masv-register-iframe {
  height: inherit;
  width: 100%;
  /* margin: 5px solid blue; */
  text-align: center;
}
.masv-create-keys {
  height: inherit;
  width: 100%;
}
.masv-create-keys .section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.masv-create-keys .section .ant-table-thead .ant-table-cell {
  color: #ffffff !important;
}

.masv-create-keys .section .key-holder{
  overflow-x:scroll;
  overflow-x:hidden;
  width:200px;
}

.masv-body-content-left .masv-body-content-left-btn{
  height: 40px;
  width: 200px;
  background-color:#6435c9;
  margin-bottom: 10px;
  font-weight: bold;
}

.masv-body-content-left .create-btn-left {
  margin-bottom: 30px;
}

.masv-body-content-right .section-body-content-right{
  text-align: left;
  background-color: #6435c9;
  margin-top: 100px;

}

.masv-body-content-right .create-api-right{
  background-color: #6435c9;
  height: 40px;
  width: 200px;
  /* margin-top: 70px; */
  font-weight: bold;
  margin-left: 130px;
}
.create-api-btn :disabled {
  background-color: #6435c9;
  background-image: none;
  color: #fff;
  text-shadow: none;
  opacity: .45!important;
}
.masv-create-keys .create-api-btn{
  /* margin-right: 70px; */
  font-family:'Times New Roman', Times, serif;
  /* margin-top: 50px; */

}
.masv-create-keys .section-header{
  margin-left: 60px;
  margin-top: 40px;
}

.masv-create-keys .section-header-form{
  margin-top: -250px;
}

 .masv-body-content-right .right-header{
  margin-bottom: 320px;
  margin-top: -80px;
}
 
.masv-body-content-left .left-header{
  color:#ffffff;
  margin-bottom: 280px;
  margin-top: 50px;
  
}

.masv-create-keys .right-side-mail-pass{
  height: 40px;
  width: 500px;
  margin-top:30px;
  margin-left: -60px;
}

.masv-body-content-right .right-sign-up{
  margin-top: 260px;
  margin-left: 60px;
  margin-bottom: 190px;
}

.masv-body-content-right .right-sign-up-btn {
  height: 40px;
  width: 200px;
  background-color: #6435c9;
  font-weight: bold;
}
.right-side-mail-pass .ant-input {
  height: 40px;
}
.right-side-mail-pass .ant-input-affix-wrapper.ant-input-password {
  padding: 0 11px;
}
.right-side-mail-pass .ant-form-item .ant-form-item-label {
  height: 100%;
  text-align: start;
}
.right-side-mail-pass .ant-table-wrapper {
  margin-left: -170px;
}
.api-table .ant-table-cell {
  min-width: 135px;
}
.err-message {
  color: #ff0000;
  margin-left: 170px;
}
.delete-api-btn {
  background-color: #6435c9;
  font-weight: bold;
}
.right-side-mail-pass2 {
  align-self: center;
  width: 100%;
  overflow-x: auto;
}

.right-side-mail-pass2 .ant-table-wrapper table{
  width: 100%;
}
.modal-delete .ant-btn-default:hover {
  color: #6435c9;
  border-color:#6435c9;
}
