@charset "utf-8";
/* CSS Document */
*, *:focus, *:hover {
  outline: none;
  box-sizing: border-box;
}
.home .btn:focus {
  box-shadow: none;
}
.home {
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.home {
  width: 100%;
  height: 100vh;
}
.home {
  background: #FFF;
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: normal;
  color: #000;
  width: 100vw;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 0px;
  margin: 0 auto;
  position: relative;
}
.home .block {
  display: block;
}
.home .wraparea {
  max-width: 1750px;
  margin: 0 auto;
  width: 100%;
}
.home .flex {
  display: flex;
  align-items: center;
}
.home h1 {
  font-size: 42px;
  line-height: 1.4;
  color: #FFFFFF;
  opacity: 1;
  font-family: 'DM Sans';
  font-weight: 700;
  letter-spacing: -.02em;
}
.home h2 {
  margin: 0;
  font-size: 42px;
  line-height: 1.2;
  font-family: 'DM Sans';
  font-weight: 700;
  color: #FFFFFF;
  margin-top: 0px !important;
  letter-spacing: -.02em;
}
.home h3 {
  font-size: 24px;
  font-family: 'DM Sans';
  font-weight: 700;
  margin: 0 auto;
  line-height: 1.2;
  letter-spacing: -.02em;
}
.home p {
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -.02em;
  margin: 1rem 0;
  font-family: 'DM Sans';
  font-weight: 700;
}
.home .headingText {
  text-align: center;
}
.home .headingText p {
  padding: 0px 120px 0;
  letter-spacing: -.02em;
  font-size: 20px;
  line-height: 30px;
  color: #FFF;
}
.home .headingText h2 {
  margin-bottom: 25px;
}