.zoom-meeting .test-speaker-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
  
.zoom-meeting .head-speaker {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #fff;
}
  
.zoom-meeting .speaker-heading-test {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    margin-top: 49px;
}
.zoom-meeting .speaker-heading-output{
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    margin-top: 28px;
}
.zoom-meeting .options-speaker {
    width: 500px;
    height: 60px;
    padding: 4px 10px;
    border-radius: 8px;
    border: 1px solid #909090;
    background-color: transparent;
    color: #909090;
    white-space: nowrap;
    box-sizing: border-box;
}

.zoom-meeting .options-speaker .ant-select-selector {
    background: #000 !important;
    border: none !important;
    color: #fff !important;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.zoom-meeting .options-speaker .ant-select-dropdown {
    background: #000;
}

.zoom-meeting .options option {
    background-color: transparent;
    color: #141414;
}
  
.zoom-meeting .try-another{
    border-color: white;
    color: white;
    background-color: #141414;
    width:160px; 
    height:32px; 
    border: none;
    font-size: 12px;
}

.zoom-meeting .try-another:hover,
.zoom-meeting .yes:hover{
    background-color: #6F2DDA !important;
    color: white !important;
    border: 0px;
    height:32px; 
    font-size: 12px;
}

.zoom-meeting .yes {
    border-color: white;
    color: white;
    background-color: #141414;
    width:50px;
    height:32px; 
    font-size: 12px;
} 

.zoom-meeting .button-group-speaker {
    display: flex;
    width:100%;
    flex-direction: row;
    justify-content: right;
    gap: 10px;
    margin-top: 30px;
    /* position: relative; */
    /* left: 32%; */
}
