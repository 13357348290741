@charset "utf-8";
/* CSS Document */
html {
  scroll-behavior: smooth;
}
.home {
  background: #101010;
}
.home {
  padding-top: 110px !important;
  position: relative;
}
/* Header */
.main-header {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #101010;
  position: fixed;
  z-index: 99;
  width: 100%;
  height:100px;
  top: 0;
  left: 0;
  right: 0;
}
.main-header:before {
    webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(29,29,31,0.10);
    content: "";
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    z-index: -1;
}
.buttonGroup button {
  border: 1px solid #FFF;
  background: none;
  font-size: 18px;
  font-weight: 600;
  color: #FFF;
  line-height: 1;
  padding: 19px 46px;
  text-transform: uppercase;
  margin-left: 25px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}
.buttonGroup button:hover {
  color: #4D089A;
  background: #FFF;
}
.buttonGroup button.signup {
  border-color: #4D089A;
  background: #4D089A;
}
.buttonGroup button.signup  a{
	color:#FFF;
}
a{
	text-decoration:none !important;
}
.buttonGroup button.signup:hover {
  background: #5609AE;
  border-color: #5609AE;
  color: #FFF;
}
.main-nav {
  margin-left: 120px;
}
.mobile-nav {
  width: 270px;
  position: fixed;
  background: #000;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.mobile-nav ul {
  margin-top: 30px;
  font-size: 16px;
  font-family: "DM Sans";
  font-weight: 500;
  letter-spacing: -.02em;
}
.mobile-nav li a{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-height: 40px;
  color: #FFF;
  position: relative;
  text-transform: uppercase;
  transition: .1s ease-in-out;
  transition-property: color,background-color,border-color,box-shadow;
}
.nav_btn {
  top: 10px;
  right: 10px;
  padding: 5px;
  position: absolute;
}
.main-nav ul {
  list-style-type: none;
}
.main-nav li {
  padding: 0px 15px;
  display: inline-block;
  position: relative;
  line-height: 1;
}
.main-nav li:before {
  position: absolute;
  content: "";
  width: 0px;
  height: 2px;
  background: #9b34ff;
  left: 10px;
  right: 10px;
  margin: 0 auto;
  bottom: 10px;
  z-index: 0;
}
.main-nav li:hover:before {
  width: calc(100% - 20px);
  opacity: 1;
}
.main-nav li a {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 50px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'DM Sans';
  color: #FFF;
  position: relative;
  text-transform: uppercase;
  transition: .1s ease-in-out;
  transition-property: color,background-color,border-color,box-shadow;
}
.banner-main {
  margin-top: -10px;
  left: 1%;
  width: 98% !important;
  position: relative;
  /* background: url("/assets/images/latest/retro-tv-sets.jpeg") center top no-repeat; */
  background-color: rgb(86, 86, 86);
  padding: 150px 0;
  background-size: cover;
  background-position: 100% 50%;
}
.banner-main img {
  width: 100%;
  display: block;
  line-height: 1;
}
.banner-main .captionText {
 /* margin-left: 120px;
  position: absolute;
  top: 30%;*/
 /* -webkit-transform: translateY(-50%);
  transform: translateY(-50%);*/
  max-width: 100%;
  text-align:center;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.banner-main .captionText .logoInDemo {
  left: 45%;
  top: 40%;
  position: absolute;
}

.banner-main .logoInDemo img {
  min-width: 130px;
  height: 115px;
  margin-top: 10px;
}

.banner-main .captionText h1 {
	max-width: 800px;
	color: #FFFFFF;
  margin: 0 0 20px 0;
  line-height: 1.2;
  font-size: 42px;
	font-weight: 700;
	margin: 0 auto;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 100%;
	top: 85px;
}
.banner-main .captionText a, .get-start-now {
	border: 1px solid #FFF;
  border-radius: 3px;
	font-size: 13px;
	font-weight: 700;
	color: #4D089A;
	line-height: 1;
	padding: 15px 20px;
	text-transform: uppercase;
	font-family: 'DM Sans';
	cursor: pointer;
	border-color: #FFF;
	background: #FFF;
	position: absolute;
	bottom: 60px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
/* .get-start-now:hover{
  background: #5609AE;
  border-color: #5609AE;
  color: #FFF;
}
.get-start-now {
  border: 1px solid #FFF;
	font-size: 18px;
  font-weight: 600;
  width: 700px;
  margin: 0 auto;
  display: block;
  padding: 5%;
  text-align: center;
  position: relative;
  top: -22px;
  border-top: 2px solid #C4C4C4;
	color: #FFF; 
	text-transform: uppercase; 
	font-family: 'Montserrat', sans-serif;
  cursor: pointer;
	border-color: #4D089A;
	background: #4D089A;
}
*/

/* PP-live Video */
.pp-live-video {
  height: 540px;
  max-width: 100%;
  text-align: center;
  margin: 20px;
}

.pp-live-video video {
  width: 970px;
  display: block;
  margin: 0 auto;
}
.video-frame{
  width: 100%;
  min-width: 320px;
  height: 100%;
}
/* Section 01 */
.section01 {
  background: #101010;
  padding: 70px 70px;
}
.section01 .imagewrap {
  box-shadow: 30px 30px 0px #4D089A;
  flex: 0 0 730px;
  width: 730px;
  margin-right: 120px;
}
.section01 .imagewrap img {
  line-height: 1;
  display: block;
}
.section01 .content-area h3 {
  margin-bottom: 35px;
  color:#fff;
}
.section01 .border-content p {
  letter-spacing: 0.25px;
  color:#fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.section01 .border-content {
  position: relative;
  width: 50%;
  padding-left: 35px;
}
/* Section 02 */
.section02 {
  background: #423E6D;
  padding: 100px 0;
  display:none;
}
.section02 h2 {
  text-align: center;
}
.logosCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}
.logosCenter .logosItem {
  margin: 0px 66px;
}
/* Section 03 */
.section03 {
  /* background: url("/assets/images/latest/lenses.png") center top no-repeat; */
  background-size: 1769px 2063px;
  background-position: calc(-1px) calc(-509.2px);
}
.section03 .wraparea {
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.section03 .headingText {
  padding-top: 100px;
}
.areaListContent ul {
  list-style-type: none;
  margin-left: -40px;
}
.areaListContent .dataDiv {
  position: relative;
  padding: 30px;
  text-align: center;
}
.areaListContent li::after {
  content: "";
  position: absolute;
  right: 0px;
  left: 0px;
  border: 1px solid rgba(255,255,255,.1);
}
.areaListContent ul li h3, .areaListContent ul li p {
  color: #FFF;
  margin-top: 20px !important;
  margin-bottom: 0px;
}
.video {
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 80px;
  display: table;
}
.video img {
  max-width: 100%;
}
.section06 {
  background: #101010;
  padding: 30px 0;
}
.section06 .textareaCenter{
  text-align: center;
  width: 85%;
  margin: 0 auto;
}
.section06 .textareaCenter p{
  color: #fff;
}
.section06 img{
  margin: 30px 100px 0px;
  width: 85%;
  height: 600px;
}
.section04 {
  background: #101010;
  padding: 0px 30px 0;
  margin-left: -15px;
}
.section04 .headingText{
  margin-top: 100px;
  position: absolute;
  z-index: 1;
}
.section04 h2, .section04 p{
	color:#fff;
}
.section04 .speed_video {
  max-height: 300px;
  overflow: hidden;
}
.speed_video video {
  width: 100%;
  height: auto;
  transform: translate(0%,-30%);
}
.section05 {
  width: 96.7%;
  margin-left: 15px;
  text-align: center;
  position: relative;
  /* background: url("/assets/images/latest/boy_with_cape.png") center top no-repeat; */
  padding: 170px 0;
  background-size: cover;
  background-position: 100% 50%;
}
.section05 .wraparea {
  background-color: rgba(0, 0, 0, 0.24);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.section05 .textareaCenter {
  margin: 100px auto;
  width: 85%;
  text-align: center;
}
.section05 .textareaCenter h2 {
  /*font-size: 113px;*/
  line-height: 1;
  margin-bottom: 0px;
  color: #fff;
}
.section05 .textareaCenter p {
  font-size: 20px;
  text-align: center;
  color: #fff;
  /*font-size: 40px;
  line-height: 50px;
  font-weight: 500;*/
  margin-top: 20px;
  margin-bottom:75px;
}
.multipleSec {
  background: #101010;
  padding: 70px 0;
}
.sliderArea {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 85%;
}
.sliderArea .item {
  width: 33%;
  padding-left: 20px;
}
.sliderArea .item img{
  width: 350px;
  height: 240px;
}

.multipleSec .headingText h2, .multipleSec .headingText p{
	color:#fff;
}
.areaunderslider h3 {
  color: #fff;
  margin-bottom: 20px;
  margin-top: 40px;
}
.areaunderslider p {
  color: #fff;
  margin: 0 auto !important;
  font-size: 16px;
  font-weight: 500;
}
.contentList-bottom {
  background: #101010;
  margin-left: 80px;
}
.contentList-bottom .wraparea {
  display: flex;
}
.contentList-bottom .wraparea .textArea {
  max-width: 27%;
  margin-left: 20px;
}
.contentList-bottom .wraparea .textArea * {
  color: #FFF;
}
.contentList-bottom .wraparea .textArea p {
  margin-bottom: 0px;
  font-size: 16px;
}
.contentList-bottom .wraparea .textArea img {
  margin-bottom: 25px;
}
.videoSection {
  background: #101010;
  padding: 80px 0 50px;
}
.videoSection .headingText {
  max-width: 1310px;
  margin-bottom: 0;
}
.videoSection .headingText h2{
	color:#fff;
}
.videoSection .headingText p {
  padding: 0px 40px;
  color:#fff;
}
.videoSection .headingText img {
  display: block;
  margin: 40px auto 0;
}
.collaborate {
  background: #101010;
  padding: 150px 0 70px;
}
.collaborate p {
  width: 98%;
}
.collaborate img {
  width: auto;
}
.devicesArea ul {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  list-style-type: none;
}
.devicesArea ul li {
  padding: 0px 130px;
}
.devicesArea ul li span {
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: #FFF;
  text-align: center;
  margin-top: 40px;
}
.support {
  position: relative;
  /* background: url("/assets/images/latest/support.png") center top no-repeat; */
  padding: 170px 0;
  background-size: cover;
  background-position: 100% 50%;
}
.support .wraparea {
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.support .dataText {
  margin-top: 100px;
}
.support .dataText h2 {
  color:#fff;
  text-align: center;
}
.support .dataText p {
  text-align: center;
  font-size: 20px;
  line-height: 1.4;
  width: 85%;
  margin: 15px auto;
  color: #fff;
}
.pricing {
  background-size: cover;
}
.pricing_video {
  width: 100%;
  max-height: 600px;
  overflow: hidden;
}
.pricing_video video {
  min-width: 100%;
  width: auto;
  height: auto;
  transform: translate(0%,-8%);
}
.prices {
  width:100%;
  height: 600px;
  position: absolute;
  color: #fff;
  display: table;
  z-index: 1;
  padding: 0 70px;
  background-color: rgba(0, 0, 0, 0.55);
}
.prices h2 {
  text-align: center;
  padding-top: 70px;
}
.prices li {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
}
.prices p {
  font-size: 20px;
}
.prices img {
  max-width: 100%;
}
.prices .book_demo {
  border-radius: 3px;
  left: 45%;
  border: 1px solid #FFF;
  font-size: 13px;
  font-weight: 700;
  color: #4D089A;
  line-height: 1;
  padding: 15px 20px;
  text-transform: uppercase;
  font-family: 'DM Sans';
  cursor: pointer;
  border-color: #FFF;
  background: #FFF;
  position: absolute; 
}
.form-bottom {
  background: #101010;
  padding: 100px 0;
}
.form-bottom .wraparea {
  display: flex;
  justify-content: space-between;
}
.form-bottom .wraparea label {
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  font-family: 'DM Sans';
  color: #FFF;
  margin-bottom: 5px;
  float: left;
}
.form-bottom .wraparea input, .form-bottom .wraparea textarea {
  width: 100%;
  height: 40px;
  background: #101010;
  font-size: 14px;
  font-family: 'DM Sans';
  color: #fff;
  border: 0px none;
  padding: 10px 16px;
  margin-bottom: 15px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	appearance:none;
	-webkit-appearance:none;
}
.form-bottom .wraparea textarea {
  height: 83px;
  resize: none;
}
.form-bottom .wraparea input[type="submit"] {
  background: #4D089A;
  font-size: 18px;
  font-weight: 600;
  color: #FFF;
  text-transform: uppercase;
  width: 250px;
  height: 60px;
  font-family: 'DM Sans';
  margin-top: 25px;
  cursor:pointer;
  margin-bottom: 0px;
}
.form-bottom .wraparea .half:last-child {
  max-width: 735px;
  width: 100%;
}
.form-bottom .wraparea .half img {
  margin-bottom: 25px;
}
.footer-main {
  background: #fff;
  padding-top: 33px;
  padding-left: 35px;
  padding-right: 35px;
}
.copyrightArea ul {
  padding: 0;
  margin: 0;
}
.copyrightArea {
  display: flex !important;
  justify-content: space-between;
  border-top: 1px solid #707070;
  margin-top: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.copyrightArea ul li {
  float: left;
  margin-right: 80px;
}
.copyrightArea ul li a {
  color: #FFF;
  text-decoration: none;
  font-size: 20px;
}
.copyrightArea p {
  margin: 0;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
}
.section01 .content-area {
	display: flex;
	align-items: flex-start;
}	
.section01 .content-area img{
	min-width:700px;
	margin:0px 80px;
}
.linkA{
	position: absolute;
	margin-top: -185px;
}
.innerPagesArea{
	background:#FFF;
	min-height:700px;
	float:left;
}
.textStyle {
	width: 100%;
	color: #545454;
	padding-bottom: 40px;
	padding: 0 15px;
}
.home .textStyle h2 {
	text-align: center;
	color: #5609AE;
	margin: 45px 0 !important;
	line-height:1;
}
.textStyle p + h3{
	margin-top:55px;
}