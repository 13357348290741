.profile_image {
  background: transparent url(../../Images/intermediate_new.png) 60% 100% no-repeat padding-box;
  opacity: 1;
  background-size: cover;
  /* height: 90vh; */
  background-position: 50%;
  width: 50%;
  height: 90%;
  position: absolute;
}

.profile_heading {
  font-size: 25px;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

.dropzone {
text-align: center;
padding: 28px;
margin-left: 150px;
border: 3px dashed #eeeeee;
cursor: pointer;
/* background-color: #fafafa;
color: #bdbdbd; */
}

.profile_img {
float: left;
display: block;
margin: 0px auto;
border: 3px solid #707070;
height: 100px;
width: 100px;
margin-top: -10px;
margin-left: 40px;
margin-bottom: 45px;
border-radius: 50%;
opacity: 1;
cursor: pointer;
}

  .profile_no_img {
    background: #FFFFFF url("../../Images/pp_logoblack.svg") 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position: center;
  }

.profile_img1 {
float: left;
display: block;
margin: 0px auto;
border: 3px solid #707070;
height: 100px;
width: 100px;
margin-top: -10px;
margin-left: 40px;
margin-bottom: 45px;
border-radius: 50%;
/* background: transparent url("../../Images/user.png") 0% 0% no-repeat padding-box; */
opacity: 1;
background-size: cover;
background-position: center;
}

.dragContainer {
border: 2px dashed #707070;
height: 80px;
cursor: pointer;
padding-left: 66px;
margin-bottom: 35px;
margin-left: 155px;
}
#profile-text{
width: 100%;
height: auto;
margin-bottom: 5px;
display: flex;
}
#profile-text div:nth-of-type(1) {
flex-basis:96%;
padding-left: 165px;
}
#profile-text  div {
flex-basis: 30px;
}
.remove_profilepic{
height: 20px;
width: 20px;
background: #FFFFFF url("../../Images/trash.svg") 0% 0% no-repeat padding-box;
background-size: contain;
background-position: center;
cursor: pointer;
}

.text-removebutton{
display: flex;
}
