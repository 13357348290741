.location-label .ant-checkbox-wrapper span {
  font-size: 1rem;
  font-weight: 700;
  text-transform: none;
  color: white !important;
}
.marquee-left,
.marquee-right {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee-left div {
  padding-left: 100%;
  animation: marqueeLeft 8s linear infinite;
}
@keyframes marqueeLeft {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.marquee-right div {
  padding-left: 100%;
  /* width: 100%; */
  animation: marqueeRight 8s linear infinite;
}
@keyframes marqueeRight {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.ant-switch {
  background: #d9d9d9;
}
.ant-switch.ant-switch-checked {
  background: #ae2aff;
}
.ant-switch:hover:not(.ant-switch-disabled) {
  background: #ae2aff;
}
[ant-click-animating-without-extra-node='true'],
[ant-click-animating='true'] {
  background: #d9d9d9;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #ae2aff;
}
.show-watermark-ui {
  word-break: break-all;
}
.show-watermark-ui p {
  margin: 2px !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.watermark-actions.ui.button:hover,
.watermark-actions.ui.button:active,
.watermark-actions.ui.button:focus {
  color: #ffffff;
}
.show-new-viewer .watermark-actions .action-clear-changes > button {
  border: none !important;
  font-size: 18px;
  color: #ffffff;
}
.watermark-slider-range-label {
  color: #000000;
  text-align: right;
  line-height: 30px;
  font-weight: bold;
}
.watermark-row {
  background: #fff;
  padding: 0 15px;
  margin: 5px 0;
  border-radius: 2px;
}
.watermarkTab .ant-tabs-nav-wrap {
  flex: 0 0 100% !important;
}
.watermarkTab .ant-tabs-nav-list {
  width: 100%;
}
.watermarkTab .ant-tabs-tab,
.watermarkTab .ant-tabs-ink-bar {
  width: 50% !important;
  color: #ffffff !important;
}
.watermarkTab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.watermarkTab .ant-list-item-meta-title {
  color: #ffffff !important;
}

.watermarkTab .ant-tabs-tab-btn {
  margin: 0 auto;
}
.watermarkTab .ant-tabs-ink-bar-animated {
  background: #ae2aff !important;
}
.watermark-viewer-panel {
  margin: 5px 10px;
}
.watermarkTab .ant-list-item-meta-title {
  text-transform: capitalize;
}
.watermarkTab .ant-list-item-meta-description,
.ant-list-empty-text {
  color: rgba(255, 255, 255, 0.45) !important;
}

#scrollableDiv {
  height: 600px;
  overflow: auto;
  margin: 5px 0;
  padding: 0 16px;
  border: 1px solid rgba(140, 140, 140, 0.35);
  border-radius: 5px;
}
.header-list {
  padding: 3px 10px;
  border-bottom: 1px solid white;
}
.ant-list-item-meta-description {
  word-break: break-all;
}
.watermark-label {
  color: #ffffff;
}

/* iPad Horizontal-specific styles */
 @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-height: 1366px) 
  and (orientation: landscape) 
  and (not (max-width: 767px)) {
    .show-watermark-ui {
      word-break: break-all;
      margin-top: 25px !important;
  }
} 

