/* List container with no bullets */
.intermediate-container .intermediate-sidemenu .meeting-list {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.intermediate-container .intermediate-sidemenu .meeting-list .meetings-container {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.intermediate-container .intermediate-sidemenu .meeting-list .meeting-item {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-left: 10px;
}

/* Individual meeting item */
.intermediate-container .intermediate-sidemenu .meeting-list .meeting-item .meeting-wrapper {
  /* display: flex; */
  /* flex-direction: column; */
  min-width: 150px;
  gap: 4px;
  padding: 6px;
  margin: 0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  visibility: visible;
  display: block;
}


.intermediate-container
  .intermediate-sidemenu:hover .meeting-name,
  
.intermediate-container
.intermediate-container-open .meeting-name{
    display: block;
  }

.intermediate-container .intermediate-sidemenu .meeting-list .meeting-item .meeting-options-wrapper,
.intermediate-container
  .intermediate-sidemenu:hover
  .meeting-list
  .meeting-item
  .meeting-options-wrapper,

  .intermediate-container
  .intermediate-container-open
  .meeting-list
  .meeting-item
  .meeting-options-wrapper {
  /* display: flex; */
  /* flex-direction: column; */
  min-width: 150px;
  gap: 24px;
  padding: 6px;
  margin-top: 0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  visibility: visible;
  display: flex;
  align-items: center;
  margin-top: 10px;

}

.intermediate-container .intermediate-sidemenu:hover .meeting-list .meeting-item .meeting-wrapper,
.intermediate-container .sidemenu-container-open .meeting-list .meeting-item .meeting-wrapper {
  /* visibility: hidden; */
  display: none;
  transition: background-color 0.3s ease;
}

.intermediate-container .intermediate-sidemenu .meeting-list .meeting-item .meeting-details {
  display: none;
  /* width: 299px;
  height: 36px; */
}

.intermediate-container .intermediate-sidemenu:hover .meeting-list .meeting-item .meeting-details,
.intermediate-container .sidemenu-container-open .meeting-list .meeting-item .meeting-details{
  display: flex;
  flex-direction: row;
  justify-content: left;
  /* align-items: left;  */
  gap: 5px;
  background-color: transparent;
  min-width: 299px;
  /* height: 36px; */
}

.intermediate-container
  .intermediate-sidemenu:hover
  .meeting-list
  .meeting-item
  .meeting-details
  .meeting-info,

  .intermediate-container
  .sidemenu-container-open
  .meeting-list
  .meeting-item
  .meeting-details
  .meeting-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  min-width: 150px;
}

/* .meeting-wrapper { */
/* padding-left: 10px; */
/* display: flex; */
/* flex-direction: row; */
/* gap: 16px; */
/* } */

/* Meeting name */
.intermediate-container
  .intermediate-sidemenu:hover
  .meeting-list
  .meeting-item
  .meeting-details
  .meeting-name,
  .intermediate-container
  .sidemenu-container-open
  .meeting-list
  .meeting-item
  .meeting-details
  .meeting-name {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 4px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Meeting details container */
.intermediate-container .intermediate-sidemenu:hover .meeting-list .meeting-item .meeting-details,
.intermediate-container .sidemenu-container-open .meeting-list .meeting-item .meeting-details{
  display: flex;
  flex-direction: row;
  /* justify-content: center;  */
  align-items: center;
  padding-left: 10px;
  gap: 36px;
  min-width: 150px;
  min-height: 59.9px;
  transition: background-color 0.3s ease;
}

/* Meeting time */
.intermediate-container
  .intermediate-sidemenu:hover
  .meeting-list
  .meeting-item
  .meeting-details
  .meeting-time,
  .intermediate-container
  .sidemenu-container-open 
  .meeting-list
  .meeting-item
  .meeting-details
  .meeting-time {
  font-size: 14px;
  color: #8d8f9c;
  margin: 0;
}

/* Join button styling */
.intermediate-container
  .intermediate-sidemenu:hover
  .meeting-list
  .meeting-item
  .meeting-details
  .join-btn ,

  .intermediate-container
  .sidemenu-container-open 
  .meeting-list
  .meeting-item
  .meeting-details
  .join-btn {
  background-color: #6a29ff;
  min-width: 100px;
  height: 40px;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 26px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  right: 0px;
  font-weight: 700;
}

.intermediate-container
  .intermediate-sidemenu:hover
  .meeting-list
  .meeting-item
  .meeting-details
  .host-options-btn,
  .intermediate-container
  .sidemenu-container-open 
  .meeting-list
  .meeting-item
  .meeting-details
  .host-options-btn {
  background-color: #32353c;
  width: 40px;
  height: 40px;
  /* padding: 2.92px; */
  color: #ffffff;
  border: none;
  /* padding: 8px 12px; */
  border-radius: 50%;
  /* font-size: 14px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  right: 0px;
  font-weight: 700;
}

.intermediate-container
  .intermediate-sidemenu:hover
  .meeting-list
  .meeting-item
  .meeting-details
  .ant-popconfirm
  .ant-popconfirm-message,
  .intermediate-container
  .sidemenu-container-open 
  .meeting-list
  .meeting-item
  .meeting-details
  .ant-popconfirm
  .ant-popconfirm-message {
  display: none;
}

/* .join-btn:hover {
  background-color: #5020cc;
} */

/* Meetings list styling */
/* .meetings-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
} */

/* .meeting-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 10px;
} */

/* .meeting-list .intermediate-new-meeting-btn {

  border: none;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
} */

.stream-icon{
  margin: 0;
  
}
.meeting-name{
  color: white;
  display: none;
  font-weight:bold;
}

/* disable Schdeule/ New MEeting while Meeting list loads */

.meeting-options-wrapper.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.search-icon-style {
  color: white;
  font-size: 32px;
  margin: 16px;
  font-weight: bold;
}

.intermediate-container .intermediate-sidemenu:hover .search-icon-style,
.intermediate-container .sidemenu-container-open .search-icon-style{
  display: none;
}

.intermediate-container .intermediate-sidemenu:hover .zoom-meeting-search .zoom-meeting-search-box,
.intermediate-container .sidemenu-container-open .zoom-meeting-search .zoom-meeting-search-box {
  display: block !important;
  margin-bottom: 18px;
  margin-left: 6px;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding: 5px;
}

.zoom-meeting-search .zoom-meeting-search-box {
  display: none;
}

/* css for text when there are no meetings (text) */


.meeting-list.no-meetings {
  color: white; 
  display: block; 
  font-weight: 700; 
  padding: 10px 0; 
  text-align: center;
  font-size: 16px;
}

.meeting-list.no-meetings p {
  color: rgba(141,143,156,1);
  font-weight: 400;
  font-size: 14px;
}

.meeting-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}


/* added to hide the plus icon and new meeting button while loader loads */
.invisible {
  visibility: hidden;
  opacity: 0;
  pointer-events: none
}