/* Invite Admin */
.inviteAdminButtons {
  margin-left: 6%;
}
.inviteShowadminButton {
  display: inline-block;
}
.inviteTeamadminButton {
  float: right;
}
.width_240 {
    width: 240px;
}
.showlist-heading{
  width: 95%;
}
.csv-button{
  justify-content: right;
  background: transparent url("../../Images/csv-export.png") 0% 0% no-repeat
    padding-box;
  opacity: 1;
  background-size: cover;
  width: 50px;
  height: 50px;
  z-index: 1;
  background-position: center;
  cursor: pointer;
  margin-top: -8px;
}
@media only screen and (max-width: 430px){
.showlist-heading {
    width: 87%;
}
}
@media only screen and (max-width: 768px) {
  .showlist-heading{
    width: 90%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 766px) {
  .showlist-heading{
    width: 93%;
  }
}
@media only screen and (min-width: 1445px){
  .showlist-heading{
    width: 97%;
  }
}


.heading-text-right {
  display: flex;
}
.superadmin-googleauth{
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.googleAuth-checkbox{
  width: 15px;
  height: 15px;
  cursor: pointer;
  font-family: 'IBMPlexSans-Regular';
}
.googleAuth-label{
  margin-left: 5px;
  font-family: 'IBMPlexSans-Bold';
}
.superadmin-license-info {
  color: #6435c9;
  cursor: pointer;
}
tr.header-cell-without-border:hover .superadmin-license-info {
  color: #fff;
  cursor: pointer;
}
.radio-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 150px;
  gap: 40px;
}
.radio-label {
  display: flex;
  align-items: center;
}
.radio-text {
  margin-left: 8px; 
  font-family: 'IBMPlexSans-Regular';
}