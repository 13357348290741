.zoom-viewer {
  background: #000000 url('./Images/video_bg_2.jpg') 0% 0% no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.zoom-viewer .ant-layout-header {
  padding-inline: 0;
  height: 80px;
  background: #000;
  line-height: 20px;
  display: flex;
}
.zoom-viewer .menu .dnd-icon , .zoom-viewer .menu .dominant-speaker-icon{
  font-size:9px;
}
.zoom-viewer #cameraDropdown > div.visible.menu.transition {
  top: 100% !important;
  left: 0px;
}
.zoom-viewer .chat-typing-indicator {
  padding-left: 5px;
  padding-bottom: 10px;
  font-size: 10px;
}
/* .zoom-viewer .ant-layout-sider .ant-layout-sider-dark .viewer-side-panel .ant-layout-sider-children  {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100vh - 80px);
  z-index: 99;
  background: #000;
} */

/* .zoom-viewer .ant-layout-sider .viewer-side-panel .ant-layout-sider-children .guest-panel-wrapper{
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100vh - 80px);
  background: #000;
} */

.zoom-viewer .ant-layout-sider {
  position: absolute;
}
.zoom-viewer .viewer-side-panel {
  text-align: left;
  line-height: 100%;
  color: #fff;
  background-color: #1d1d1d;
  min-width: 360px !important;
  position: relative;
}

.zoom-viewer .ant-spin-nested-loading .ant-spin-blur::after {
  opacity: 1;
  z-index: 100000;
  background-color: #ffffff;
}

.zoom-viewer .show-viewer-leftmenu {
  position: fixed;
  width: 90px;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: black;
  transition: width 0.2s ease-out;
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
  gap: 24px;
  z-index: 100;
}

.zoom-viewer .show-viewer-leftmenu.video-tray-collapse {
  height: 100vh;
}
.zoom-viewer .show-viewer-leftmenu-guestViewer {
  position: fixed;
  width: 90px;
  top: 0px;
  left: 0px;
  height: calc(100vh - 200px);
  background-color: black;
  transition: width 0.2s ease-out;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  gap: 24px;
  z-index: 100;
}

.zoom-viewer .show-viewer-leftmenu-guestViewer.video-tray-collapse {
  height: 100vh;
}
.zoom-viewer .full-height-leftmenu {
  height: 100vh;
}

.zoom-viewer .show-viewer-leftmenu.inverse-layout {
  height: 100vh;
  z-index: 100;
}
.zoom-viewer .show-viewer-leftmenu-guestViewer.inverse-layout {
  height: 100vh;
  z-index: 100;
}
.zoom-viewer .sidemenu-container-open,
.zoom-viewer .show-viewer-leftmenu:hover {
  width: 360px;
  height: 100vh;
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.zoom-viewer .show-viewer-leftmenu-guestViewer:hover {
  background-color: black;
}
.zoom-viewer .sidemenu-item {
  display: flex;
  gap: 12px;
  align-items: center;
  height: 42px;
  padding-left: 24px;
  padding-right: 12px;
  cursor: pointer;
  flex-shrink: 0;
}

.zoom-viewer .sidemenu-item-border:hover {
  border-left: 2px solid white;
}

.zoom-viewer .sidemenu-hide-collapsed {
  font-size: 16px;
  color: white;
  white-space: nowrap;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.2s ease-out;
}

.zoom-viewer .sidemenu-hide-collapsed.sidemenu-stream-name {
  pointer-events: none;
}

.zoom-viewer .sidemenu-container-open .sidemenu-hide-collapsed,
.zoom-viewer .show-viewer-leftmenu:hover .sidemenu-hide-collapsed {
  display: flex;
  transform: translateX(0%);
  opacity: 1;
}

.zoom-viewer .sidemenu-title {
  font-weight: 700;
}

.zoom-viewer .sidemenu-subtitle {
  font-weight: 400;
}

.zoom-viewer .sidemenu-item-icon {
  height: 42px;
}

.zoom-viewer .sidemenu-separator {
  height: 1px;
  background-color: #282829;
  width: calc(100% - 32px);
  margin: 0px 16px;
  box-sizing: border-box;
}

.zoom-viewer .sidemenu-spaced-header {
  letter-spacing: 2px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  padding-left: 16px;
}

.zoom-viewer .streams-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
}

.zoom-viewer .streams-container-disabled {
  pointer-events: none;
}

.zoom-viewer .stream-button {
  width: 42px;
  height: 42px;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  min-width: 42px;
  min-height: 42px;
  background-color: #1d1d1d;
}

.zoom-viewer .sidemenu-item:hover .stream-button {
  background-color: #282829;
}

.zoom-viewer .sidemenu-item:hover .stream-button img {
  filter: brightness(0.7);
}

.zoom-viewer .stream-selected-active .stream-button {
  background-color: #6a29ff !important;
}

.zoom-viewer .stream-selected-active .stream-button img {
  filter: brightness(1) !important;
}

.zoom-viewer .stream-selected-inactive .stream-button img {
  filter: brightness(0.5);
}

.zoom-viewer .sidemenu-stream-name {
  font-size: 16px;
  font-weight: 700;
}

.zoom-viewer .stream-selected-inactive .sidemenu-stream-name {
  color: #ccc;
}

.zoom-viewer nav li, .zoom-viewer nav ul {
  margin: 0;
    outline: 0;
    padding: 0;
}


.zoom-viewer .topbar {
  background-color: #000;
  display: flex;
  height: 80px;
  justify-content: space-between;
  width: calc(100vw - 90px);
  z-index: 100;
  align-items: center;
  padding: 0;
  padding-inline: 0;
  margin-left: 90px;
}

.zoom-viewer .topbar .centerMenuTop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-basis: 100%;
  justify-content: center;
  padding: 12px;
  gap: 12px;
  height: 100%;
}

.zoom-viewer .centerMenuTop .top-menu-button-item {
  min-width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  transition: 0.4s;
  background-color: #141414;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
}
.zoom-viewer .noise-cancellation:hover {
  height: inherit;
  width: 100%;
  border: 2px solid #4c7947;
  border-bottom: 3px solid #4c7947;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zoom-viewer .noise-cancellation-on {
  height: inherit;
  width: 100%;
  /* border: 2px solid #4c7947;
  border-bottom: 3px solid #4c7947; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  background-color: #4c7947;
}
.zoom-viewer .centerMenuTop .top-menu-button-item.noise-cancellation-btn {
  border: 2px solid transparent;
  border-bottom: 3px solid transparent;
}

.zoom-viewer .centerMenuTop
  .top-menu-button-item.noise-cancellation-btn.noise-cancellation-on {
  border: 2px solid #4c7947;
  border-bottom: 3px solid #4c7947;
}

.zoom-viewer .call-options-dropdown > div > i {
  display: none !important;
}

.zoom-viewer .call-options-dropdown > div {
  position: unset !important;
}

.zoom-viewer .pencil-tool-dropdown > div > i {
  display: none !important;
}

.zoom-viewer .pencil-tool-dropdown > div {
  position: unset !important;
}

.zoom-viewer .audiovisual-source-dropdown > div > i {
  display: none !important;
}

.zoom-viewer .audiovisual-source-dropdown > div {
  position: unset !important;
}

.zoom-viewer .centerMenuTop .top-menu-button-item > span {
  display: none;
}

.zoom-viewer .centerMenuTop .top-menu-button-item:hover {
  background-color: #454248;
}

.zoom-viewer .centerMenuTop .top-menu-button-item:hover img {
  filter: brightness(0) invert(1);
}

.zoom-viewer .centerMenuTop .top-menu-button-item:hover > span {
  height: 24px;
  background: #000;
  position: absolute;
  display: initial;
  bottom: -100%;
  font-size: 14px;
  color: #fff;
  padding: 0 5px;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  z-index: 10;
}

.zoom-viewer .centerMenuTop .top-menu-button-item:hover span {
  bottom: -35px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
}

.zoom-viewer .centerMenuTop .top-menu-button-item:hover .volumeSpan {
  margin-left: 70px;
}

.zoom-viewer .volume-control {
  position: absolute;
  background: none;
  z-index: 1900;
  background: #141414;
  top: calc(100% + 1px);
  display: flex;
  width: 6px !important;
  min-width: unset;
  height: calc(122px + 8px);
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  margin: unset;
}

.zoom-viewer input[type="range"] {
  background: linear-gradient(
    to right,
    #e5e5e5 0%,
    #e5e5e5 100%,
    #282829 0%,
    #282829 0%
  );
  /* border: solid 1px #003860; */
  box-sizing: border-box;
  border-radius: 8px;
  height: 6px;
  width: 122px;
  outline: none;
  -webkit-appearance: none;
  transform: rotate(270deg);
}

.zoom-viewer .centerMenuTop .top-menu-button-item span::before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid black;
  content: "";
  top: -7px;
  position: absolute;
  left: 50%;
  margin-left: -5px;
}

.zoom-viewer .centerMenuTop .top-menu-button-item a {
  height: 28px;
  position: absolute;
  padding: 0 25px;
  color: #fff;
}

.zoom-viewer .centerMenuTop .top-menu-button-item a:hover {
  color: #b2b2b2;
}

.zoom-viewer .menuTopRight {
  width: max-content;
  display: flex;
  flex-shrink: 0;
}

.zoom-viewer .menuTopRight ul {
  list-style-type: none;
  padding: 0px;
  margin: 0 auto;
}

.zoom-viewer .menuTopRight ul li {
  float: left;
  width: 60px;
}

.zoom-viewer .menuTopRight ul li a {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-bottom: 3px solid transparent;
  position: relative;
  margin-top: 3px solid transparent;
  cursor: pointer;
}

.zoom-viewer .menuTopRight ul li a:hover,
.zoom-viewer .menuTopRight ul li a.active {
  background-color: #454248;
  border-color: #6600ff;
}

.zoom-viewer .menuTopRight ul li a.disabled {
  pointer-events: none;
}



.zoom-viewer .video-tray-collapse-bg {
  width: 100%;
  background-color: #000;
  height: 200px;
  position: absolute;
  bottom: 0;
}

.zoom-viewer .video-tray-collapse-bg.inverse-layout {
  position: absolute;
  top: 0;
}

.zoom-viewer .zoom-video-tray{
  height: 200px;
}


.zoom-viewer .viewer-column {
  display: flex;
  height: calc(100vh - 100px);
  width: 100%;
  background: none !important;
}

.zoom-viewer .viewer-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);
  position: relative;
}
.zoom-viewer .viewer-content.inverse-layout {
  flex-direction: column-reverse;
}
.zoom-viewer .empty-video-tiles-placeholder {}

.zoom-viewer .content-row {
  display: flex;
  flex-direction: row;
  z-index: 99;
}
.zoom-viewer .chat-h1{
  align-items: center;
  background: #1d1d1d;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.zoom-viewer .chat-options-wrapper{
  align-items: center;
  display: flex;
  gap: 12px;
  padding: 16px;
}
 
.zoom-viewer .chat-wrapper {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: calc(100vh - 80px);
}

.zoom-viewer .clear-chat-button{
  cursor: pointer;
  opacity: 1;
  position: relative;
  transition: .3s;
}

.zoom-viewer .chat-msg-input {
  align-items: center;
  background-color: #000;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 2px;
  font-size: 16px;
}
.zoom-viewer .chat-msg-input input[type=text] {
  background-color: #282829 !important;
  border: none !important;
  border-radius: 0;
  color: #fff !important;
  padding: 12px 0;
}

.zoom-viewer .emoji-img-wrapper {
  align-items: center;
  background-color: #282829;
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.zoom-viewer .chat-message-body {
  background-color: #282829;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  max-width: 100%;
  min-width: 65%;
  padding: 16px 32px 16px 16px;
  position: relative;
  transition: all .2s ease-out;
  width: -webkit-max-content;
  width: max-content;
}
.zoom-viewer .editTxtArea {
  background: #454248;
  border: none;
  border-radius: 10px;
  border-top-left-radius: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  padding: 7px;
  resize: none;
  width: 250px;
}
.zoom-viewer .chat-message-body .duplicate-icon {
  background: transparent url("../../Images/copy_white.svg") no-repeat 50%;
  height: 20px;
  margin: 0;
  width: 20px;
}
.zoom-viewer .file-message-grey-bg .ui.card {
  background-color: #141414;
  border: 0 solid #000;
  border-radius: 8px;
  box-shadow: none;
  text-overflow: ellipsis;
}
.zoom-viewer .annote-chat-message-grey-bg {
  min-width: 250px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.zoom-viewer .download-file-icon, .zoom-viewer .emoji-img {
  cursor: pointer;
}
.zoom-viewer .chat-message-body .edit-message-icon {
    background: transparent url("../../Images/edit_message.svg") no-repeat 50%;
    height: 20px;
    margin: 0;
    width: 20px;
}
.zoom-viewer .float-right {
  float: right;
}
.zoom-viewer .cancelTick-img, .zoom-viewer .greenTick-img {
  height: 27px;
  width: 27px;
}
.zoom-viewer .stream-label-helper-text {
  font-size: 10px;
  display: flex;
  color: #fff;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}
.zoom-viewer .stream-label-edit-box {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  margin-top: 3px;
  flex: 1;
  margin-right: 4px;
}
.zoom-viewer .stream-label-edit-parent {
  display: flex;
  gap: 2px;
}
.zoom-viewer .stream-label-error-text {
  color: red;
  font-size: 10px;
  display: flex;
  font-weight: bold;
}
.zoom-viewer .stream-label-submit {
  background: none;
  width: 27px;
  height: 27px;
  border: none;
  margin: 0;
  padding: 0;
}

.zoom-viewer .stream-label-submit:disabled {
  opacity: 0.2;
}
.zoom-viewer .chat-message-body:hover {
  margin-bottom: 16px;
}
.zoom-viewer .chat-heading {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 10px;
}
.zoom-viewer .file-message-grey-bg .file-name {
  color: #8d8f9c;
}
.zoom-viewer .chat-timestamp-and-message-wrapper {
  position: relative;
  z-index: 3;
}
.zoom-viewer .chat-message-hover-tools-row {
  align-items: center;
  background-color: #141414;
  border-radius: 4px;
  display: none;
  gap: 4px;
  height: -webkit-max-content;
  height: max-content;
  padding: 4px;
  position: absolute;
  right: 4px;
  top: 4px;
  width: -webkit-max-content;
  width: max-content;
}
.zoom-viewer .chat-image {
  background: transparent url("./Images/logo.png") 0% 0% no-repeat
  padding-box;
  background-position: cover !important;
  background-size: cover !important;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-table;
  height: 42px;
  min-width: 42px;
  width: 42px;
}
.zoom-viewer .chat-local-message-body {
  justify-content: flex-end;

}
.zoom-viewer .chat-local-message-body .chat-message-body{
  
  margin-right: 12px;
  margin-left: 0;
}
.zoom-viewer .chat-message-body:hover .chat-message-hover-tools-row {
  display: flex;
}
.zoom-viewer .chat-message-tool-icon-container:hover {
  -webkit-filter: brightness(1);
  filter: brightness(1);
}
.zoom-viewer .upload-btn {
  align-items: center;
  background-color: #282829;
  border-radius: 4px 0 0 4px;
  display: flex;
  justify-content: center;
  padding: 12px;
}

.zoom-viewer .chat-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-bottom: 16px;
  width: 100%;
}
.zoom-viewer .chat-time {
  bottom: -19px;
  font-size: 10px;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: all .2s ease-out;
  z-index: 0;
}
.zoom-viewer .chat-notification {
  color: #4a4b51;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}
.zoom-viewer .chat-message-body:hover .chat-time {
  opacity: 1;
}
.zoom-viewer .chat-list-item:first-child {
  margin-top: auto !important;
}

.zoom-viewer .chat-local-message {
  justify-content: flex-end;
}
.zoom-viewer .chat-list-item {
  display: flex;
  padding: 8px 16px;
}
.zoom-viewer .chat-message-white-bg{
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}
.zoom-viewer .ui.small.input{
  width:100%;
  height:100%;
}

.zoom-viewer .guest-panel-wrapper,  .zoom-viewer .encoder-panel-wrapper{
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100vh - 80px);
  background-color: #000000;
  align-items: flex-end;
}

.zoom-viewer .main-menu-right-chat {
  background: #141414;
  color: white;
  display: flex;
  flex-direction: column;
  -webkit-transition: width 0.05s linear;
  transition: all 0.5s;
  height: 100%;
  width: 100%;
  min-width: 360px;
  position: relative;
  /* z-index: 100; */
  overflow-y: auto;
  overflow-x: hidden;
}

.zoom-viewer .main-menu-right-chat::-webkit-scrollbar {
  height: 12px !important;
  width: 12px !important;
  background: #000 !important;
}

.zoom-viewer .main-menu-right-chat::-webkit-scrollbar-thumb {
  background: #1b1b1b !important;
  -webkit-border-radius: 1ex !important;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75) !important;
}

.zoom-viewer .main-menu-right-chat::-webkit-scrollbar-corner {
  background: #000 !important;
}

.zoom-viewer #guest-invite-tray .guest-invite-content {
  min-height: calc(100vh - 293px);
}

.zoom-viewer .guest-users-list-title {
  font-weight: 700;
}

.zoom-viewer #guest-invite-tray .chat-h1 {
  display: flex;
}

.zoom-viewer .guest-viewer-url-container-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.zoom-viewer .guest-viewer-url-container {
  height: 60px;
  border-bottom: 1px solid #212222;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items:center; */
  padding: 0px 20px 0px 20px;
}
.zoom-viewer .guest-viewer-url-container-hidden {
  height: 60px;
  border-bottom: 1px solid #212222;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items:center; */
  padding: 0px 20px 0px 20px;
  visibility: hidden;
}

.zoom-viewer .copy-icon {
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #ae2aff;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.zoom-viewer .url-holder {
  border: none;
  background-color: #141414;
  color: #fff;
  cursor: auto;
}
.zoom-viewer .url-holder-expired {
  border: none;
  background-color: #141414;
  color: #fff;
  cursor: auto;
  text-decoration: line-through;
}

.zoom-viewer .passcode-holder {
  border: none;
  background-color: #141414;
  color: #fff;
  cursor: auto;
  font-weight: 700;
}

.zoom-viewer .passcode-holder-expired {
  border: none;
  background-color: #141414;
  color: #fff;
  cursor: auto;
  font-weight: 700;
  text-decoration: line-through;
}

.zoom-viewer .guest-invite-modal-trigger-btn {
  color: #ffffff;
  background-color: #1d1d1d;
  border: none;
  font-size: 25px;
  cursor: pointer;
}
.zoom-viewer .guest-invite-modal-trigger-btn-disabled {
  color: #333;
  background-color: #1d1d1d;
  border: none;
  font-size: 25px;
  cursor: not-allowed;
}

.guest-invite-notification-modal .ant-modal-content{
  padding: 0% 0% 0% 0%;
  width:450px;
}

.guest-invite-notification-modal .ant-modal-footer{
  padding: 0% 0% 0% 0%;
}

.newguest-invite-modal {
  height: 200px !important;
  background-color: #1b1b1b;
  color: #707070;
  width: 450px;
}
.newguest-invite-modal .guest-content {
  height: 140px;
  padding: 20px 25px;
  line-height: 26px;
  letter-spacing: 1.4px;
  font-size: 16px;
  width: 100%;
}

.newguest-invite-modal .guest-action-buttons {
  float: right;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  gap:8px;
}
.newguest-invite-modal .guest-button:hover {
  cursor: pointer;
  background-color: #6a29ff;
  color: #f4f4f4;
}
.newguest-invite-modal .guest-button {
  background: #e0e1e2 none;
  font-weight:700;
  border: none;
  border-radius: 0.28571429rem;
  color: rgba(0,0,0,.6);
  cursor: pointer;
  display: inline-block;
  height: 36px;
  width:86px;
}

.zoom-viewer .allowGuestToggleOn {
  background-image: url("../../Images/toggleOn.svg") !important;
  background-repeat: no-repeat;
  /* position: absolute; */
  z-index: 1001;
  height: 30px;
  width: 45px;
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoom-viewer .allowGuestToggleOff {
  background-image: url("../../Images/toggleOff.svg") !important;
  background-repeat: no-repeat;
  /* position: absolute; */
  z-index: 1001;
  height: 30px;
  width: 45px;
  margin-top: 8px;
  border-left: 5px solid #141414;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom-viewer .guest-user-remove-btn-wrapper {
  display: flex;
  align-items: center;
}

.zoom-viewer .guest-user-status-circle {
  padding-top: 8px;
  border-right: 4px solid #141414;
}

.zoom-viewer .guest-user-list-remove-btn {
  color: #ffffff;
  background-color: #6f2d88;
  font-weight: 600;
  height: 25px;
  width: 100px;
  border: none;
  cursor: pointer;
}

.zoom-viewer .guest-user-status-waiting {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: #f39200;
  display: inline-block;
}

.zoom-viewer .guest-url-status-active {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: #41ff00;
  display: inline-block;
}

.zoom-viewer .guest-url-status-expired {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: red;
  display: inline-block;
}

.zoom-viewer .guest-invite-content .delete_image {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.zoom-viewer .main-menu-right-chat-users {
  background: black;
  position: absolute;
  top: 56px;
  /* bottom: 47px; */
  right: 0;
  margin-right: 0px;
  color: white;
  float: right;
  width: 344px;
  /* overflow: scroll; */
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
  padding-left: 20px;
}

.zoom-viewer .publisher-panel-content {
  margin-top: 16px;
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  padding-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.zoom-viewer.chat-h1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1d1d;
}

.zoom-viewer .chat-text {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding: 20px;
  letter-spacing: 2px;
}

.zoom-viewer #guest-invite-tray .guest-invite-header .chat-text {
  flex-grow: 1;
  margin: unset;
  position: unset;
  display: flex;
  justify-content: center;
}

.zoom-viewer #guest-invite-tray .guest-invite-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 10px;
}

.zoom-viewer .publisher-panel-content .ui.form .field > label {
  font-size: 1rem;
  font-weight: 700;
  text-transform: none;
  color: white;
  margin: 10px;
}

.zoom-viewer .publisher-panel-item {
  padding: 10px 20px;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #212222;
}

.zoom-viewer .publisher-panel-image {
  width: 20%;
}

.zoom-viewer .publisher-panel-user {
  width: 40%;
  color: white;
}

.zoom-viewer .publisher-panel-action {
  width: 20%;
  display: flex;
  justify-content: center;
}

.zoom-viewer .publisher-panel-action-button {
  background: gray;
  border-radius: 5px;
  padding: 5px;
  border: none;
}

.zoom-viewer .publisher-panel-status {
  width: 20%;
  display: flex;
  justify-content: center;
}

.zoom-viewer .publisher-panel-status-circle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: #fff;
  display: inline-block;
}

.zoom-viewer .publisher-actions {
  display: flex;
  justify-content: center;
}

.zoom-viewer .publisher-panel-search .ui.search {
  width: 100%;
}

.zoom-viewer .publisher-panel-search .ui.search input {
  background-color: transparent !important;
  padding: 0px;
  outline: none !important;
  padding-left: 12px;
  width: 100%;
  color: #8d8f9c !important;
  border: none !important;
  outline: none !important;
}

.zoom-viewer .publisher-panel-search {
  min-width: 280px;
  padding: 8px 12px;
  margin: 12px;
  border-radius: 48px;
  background-color: #282829;
  display: flex;
}

.zoom-viewer .publisher-panel-search:hover {
  background-color: #2a2a2b;
}

.zoom-viewer .tabs-wrapper {
  display: flex;
}

.zoom-viewer .publisher-panel-tab-button {
  border: none;
  flex-basis: 100%;
  background: none;
  color: #ffffff44;
  min-width: 100px;
  line-height: 40px;
  outline: none;
  cursor: pointer;
}

.zoom-viewer .publisher-panel-tab-button.active {
  border-bottom: 2px solid #8a2be2;
  color: white;
}

.publisher-panel-content .ui.form .inline.fields .field > label {
  font-weight: 700;
  text-transform: none;
  color: #000;
  background: white;
  height: 45px;
  width: 100%;
  position: absolute;
  border: 5px solid #000;
  padding: 8px;
  font-size: 16px;
}

.pulsating-icon {
  width: 30px;
  height: 30px;
  margin-top: 12px;
  background-image: url("../../Images/guest_whitebg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: pulsate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes pulsate {
  0% {
    background-image: url("../../Images/guest_whitebg.svg");
    transform: scale(1);
  }
  50% {
    background-image: url("../../Images/guestRed.svg");
    transform: scale(1.2);
  }
  100% {
    background-image: url("../../Images/guest_whitebg.svg");
    transform: scale(1);
  }
}

.full-screen-player{
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
}

/* watermark css */
.show-watermark-ui {
  height: auto;
  min-height: 50px;
  /* width: 100%; */
  position: absolute;
  z-index: 1;
  color: #ffffff;
}
.watermark-tab-button {
  border: none;
  flex-basis: 100%;
  background: none;
  color: #f4f4f4;
  min-width: 100px;
  line-height: 40px;
  outline: none;
  cursor: pointer;
}

.watermark-tab-button.active {
  background: #8a2be2;
  color: #f4f4f4;
}

.watermark-panel-user {
  width: 70%;
  color: white;
}

.watermark-toggle-button {
  width: 25%;
  display: flex;
  justify-content: center;
}

.allowToggleOn {
  background-image: url("../../Images/toggleOn.svg") !important;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1001;
  height: 30px;
  width: 45px;
  cursor: pointer;
  margin-top: -10px !important;
}
.allowToggleOff {
  background-image: url("../../Images/toggleOff.svg") !important;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1001;
  height: 30px;
  width: 45px;
  cursor: pointer;
  margin-top: -10px !important;
}
.margin10 {
  margin-top: 10px !important;
}
.watermark-panel-checkbox {
  padding: 5px;
  display: flex;
  align-items: center;
}

.watermark-panel-lable {
  width: 70%;
  color: white;
  margin-left: 10px;
}

.watermarkInput-field {
  width: 100%;
  height: 45px;
  padding: 10px;
  border-radius: 10px solid #0c0c0c;
}

.watermark-input-left {
  width: 49%;
  color: white;
  margin: 10px;
  float: left;
}

.watermark-input-right {
  width: 49%;
  color: white;
  margin: 10px;
  float: right;
}

.publisher-panel-content .ui.form .field > label {
  font-size: 1rem;
  font-weight: 700;
  text-transform: none;
  color: white;
  margin: 10px;
}

.publisher-panel-content .ui.form .inline.fields .field > label {
  font-weight: 700;
  text-transform: none;
  color: #000;
  background: white;
  height: 45px;
  width: 100%;
  position: absolute;
  border: 5px solid #000;
  padding: 8px;
  font-size: 16px;
}

.color-picker {
  position: absolute;
  margin: 5px 12px;
  border: none;
  width: 25px;
}

.color-hex-value {
  width: 170px !important;
}

.opacity-range {
  position: absolute;
  margin-top: 20px !important;
  left: 100px;
  border: 1px solid #8a2be2 !important;
  width: 185px !important;
  transform: rotate(360deg) !important;
}

.watermark-action-button {
  padding: 8px 12px;
  background-color: #282829 !important;
  border-radius: 8px;
  outline: none !important;
  border: 0px solid black !important;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.watermark-action-button:hover {
  background-color: #9f5fff !important;
}
.opacity-percentage {
  color: #000;
  position: absolute;
  right: 10px;
  top: 117px;
}
.location-label {
  margin: 40px 10px 10px !important;
}
.watermark-panel-checkbox .ui.checkbox label,
.ui.checkbox + label {
  color: #fff !important;
}
.ui.selection.dropdown.watermark-dropdown {
  padding: 8px !important;
  border-radius: 5px !important;
  min-height: 38px !important;
}
.watermark-dropdown.ui.selection.dropdown > .dropdown.icon {
  padding: 10px !important;
}
.watermark-dropdown.ui.search.dropdown > .text {
  font-size: 14px;
}
.zoom-viewer .watermark-actions {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
}
.zoom-viewer .watermark-actions {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
}

.zoom-viewer .action-clear-changes {
  padding: 8px 12px;
  background-color: #282829;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.zoom-viewer .action-save-changes {
  padding: 8px 12px;
  background-color: #9f5fff;
  border-radius: 8px;
  cursor: pointer;
}

.zoom-viewer .action-save-changes > button {
  padding: 8px 12px;
  border-radius: 0px;
  background-color: transparent !important;
  outline: none !important;
  border: 0px solid black !important;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.zoom-viewer .action-clear-changes > button {
  padding: 8px 12px;
  border-radius: 0px;
  background-color: transparent !important;
  outline: none !important;
  border: 0px solid black !important;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.zoom-viewer .action-clear-changes:hover {
  background-color: #9f5fff !important;
}
.zoom-viewer .publisher-panel-wrapper {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: calc(100vh - 80px);
}

.miniheader-modal .ant-modal-content {
  padding: 2px;
  border:2px solid #141414;
  border-radius: 8px;
  background-color: black; /* Added background color */
}

.miniheader-btn {
  width: 56px;
  height: 100%;
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  transition: 0.4s;
  background-color: #141414;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
}

.miniheader-btn-right {
  padding: 5px;
}

.miniheader-modal .ant-modal-footer{
  padding: 0% 0% 0% 0%;
}

.miniheader-modal-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:5px
}
.miniheader-modal-row{
  display: flex;
  flex-direction: row;
  gap:5px;
  justify-content: center;
}


/* MEDIA QUERY FOR PIXEL PHONES */
@media only screen and (max-width: 600px) {
  .zoom-viewer .chat-msg-input {
    font-size: 10px;
  }
  .show-new-viewer .main-menu-right-chat,
  .zoom-viewer .main-menu-right-chat{
    /* height: 60%; */
    width: 70%;
    position: fixed;
    right: 0;
    margin-right: 10px;
    min-width: 0px;
  }
  .show-new-viewer .chat-msg-input{
    font-size: 10px;
  }
  .show-new-viewer .chat-wrapper,
  .show-new-viewer .encoder-panel-wrapper,
  .show-new-viewer .avid-locators-panel-wrapper,
  .show-new-viewer .publisher-panel-wrapper 
  .show-new-viewer .guest-panel-wrapper {
    background-color: #000;
  }
  .zoom-viewer .publisher-panel-content {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
}

@media only screen 
and (max-device-width: 1000px) 
and (max-device-height: 1000px) 
and (orientation: landscape)
 {
 .zoom-viewer .show-viewer-leftmenu {
    position: fixed;
    width: 90px;
    top: 0px;
    left: 0px;
    height: 170px;
    background-color: black;
    transition: width 0.2s ease-out;
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 0;
    gap: 24px;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (not (max-width: 767px)) {

  .zoom-viewer .main-menu-right-chat  {
    height: 60%;
    width: 92%;
    min-width: 320px;
  }
  .zoom-viewer .chat-wrapper {
    background-color: black;
    align-items: flex-end;
  }
  .zoom-viewer .chat-typing-indicator {
    padding-left: 5px;
    padding-bottom: 5px;
    font-size: 10px;
  }
  .zoom-viewer .publisher-panel-wrapper {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: calc(100vh - 80px);
    align-items: flex-end;
    background: #000;
  }
  .zoom-viewer .guest-panel-wrapper,  .zoom-viewer .encoder-panel-wrapper{
    width: 320px;
    background-color: #000000;
    align-items: flex-end;
  }
  
}  
