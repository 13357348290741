.typography-h4 {
  font-family: "NunitoSans-Bold";
  font-size: 1.1 rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.15em;
  text-align: left;
}

.typography-h3 {
  font-family: "NunitoSans-Bold";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  letter-spacing: 0em;
  text-align: left;
}
.bold-text{
  color: #000;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 1px;
}
.mlv-grid-container {
  position: relative;
  display: table;
  width: 100%;
  /* height: 840px; */
  grid-template-columns: repeat(24, 1fr);
  gap: 2rem;
  overflow-y: visible;
}

.mlv-heading {
  margin: 2rem 2.5rem 0 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mlv-left-nav {
  grid-column: 1 / 8;
  background-color: #FFFFFF;
}

.mlv-right-container {
  grid-column: 8 / 25;
  background-color: #F7F8FE;
  height:815px;
}

.mlv-pub-license-container {
  margin: 2rem 0 4rem 0;
  display: flex;
  /* height: 100%; */
  width: 100%;
  justify-content: space-evenly;
}

.mlv-pub-license-content,.mlv-viewer-license-content {
  margin-top: 0.4rem;
  background-color: #FFFFFF;
  border-radius: 4px;
  height: auto;
  max-height: 500px;
  font-family: "NunitoSans-Bold";
}
.mlv-viewer-license-content{
  height: auto;
  max-height: 200px;
  overflow-y: auto;
}

.ml-license-lists{
  height: 375px;
  overflow-y: auto;
  float: left;
  width: 100%;
  background: #FFFFFF;
}
.license-msg-content {
  height: auto;
  max-height: 200px;
}
.mvl-0-margin{
  margin:0px !important;
}
.mlv-pub-license-main {
  width: calc(60% - 4rem);
}

.mlv-pub-history-main {
  width: calc(40% - 4rem);
}
.mlv-pub-history-content {
  margin-top: 0.6rem;
  background-color: #FFFFFF;
  border-radius: 4px;
  height: auto;
  max-height: 500px;
  font-family: "NunitoSans-Regular";
  overflow-y: auto;
}

.container.v2-container {
  box-sizing: border-box;
}

.container.v2-container *, .container.v2-container :before, .container.v2-container :after {
  box-sizing: inherit;
}

.mlv-card {
  display: flex;
  padding: 1.4rem 0;
  margin: 0 1.5rem !important;
  justify-content: space-between;
  border-bottom: 1px solid #EFEFEF;
  align-items: center;
}

.mlv-card-left > div {
  padding-right: 1rem;
}

.mlv-card-left > div:nth-child(1) {
  font-weight: 600;
  font-size: 16px;
}

.mlv-card-left > div:nth-child(2) {
  font-weight: normal;
  color: #8D8F9C;
  font-size: 14px;
}

.mlv-card i.close {
  font-size: 1.3em;
}

.mlv-viewer {
  display: flex;
  padding: 1.4rem 0;
  justify-content: space-between;
  border-bottom: 1px solid #EFEFEF;
  align-items: center;
}

.mlv-card-viewer{
  font-weight: 400;
  font-size: 14px;
  color: #8D8F9C;
}
.mlv-req-license-button {
  background-color: #6A29FF !important;
  color: #FFFFFF !important;
  border-radius: 8px !important;
  width: 220px !important;
  height: 45px !important;
  padding: 0 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
}

/* Licence manager */
.licence-modal{
  width: 365px !important;
  padding: 10px!important;
}
.licence-header{
  font-size:14px !important;
  border-bottom: none !important;
  font-family: "NunitoSans-Bold";
  letter-spacing: 0.15em;
  min-height: 40px;
}
.licence-content{
  font-size:16px !important;
  font-weight: 700 !important;
  font-family: "NunitoSans-Regular";
  padding: 0 1.5rem !important;
}
.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  font-size: 26px; 
}
.count-minus,
.count-plus {
  display: block;
  width: 50px;
  height: 40px;
  margin: 0;
  background: #f9f9f9;
  text-decoration: none;
  text-align: center;
  line-height: 35px;
  user-select:none
}
.count-minus {
  border-radius: 3px 0 0 3px;
}
.count-plus {
  border-radius: 0 3px 3px 0;
}
.count-minus:hover,
.count-plus:hover {
  background: #6A29FF;
  color: #fff;
  cursor: pointer;
} 
.count-input {
  width: 60px;
  height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #f9f9f9;
  border-bottom: 2px solid #f9f9f9;
  border-left: 1px solid #f9f9f9;
  border-right: 2px solid #f9f9f9;
  background: #f9f9f9;
}
.confirm-button{
  background-color: #6A29FF !important;
  color: #fff !important;
  text-shadow: none !important;
  width: 120px !important;
  height: 40px!important;
}
.cancel-button{
  background-color: #d9d9d9!important;
  color: #000 !important;
  text-shadow: none !important;
  width: 120px !important;
  height: 40px!important;
}
.ui.modal.licence-modal>.close{
    top: 0 !important;
    right: 0 !important;
    opacity: 1 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #999 !important;
}
.check-icon{
  color: green;
}
.close-icon{
  color: #8D8F9C;
  font-weight: 400;
  cursor: pointer;
}
.close-icon-inactive{
  opacity: 0.2 !important;
  cursor: none;
}
.question-icon{
color: #C1C4D5;
opacity: 0.8;
}
.qauestion-active{
  color: #8D8F9C;
  font-size: 1.3em;
  cursor: pointer;
}
.no-license-content{
  height: 375px;
  text-align: center !important;
  padding: 30px 0px !important;
}
.no-license-text{
  font-family: "NunitoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #8D8F9C;
  margin: 10px 0px;
}
.purple_link{
  color: #6A29FF !important;
  cursor: pointer;
}
.ml-footer {
  color: #8D8F9C;
  font-weight: 700;
  font-family: 'NunitoSans-Light';
  padding: 15px 25px;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-align: left;
  height: 120px;
  background:#fff;
  float: left;
  border-top: 1px solid #f4f4f4f4;
}
.ml-footer-content{
  width:96%;
  float: left;
}
.ml-footer-icon{
  width: 3%;
  float: left;
}
.pl-10{
  padding-left: 10px !important;
}
.mlv-card-left > [data-tooltip] {
  margin-left: 50px;
}