.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper {
  width: 100%;
  background-color: #141414;
  padding: 10px 10px 10px 10px;
  overflow: auto;
  scrollbar-width: none; /* hiding scrollbar Firefox */
  -ms-overflow-style: none; /* hiding scrollbar Internet Explorer 10+ */
}

.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper::-webkit-scrollbar {
  display: none; /* hiding scrollbar Chrome, Safari, and Opera */
}

.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper .gallery-mini .video-tile-container{
  height:30vh;
  max-height:50vh;
}

.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper .gallery-main .video-tile-container{
display: flex;
justify-content: center;
}

.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper .gallery-mini .video-tile-container .video-tile{
  width: 100%; 
  display: flex;
  flex-direction: column;
  gap:100px; 
}

.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper .gallery-mini .video-tile-container .video-tile .player-container{
  width: 100%;
}

.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper .video-tile-container {
  min-width: 200px;
  height: 180px;
  border: 4px solid #1d1d1d;
  border-radius: 8px;
}

.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper .video-tile-container .video-tile .video-controls .overlay-audio-off{
  margin-right: 10px;
}

.meeting-room .video-tray .video-tile-wrapper .gallery-mini{
  display: grid !important;
  height: 100%;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  gap: 10px;
  width: 100%;
  transition: width 0.2s ease-out;
}

.meeting-room .video-tray .video-tile-wrapper .gallery-main{
  display: grid !important;
  height: 100%;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  gap: 10px;
  width: 100%;
  transition: width 0.2s ease-out;
}
