.diagnostics-modal {
    margin: 1em 2.5% !important;
}
.diagnostics-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.diagnostics-wrapper h4 {
    font-size: 18px;
}
.diagnostics-speaker, .diagnostics-microphone, .diagnostics-video, .diagnostics-connectivity {
    width: 49.5%;
    padding: 15px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.diagnostics-action-btn {
    margin: 15px 0 !important;
}
.diagnostics-test-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.diagnostics-test-wrapper > div:first-child { 
    width: 80%;
    margin: 5px;
}
.diagnostics-result {
    width: 100%;
    text-align: center;
}
.diagnostics-error{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    font-weight: bold;
    padding: 15px 0;
    border-radius: 5px;
}
.diagnostics-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    font-weight: bold;
    padding: 15px 0;
    border-radius: 5px;
}