
.meeting-room .video-tray .video-tray-wrapper{
  display: flex;
  flex-direction: row;
  position: relative;
  bottom: 0px;
  width:100%
}


.meeting-room .video-tray .video-tray-wrapper .tray-btns {
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d1d1d;
  width: 44px;
  height: 200px;
}




