.zoom-viewer .player-wrapper {
  display: flex;
  height: calc(100% - 200px);
  width: 100%;
}

.zoom-viewer .player-wrapper-full-height {
  height: 100%;
}

.zoom-viewer .viewerstream {
  margin-top: 0px;
  background: black;
  height: 100%;
  width: 100%;
  position: relative;
}

.zoom-viewer .vieweraspectratio {
  padding-bottom: 62.5%;
  position: absolute;
}

.zoom-viewer .viewcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
}

.zoom-viewer .viewcontainer .videoparticipant video {
  width: 100% !important;
}

.zoom-viewer .viewerstream-video {
  outline: none;
  width: 100%;
  height: 100%;
}

.zoom-viewer .viewerstream-video-withChat {
  width: 77%;
  outline: none;
  height: 75vh;
  position: relative;
}

.zoom-viewer .overlay-video {
  position: absolute;
  top: 4%;
  left: 43%;
  z-index: 1;
  font-size: 25px;
  padding: 5px;
  background: black;
  color: white;
}

.zoom-viewer .multi-video-container {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #000000;
}

.zoom-viewer .multi-video-container .viewerstream {
  height: 33.33%;
  padding: 5px 0;
}

.zoom-viewer .multi-video-container .viewerstream .viewcontainer .video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.zoom-viewer
  .multi-video-container
  .viewerstream
  .viewcontainer
  .video-container
  .viewerstream-video {
  cursor: pointer;
}

.zoom-viewer
  .multi-video-container
  .viewerstream
  .viewcontainer
  .video-container
  .inactive-stream-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-viewer
  .multi-video-container
  .viewerstream
  .viewcontainer
  .video-container
  .inactive-stream-section
  .inactive-stream-text {
  color: #ffffff;
  font-size: 14px;
}
.zoom-viewer .show-speaker-viewer-container{
  /* display: flex; */
  margin: 0 auto;
  outline: none;
  max-height: 100%;
  max-width: calc(100% - 290px);
  /* position: absolute; */
  /* left: 0px; */
}
/* Media Query for iPad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (not (max-width: 767px)) {
  .zoom-viewer .viewcontainer {
    position: fixed;
  }

  aside.ant-layout-sider.ant-layout-sider-dark.viewer-side-panel {
    height: 600px;
    min-width: 320px !important;
  }

  .zoom-viewer .main-menu-right-chat {
    height: 600px;
    overflow: hidden;
  }

  .zoom-viewer .video-tray-collapse-bg {
    display: none;
  }
  /* .show-new-viewer .watermark-actions{
    margin-top: 0px;
  }
  .show-new-viewer .publisher-panel-content{
    margin-top: 0px;
  } */
}

/* Media Query for iPad Landscape */
@media only screen and (min-device-width: 1023px) and (max-device-height: 1366px) and (orientation: landscape) and (not (max-width: 767px)) and (not (min-width: 1024px)) and (not (max-width: 1366px)) {
  .zoom-viewer .viewcontainer {
    position: fixed;
    padding-left: 40px;
  }
  .canvas-cursor-auto{
    padding-left: 100px
  }
  .zoom-viewer .video-tray-collapse-bg.inverse-layout{
   height: 210px;
  }
  .zoom-viewer .video-tray-collapse-bg{
    height: 210px;
  }
}

/* @media (min-width: 600px) and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
  .zoom-viewer .main-menu-right-chat {
    height: 70% !important;
  }
  .show-new-viewer .watermark-actions{
    margin-top: 0px;
  }
  .show-new-viewer .publisher-panel-content{
    margin-top: 0px;
  }
} */
