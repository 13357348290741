.form-input-phone {
    box-shadow: 0 0 0 100px #f4f4f4 inset !important;
    border: 1px solid #707070 !important;
    border-radius: 0px !important;
    font-family: "IBMPlexSans-Regular" !important;
    font-size: 18px !important;
    letter-spacing: 1.03px !important;
    opacity: 1;
    color:#000 !important
  }

.form-input-phone input[type="number"] {
  color: #000 !important;
}
.copy-text{
  font-family: "IBMPlexSans-Italic" !important;
  font-size: 14px !important;
  letter-spacing: 1.02px !important;
  opacity: 1;
  color:#000 !important
}