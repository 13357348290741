.mailbox-wrapper {
  width: 100%;
  display: flex;
}
.mailbox-wrapper .ant-input,
.mailbox-wrapper .ant-select-selector,
.mailbox-downloader .ant-input,
.mailbox-wrapper .ant-select-single .ant-select-selector .ant-select-selection-item {
  height: auto;
  min-height: 20px;
}
.mailbox-wrapper .ant-select-single .ant-select-selector .ant-select-selection-item {
   padding: 0;
}
.mailbox-wrapper th {
  background: black;
  font-weight: bold;
  font-size: 14px;
  color:  #ffff;
}

.inbox-wrapper-table {
  width: 90%;
  margin: 1% auto;
  }

.masv-table-wrapper .ant-table-wrapper .ant-table-column-sorters .ant-table-column-title {
  color: #ffff;
}

.inbox-wrapper-table .ant-table-container {
    width: auto;
  }
.mailbox-downloader {
  margin: 5% 0;
  font-size: 15px;
  color: black;
  font-weight: bold;
}
.mailbox-downloader .password-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inbox-wrapper ul {
  padding: 0;
}
.mailbox-wrapper .select_recipients .ant-select-selector{
  height: 30px;
}
.mailbox-wrapper .ant-form-item .ant-form-item-label{
  font-weight: bold;
  color:black;
  text-align: left;
  margin-left: 50px;
  white-space: pre-wrap;
}
.mailbox-wrapper .ant-select-selection-placeholder{
  height: 30px;
  border: 5px;
  margin-top: 5px;
} 
.mailbox-wrapper .ant-menu-title-content{
  font-weight: bold;
  font: sans-serif;
  font-size: 18px;
}
.recipients-table th {
  color: #fff !important;
}
.recipients-table .ant-table-column-sorter {
  color: white;
}
.mailbox-wrapper .upload-btn{
  background-color:#6435c9;
  color: #fff;
}
.file-info-icon {
  color: #6435c9;
  cursor: pointer;
}
tr.ant-table-row:hover > td .file-info-icon {
  color: #fff;
  cursor: pointer;
}

.drawer-recipients-list .ant-drawer-right>.ant-drawer-content-wrapper {
  height: fit-content;
}
.mailbox-wrapper .send-file-header{
  margin-left: 200px;
  font-weight: 700;
}
.mailbox-wrapper .gen-pass .gen-pass-btn{
  margin-top: -168px;
  color: #6435c9;
  border: none;
  outline: none;
  box-shadow: none;
  height: 20px;
  margin: 7px;
  border-radius: 10px;
  width: 150px;
  background: none;
}
.mailbox-wrapper .gen-pass{
  margin-top: -28px;
  margin-left: 360px;
  box-shadow: none;
  border: none;
  outline: none;
  background: none;
}
.Header-pop-up{
  font-size: 20px;
  font-weight: bold;
}
.download-Btn{
  background-color:#6435c9;
  border-radius:8px;
}
.get-Pass-Btn{
  border: none;
  outline: none;
  box-shadow: none;
  color:#6435c9;
  border-color: #ffff;
  margin-left: 230px;
  width: 106px;
  background-color:#ffff ;
}

.add-Files-Btn, .add-Folder-Btn {
  color: #6435c9;
  border-color: #6435c9;
  width: 200px;
  
}
.flex-Container{
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  width: 100%;
}
.error-Msg-Pop-Up{
  color: red;
}

.heading-text-mailbox {
  margin-left: 15px;
  text-align: left;
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #000000;
  opacity: 1;
  width: 97%;
  margin-left: 40%;
}
p:first-child {
  margin-top: 20px;
}
.mailbox-wrapper .upload-btn-disabled {
  background-color: #767676;
  color: #fff;
  /* cursor: 'not-allowed'; */
}
.mailbox-wrapper .upload-btn-disabled .ant-btn-primary:disabled {
  color:#fff;
}
.table-row-without-border .ant-table-row {
  /* background-color: rgb(244, 244, 244); */
  background-color: #80808000;;
  border: 1px solid #f4f4f4!important;
  border-collapse: initial;
  border-spacing: 0 1px;
  cursor: pointer !important;
}
.table-row-without-border .ant-table-tbody > tr.ant-table-row:hover > td {
  /* background: none !important; */
  background: #6435c9;
  color: #fff;
}
.mailbox-search .ant-input-group-wrapper {
  display: inline-block;
  width: 16%;
  text-align: start;
  vertical-align: top;
  margin-left: 68px;
}
.mailbox-search .ant-input-search .ant-input-search-button {
  height: 32px;
}
.inactive-row  {
  color: #FF5722;
  /* cursor: not-allowed !important; */
  /*pointer-events: none; Prevent user interactions */
  /* cursor: none; */ 
}
.mailbox-search .ui.checkbox {
  margin-left: 30px;
  margin-top: 5px;
}
.send-file-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap:100px; */
}
 .progress-loader-container{
  width:inherit ;
  height:300px;
  /* margin:100px; */
  /* height: inherit; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #6435c9; */
}
.progress-modal .ant-modal-footer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.progress-modal .ant-modal-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 900;
}
.progress-modal .status-text{
  margin:5px 0px 5px 0px;
  font-weight: 700;
}
.cancel-upload-btn{
  background-color: #6435c9;
  color: #f4f4f4;
  font-weight: 700;
}
.okay-upload-btn{
  margin-left: 300px;
  background-color: #6435c9;
  color: #f4f4f4;
}
.send-file-container .ant-form-item .ant-form-item-label > label {
  font-family: 'IBMPlexSans-Medium' !important;
}
.mailbox-wrapper .gen-pass .gen-pass-btn{
  margin-top: -168px;
  color: #6435c9;
  border: none;
  outline: none;
  box-shadow: none;
  height: 20px;
  margin: 7px;
  border-radius: 10px;
  width: 150px;
}
.mailbox-wrapper .gen-pass{
  margin-top: -28px;
  margin-left: 356px;
  box-shadow: none;
  border: none;
  outline: none;
}
.Header-pop-up{
  font-size: 20px;
  font-weight: bold;
}
.download-Btn{
  background-color:#6435c9
}
.error-Msg-Pop-Up{
  color: red;
}
.mailbox-wrapper .btn-disabled {
  background-color: #6435c9;
  background-image: none;
  color: #fff;
  text-shadow: none;
  opacity: .45!important;
}
.confirm-download .ant-btn-default:hover {
  color: #6435c9;
  border-color:#6435c9;
}

.send-files-input input[type="number"]{
  background-color: white !important;
  color: #000000 !important;
}
.upload-file .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
  width: 30px;
}
.input-number.ant-input-number {
  width: 100%;
}
.password-protection.ant-switch.ant-switch-checked {
  margin-top: 12px;
}
.download-limit-drop-down .ant-select-selection-placeholder {
  margin-top: 0px;
}
.password-enable-switch label{
  width: 140px;
}
.failed-tansaction-btn {
  float: right;
  margin-right: 4.4vw;
}
.failed-tansaction-btn >span {
  font-weight: bold;
}