.vc-list-padding-10 {
  padding: 10px;
}

.modal-top-17 {
  top: 17.5vh;
}

.modal-content {
  height: 65vh;
  display: flex;
}

.modal-content-left {
  width: 50%;
  overflow-y: auto;
  border-right: 1px solid #e8e8e8;
  padding-right: 10px;
}

.modal-content-right {
  width: 50%;
  padding: 0 10px;
  overflow-y: auto;
}

.tooltip-content {
  max-height: 200px;
  overflow-y: auto;
  width: 200px;
}

.tooltip-list-item {
  padding: 4px 0;
  border-bottom: none;
}

.vc-list-info-icon {
  margin-left: 8px;
  cursor: pointer;
}

.highlight-text {
  color: #1890ff;
}

.loading-container {
  text-align: center;
  margin-top: 20px;
}

.vc-list-text {
  margin-top: 10px;
}
