.un-invite-flex {
  display: flex;
}

.show-publisher-uninvite-users-table tbody {
  display: block;
  height: calc(100vh - 355px);
  overflow: auto;
}
.show-publisher-uninvite-users-table thead, .show-publisher-uninvite-users-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 5px solid #fff !important;
}

.show-publisher-uninvite-users-table .header-cell-without-border {
  border-collapse: collapse;
}

.show-pub-uninvite-users-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 45px;
  background: #C1C4D5;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  align-items: center;
  padding: 14px;
}

.col-name {
  flex-grow: 1;
}
.col-check-all {
  right: 27px;
  display: flex;
  position: absolute;
}
.col-remove-th {
  margin-right: 5px;
}
.show-pub-uninvite-users-select-all {
margin: 12px 26px;
display: flex;
justify-content: flex-end;
}

.show-pub-uninvite-users-select-all .ui.checkbox label {
  padding-left: 0;
  padding-right: 1.85714em;
}

.show-pub-uninvite-users-select-all .ui.checkbox label:before, .show-pub-uninvite-users-select-all .ui.checkbox label:after {
  right: 0 !important;
  left: unset;
}

.show-pub-uninvite-users-body {
  background: #EAEAEA;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 18px;
}

.show-pub-uninvite-users-body .ant-collapse-header {
  padding: 0 !important;
}

.show-pub-uninvite-users-body .ant-collapse {
  background-color: #EAEAEA;
}
.show-pub-uninvite-users-body .ant-collapse:hover {
  background-color: #EAEAEA;
}
.show-pub-uninvite-users-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.remove-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.remove-button {
  width: 120px;
  height: 33px;
  border: 1px solid #6A29FF;
  background: #FFFFFF;
  color: #6435C9;
  cursor: pointer;
}

.remove-button:disabled {
  opacity: 0.3;
}