.zoom-meeting-webcam-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.zoom-meeting-video-wrapper {
    overflow: hidden;
}

.zoom-meeting-webcam-video {
    height: 90%;
    width: 85%;
    background-color: #000000;
    object-fit: cover;
}

.zoom-meeting-video-label {
    display: flex;
    justify-content: left;
    align-items: center;
    color: white;
    background-color: #1b1c1d;
    width: 100%;
    height: 40px;
    position: fixed;
    padding: 0 24px;
}

.zoom-meeting-webcam-inner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zoom-meeting-video-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.zoom-meeting-video-label {
    position: absolute;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: left;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.video-option-wrapper{
    /* height: 81px; */
    width: 240px;
    border-radius: 20px;
}

.video-option-container .option-item{
    color: white;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 24px;
    color: white;
    height: 24px;
    cursor:pointer;
}

.video-option-container .ant-popover-inner{
    background-color: #393939 !important;
    padding: 10px;
    border-radius: 20px;
}

.video-option-container hr{
    border: 0.2px solid #FFFFFF26;
    margin: 1rem 0;
}