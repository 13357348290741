.algolia-table,
.algolia-table th {
  width: 100%;
}
.algolia-table th {
  position: relative;
}
.algolia-table th::after {
  content: " ";
  position: absolute;
  border-left: 1px solid #f4f4f4;
  top: 5%;
  bottom: 5%;
  right: 0;
  height: 80%;
  margin-top: auto;
  margin-bottom: auto;
}
.algolia-table th:last-child::after {
  border: none;
}
.sort-asc-column {
  background-image: url("../../Images/sort-arrow.png");
  background-repeat: no-repeat;
  padding: 5px 10px; 
  background-size: 10px;
  background-position: center;
  display: inline-block;
}
.sort-desc-column {
  background-image: url("../../Images/sort-arrow.png");
  background-repeat: no-repeat;
  padding: 5px 10px; 
  background-size: 10px;
  background-position: center;
  display: inline-block;
  transform: rotate(180deg);
}
.sort-none-column {
  visibility: hidden;
}
.algolia-footer .pagination  {
  margin-top: 0;
}
.algolia-footer .pagination .ais-Pagination-link {
  display: inline-block;
  margin: 0 2px;
  cursor: pointer;
}
.algolia-footer .pagination .ais-Pagination-link:disabled {
  opacity:.6;
  cursor:not-allowed;
  color:#a5abc4
 }
 .action-button{
   width: 92px;
 }