.layout-left-content {
    background: white;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    flex: 2;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 44px;
    max-width: 420px;

  }
  
  .layout-left-content .logo {
    margin-top: 20px;
    margin-left: 20px;
  }
  .layout-right-content {
    flex: 3;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 44px;
    align-items: center;
    background: #f7f8fe;
  height: min-content;

  }

.container-wrapper {
  width: 100%;
}
.container-wrapper .tab {
  min-height: 100%;
}

.layout-right-content .header-menu {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  /* right: 10px; */
  /* margin-top: 20px; */
  margin-right: 20px;
  
}
.container-wrapper .ant-tabs-nav {
  height: 76px;
  background-color: #ffffff;
  margin: 0 !important;
  border: none !important;
}
.layout-right-content .ant-tabs-tab {
  display: flex;
  width: 76px;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid #EDEDED;
}

.tab-wrapper {
  height: 100%;
  padding: 5%;
}

.left-container-wrapper {
  margin: 30px;
}

.left-header {
  height: 30px;
}
.left-container-searchbar input {
  height: 45px;
}

.left-container-searchbar {
  flex: 1 1;
}

.left-container-searchbar .ant-input-group-addon {
  height: 45px;
}
/* .ant-input-affix-wrapper {
  border-right-width: 0px;
} */

.ant-input-search .ant-input-search-button {
  height: 55px;
}

.-btn-wrapper{
  margin-top:78px;
  padding:50px;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
.searchbar-wrapper{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
.searchbar-manage-users{
  margin-top: -15px;
}
.searchbar-btn {
  flex: 4 1;
}
.search-toggle .ant-input-affix-wrapper  {
  display: none;
  text-align: end;
  flex: 1 1;
  -moz-transition: width 1s ease;
  -webkit-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
}
.searchbar-toggle-btn {
  display: none;
  -moz-transition: width 1s ease;
  -webkit-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
}

.-form-wrapper{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  gap: 10px;
}

.add-btn,
 .add-btn:hover{
  width: 100%;
  height: 55px;
  background-color: #6A29FF;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
 }
 .form-content .ant-form-item  {
   width: 100% ;
 }
 .ant-input {
  height: 54px;
 }
 .btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.invite-btn,
 .invite-btn:hover{
  width: auto;
  height: 49px;
  background-color: #8867d4;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
  padding: 0px 30px 0px 30px;
 }
 .checkbox-wrapper {
  padding: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
 }
 .ant-select-selector {
  /* height: 52px !important; */
  margin-top: 0px;
}