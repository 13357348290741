.logo {
  grid-column: 1 / 2; 
  grid-row: 1 / 2;
  display: inline-block;
  filter: invert(1);
  height: 40px;
  width: 100px;
  background-image: url("../../Images/logo_PP_whiteFooter.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content {
  text-align: center;
  margin-bottom: 5rem;
}

.hi {
  height: 100px;
  filter: invert(68%) sepia(4%) saturate(5741%) hue-rotate(216deg) brightness(101%) contrast(107%);
  margin: 2rem 0;
}
.waitingmodal{
  background:#ffffff;
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow:hidden;
  pointer-events: none;
  z-index: 1000;
}