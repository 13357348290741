.subscribe-wrapper {
  margin: 25px auto;
  width: 80%;
  /* height: 1150px; */
}

.subscribe-steps-wrapper {
  margin: 25px auto;
  width: 70%;
  min-height: 700px;
}
.subscribe-wrapper .heading-text {
  text-align: center;
}

.steppers {
  margin: 15px auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

.steppers div {
  flex-grow: 1;
  padding: 15px 0;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 4px solid #6600ff;
  color: #6600ff;
  cursor: pointer;
}

.steppers div:last-child {
  text-align: right;
}

.billing-info-wrapper {
  padding: 15px; 
}

.billing-info-details {
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 250px);
}


.billing-info-wrapper .ui.form {
  position: inherit;
}

.stripe-billing-info {
  display: flex;
  flex-wrap: wrap;
}

.stripe-billing-info > div.width_50 {
  width: 50%;
}

.billing-field {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 2rem;
}

.billing-field .left-icon {
  margin-right: 22px;
  margin-left: 0;
  float: none;
}

.billing-field .field-icon-email {
  margin-left: -10px;
}

.billing-info-wrapper .ui.form .icon-input-field {
  float: none;
  height: auto;
  margin-top: 0;
}

.billing-info-wrapper .ui.form .form-field {
  margin: 0;
}

.stepper-action {
  display: flex;
  padding: 0 15px;
  margin: 0 auto;
}

.stepper-action div {
  width: 100%;
  text-align: center;
}

.billing-field-alignment {
  margin: 0 0 0 25px;
  width: calc(50% - 25px) !important;
  align-self: baseline;
  padding: 0;
}

.stripe-billing-info .note_label {
  padding: 0 0 0 50px;
  font-weight: bold;
}
.list-streams-table-view button {
  width: 100%;
}
.customer-details-form {
  height: 1400px;
}
