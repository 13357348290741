.zoom-meeting-chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.zoom-meeting-chat-window {
  flex: 1;
  padding: 16px 20px;
  overflow-y: auto;
  background-color: rgba(20, 20, 20, 1);
}

.zoom-meeting-chat-message {
  margin-bottom: 10px;
}

.zoom-meeting-message-text {
  font-weight: bold;
}

.zoom-meeting-timestamp {
  color: grey;
  font-size: 10px;
  font-weight: 400;
}

.zoom-meeting-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 1);
  font-size: 14px;
  padding: 45px 12px 12px 12px;
  gap: 12px;
}

.zoom-meeting-input-field {
  flex: 1;
  height: 36px;
  padding: 8px 12px;
  border: 1px solid #3f3f3f;
  border-radius: 4px;
  background-color: #1f1f1f;
  color: white;
  font-size: 14px;
  flex: 1;
  height: 36px;
  background-color:#282829;
  color: white;
  font-size: 14px;
}

.zoom-meeting-input-field-edit {
  flex: 1;
  height: 36px;
  padding: 8px 12px;
  border: 2px solid #3f3f3f;
  border-radius: 4px;
  background-color: #1f1f1f;
  color: white;
  font-size: 14px;
  flex: 1;
  height: 36px;
  background-color:#282829;
  color: white;
  font-size: 18px;
}
.zoom-meeting-edit-confirm-button{
  background-color: #282829;
  border: 1px solid !important;
  color: white;
  font-size: 8px;
  padding: 6px 10px;
  border-radius: 4px;
  border: none;
  margin-left: 10px;
}

.zoom-meeting-edit-cancel-button{
  background-color: #282829;
  border: 1px solid !important;
  color: white;
  font-size: 8px;
  padding: 6px 10px;
  border-radius: 4px;
  border: none;
  margin-left: 10px;
}

.zoom-meeting-input-upload{
  flex: 1;
  background-color: #282829;
  color: white;
  height: 43px;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
}



.zoom-meeting-chat-filter{
  background-color: rgba(20, 20, 20, 1);
  font-size: 16px;
  font-weight: 500;
  padding: 16px 20px; 
}

.zoom-meeting-chat-filter select{
  border-radius: 50px;
  color: white;
  font-size: 16px;
  font-weight: 500;  
  border: none;
  padding: 6px 10px;
  margin-left: 8px;
  width: 80%;
  appearance: none; /* Hides the default arrow */
  background-color: #6A29FF;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z' fill='%23ffffff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 30px;
}

.zoom-meeting-chat-filter select option{
  background-color: #282829;
}

.zoom-meeting-chat-item{
  border: 1px solid #1D1D1D;
  padding: 16px;
  background-color: #282829;
  border-radius: 8px;
  max-width: 92%;
  /* margin-bottom: 1rem; */
}

.zoom-meeting-chat-item.sent{
  margin-left: auto;
}

.zoom-meeting-chat-item.sent svg{
  vertical-align: bottom;
  margin-left: 10px;
}

.zoom-meeting-chat-item h3{
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 6px;
 }

 .zoom-meeting-chat-item p {
  font-size: 14px;
  font-weight: 400;
  color: #8D8F9C;

 }

.zoom-meeting-input-upload input{
  border: none;
}

input.zoom-meeting-input-field {
  background-color: #282829;
  color: white;
  height: 32px;
}

.zoom-meeting-chat-header {
  padding: 19px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif !important;
  background-color: rgba(29, 29, 29, 1);
}

.zoom-meeting-message-container {
  display: flex;
  align-items: center;
}

.zoom-meeting-text {
  margin-right: auto;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Nunito Sans', sans-serif !important;
  letter-spacing: 2.5px;
}

.zoom-meeting-icons-container {
  display: flex;
  gap: 15px;
  margin-left: 20px;
}

.zoom-meeting-icon {
  font-size: 15px;
  width: 25px;
}

.zoom-meeting-send-icon {
  height: 40px;
}

.zoom-meeting-chat-time {
  margin-bottom: 1rem;
  display: block;
  text-align: left;
}

.zoom-meeting-chat-time.sent {
  margin-left: auto;
  text-align: right;
}

.zoom-meeting-chat-scroll {
  max-height: 100%;         
  overflow-y: auto;         
  overflow-x: hidden;
}

.zoom-meeting-input-container .emoji-picker{
  position: absolute;
  bottom: 66px;
  right: 0;
  width: 100%;
}

.zoom-meeting-input-container .EmojiPickerReact{
  width: 100% !important;
  border-radius: 0px;
}

svg.zoom-meeting-download-icon {
  margin-left: 10px;
}

.zoom-meeting-file-name{
  font-size: 15px;
  color: #ffffff7a;
}

.zoom-meeting-download-icon{
  margin-left: 5px;
}