.appleTvheader {
  font-weight: 700;
  font-size: 14px;
}
.appleTvbanner {
  min-height: 50px;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px 20px 0px;
}
.appleTvcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.appleTvcontainerInputfield {
  height: 40px;
  line-height: 40px;
  border: none; /* Remove all borders */
  outline: none; /* Remove the default outline */
  border-bottom: 3px solid #6435c9; /* Add the bottom border */
  margin-bottom: 20px;
  letter-spacing: 8px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  /* padding-bottom: 1px solid#6435c9; */
  /* background-color: #000; */
}
.appleTvcontainerButton {
  margin-top: 15px;
  height: 50px;
  width: 40%;
  border-radius: 8px;
  border: none;
  background-color: #6435c9;
  color: #fff;
  line-height: 40px;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
}
.appleTvcontainerButton:disabled {
  background-color: #b09cdc;
  cursor: not-allowed;
}
th {
  color: #fff !important;
  white-space: nowrap;
}