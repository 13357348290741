
.zoom-meeting-header{
    display: flex;
    padding: 10px;
    width: 100%;
    padding-inline: 0;
    height: 80px;
    background: #000;
    line-height: 20px;
    justify-content: center;
    align-items: center;
    .header-buttons{
        margin: 0 5px;
    }
    .center-icons {
        display: flex;
        justify-content: center;
        align-items :center;
        padding: 10px;
        .background-square{
            background-color: grey;
        }
    }  
    .micMute-icons{
        padding:10px;
        min-width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        // transition: 0.4s;
        background-color: #141414;
        border-radius: 8px;
        box-sizing: border-box;
        svg{
            height: 43px;
            width: 13px;  
        }
        .down-arrow{
            gap: 10px;
            position: absolute;
            width: 28px !important;
            height: 16px !important;
            fill: #ffffff;
            /* bottom: -83px; */
            top: 5%;
            transition: none !important;
            animation: none !important;
        }
    }  

    
    .videoOff-icons{
        padding:10px;
        min-width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        transition: 0.4s;
        background-color: #141414;
        border-radius: 8px;
        box-sizing: border-box;
        svg{
            height: 41px;
            width: 21px;  
        }
    }
    .shareIcon-icons{
        padding:10px;
        min-width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        transition: 0.4s;
        background-color: #141414;
        border-radius: 8px;
        box-sizing: border-box;
        svg{
            height: 21px;
            width: 21px;
        }
    }
    .participate-icons{
        padding:10px;
        min-width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        transition: 0.4s;
        background-color: #141414;
        border-radius: 8px;
        box-sizing: border-box;
        .participants-count {
            font-size: 10px;
            margin-top: -5px;
            margin-right: -5px;
            font-weight: bold;
            color: #888888;
            &.active {
                color: #ffffff;
            }
        }
        svg{
            height: 35px;
            width: 24px;
        }
    }
    .chat-icons{
        padding:10px;
        min-width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        transition: 0.4s;
        background-color: #141414;
        border-radius: 8px;
        box-sizing: border-box; 
        svg{
            height: 36px;
            width: 16px;
        }
    }
    .endCall-icons{
        min-width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        transition: 0.4s;
        background-color: #141414;
        border-radius: 8px;
        box-sizing: border-box;
        svg{
            height: 100%;
            width: 100%;
        }
    }

    .swap-icons{
        padding:10px;
        min-width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        transition: 0.4s;
        background-color: #141414;
        border-radius: 8px;
        // position: relative;
        box-sizing: border-box; 
        display: none;
    }

    .right-buttons{
        margin: 0 5px;
    }
    .right-icons{
        display: flex;
        padding: 10px;
        width: max-content;
        right: 0;
        position: absolute;
        
    }
        .invert-icons{
            padding:10px;
            min-width: 56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-content: center;
            border-bottom: 3px solid transparent;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            transition: 0.4s;
            background-color: #141414;
            border-radius: 8px;
            box-sizing: border-box;
        svg{
            height: 40px;
            width: 100%;
        }
    }
        .fullScreen-icons{
            padding:10px;
            min-width: 56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-content: center;
            border-bottom: 3px solid transparent;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            transition: 0.4s;
            background-color: #141414;
            border-radius: 8px;
            box-sizing: border-box;
            svg{
            height: 33px;
            width: 20px;
            }
        
    }
  
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (not (max-width: 767px)) {
   
  }

@media(device-width: 375px) and (device-height: 667px) {
    .endCall-icons {
    
        position: fixed;
        bottom: 22px;              
        align-items: center;
        right:15px;
      
    }
    .center-icons {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 9px !important;
        height: 50px;
        width: 100%;
        right: -6px !important;
        position: absolute;
    }
    .header .right-icons{
        display: none;
    }
    
    .header .swap-icons {
        display: flex;
        position: fixed;
        bottom: 22px;
        left: 15px;
    }

    .zoom-meeting-content{
        grid-template-columns: 100% !important;
    }

    .sidebar{
        position: fixed;
        top: 36%;
        left: 0;
        width: 100% !important;
    }

  }  
.edit-profile-popup {
    background-color: #141414 !important;
    width: 613px !important;
    height: 430px;
    border-radius: 20px !important;
    gap: 15px;
    opacity: 1;
    color: white;
}
.zoom-meeting-edit-modal {
    width: 493px;
    height: 291px;
    gap: 42px;
    text-align: center;
    margin: 30px 30px;
}
.zoom-meeting-edit-modal-title {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center !important;
    left: 28px;
    position: relative;
}

.zoom-meeting-modal-value {
    color: #6F2DDA;
    text-decoration: underline;
}
.zoom-meeting-cancel-button {
    background: transparent;
    width: 71px;
    height: 34px;
    padding: 0px 14px 0px 14px;
    border-radius: 25px !important;
    border-width: 1px;
    border-color: #fff;
    color: white;
    bottom: 10px;
    left: 180px;
    position: relative;
    &:hover {
        background: var(--PurpleAccent, #6F2DDA);
      }
}
.zoom-meeting-save-button {
    background: var(--PurpleAccent, #6F2DDA);
    width: 71px;
    height: 34px;
    padding: 0px 14px 0px 14px;
    border-radius: 25px !important;
    border-width: 1px;
    border-color: #fff;
    color: white;
    bottom: 10px;
    left: 190px;
    position: relative;
}
.zoom-meet-details {
    text-align: left;
    left: 35px;
    top: -20px;
    position: relative;
    font-family: Inter;
}
.edit-profile-options-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: transparent !important;
    max-width: 600px;
    margin: 0 auto;
  }
/* Edit option fieldset styling */
.edit-profile-options-wrapper .fieldset {
    border: none;
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
.edit-profile-options-wrapper .legend {
    font-weight: bold;
    margin-bottom: 8px;
    color: #FFFFFF;
  }
  
  /* Specific to the security fieldset */
.edit-profile-options-wrapper .security-fieldset {
    flex-direction: column;
    align-items: flex-start;
    border-color: transparent;
  }
  
.edit-profile-options-wrapper  .security-fieldset .password-text {
    width: 112px;
    height: 40px;
    border: 1px solid #909090;
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 8px;
  }
  
.edit-profile-options-wrapper .security-fieldset label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #909090 !important;
  }
  
  .edit-profile-options-wrapper .security-fieldset label input[type="checkbox"] {
    margin: 0 !important;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid #CCCCCC;
    cursor: pointer;
    border-radius: 4px;
  }
  
.edit-profile-options-wrapper .security-fieldset label input[type="checkbox"]:checked {
    background-color: #6F2DDA;
    border-color: #6F2DDA;
  }
  
.edit-profile-options-wrapper  .security-fieldset label input[type="checkbox"]:checked::after {
    content: "✔";
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    line-height: 20px;
  }
  
  /* Text information styling */
.edit-profile-options-wrapper .info-text {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(144, 144, 144, 1);
    padding: 0px 15px 10px;
  }
  /* Copy Notification */
.edit-profile-options-wrapper .zoom-meeting-participants-copied-notification {
    position: fixed;
    bottom: 150px;
    right: 40px;
    background: rgba(55, 55, 55, 1);
    color: #FFFFFF;
    padding: 8px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.2s;
  }
.edit-profile-options-wrapper .zoom-meeting-participants-copied-notification.visible {
    opacity: 1;
  }
.edit-profile-options-wrapper .zoom-meeting-participants-invitation-link {
    color: #909090;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
.edit-profile-options-wrapper .new-meeting {
    background-color: #0000;
    border: 1px solid #909090;
    height: 40px;
    margin-bottom: 8px;
    border-radius: 5px;
    color: #909090;
    padding: 10px;
    width: 120px;
}

.custom-dropdown-menu-host {
    background-color: #393939 !important;
    width: 210px;
    height: 168px;
    border-radius: 20px !important;
    opacity: 1;
    z-index: 1000 !important;
    box-shadow: 4px 4px 20px 0px #00000080;
    right: 8px !important;
  }
  
  .custom-dropdown-menu-nonhost {
    background-color: #393939 !important;
    width: 210px;
    height: 115px;
    border-radius: 20px !important;
    opacity: 1;
    z-index: 1000 !important;
    box-shadow: 4px 4px 20px 0px #00000080;
    right: 8px !important;
  }
  
  .custom-dropdown-cancel {
    background-color: #4C4C4C;
    width: 190px;
    height: 40px;
    border-radius: 12px !important;
    opacity: 1;
    z-index: 1000 !important;
    left: 6px;
    top: 5px;
    color: #fff !important;
    &:hover {
        background-color: #4C4C4C !important;
        color: #fff;
      }
  }
  .custom-dropdown-leave {
    background-color: #4C4C4C !important;
    width: 190px;
    height: 40px;
    border-radius: 12px !important;
    opacity: 1;
    z-index: 1000 !important;
    left: 6px;
    top: 8px;
    color: #fff !important;
    &:hover {
        background-color: #9D1111 !important;
        color: #ffffff;
      }
  }
  .custom-dropdown-endForEveryone{
    background-color: #9D1111 !important;
    width: 190px;
    height: 40px;
    border-radius: 12px !important;
    opacity: 1;
    z-index: 1000 !important;
    left: 6px;
    top: 20px;
    color: #fff !important; 
  }
  .custom-divider {
    width: 190px;
    height: 1px;
    position: relative;
    top: 5px;
    margin: 8px 8px 5px 5px;
    background: #ffffff26;
    opacity: 1;
    z-index: 1000 !important;
  }
  