.zoom-meeting .reply-check-container {
    display: flex;
    flex-direction: column;
    gap: 15px; 
    justify-content: center; 
}

.zoom-meeting .head {
    font-size: 20px;
    font-weight: 500;   
    text-align: center;
    color: #fff;
    margin-bottom: 42px;
}

.zoom-meeting .speaker-heading {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
}

.zoom-meeting .input-level {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
}

.zoom-meeting.test-speaker-wrapper{
    padding: 0 46px;
}

.zoom-meeting .options {
    width: 347px;
    height: 60px;
    padding: 4px 4px 4px 4px;
    border-radius: 8px;
    border: 1px solid #909090;
    background-color: transparent;
    color: #909090;
    margin-bottom: 26px;
}

.zoom-meeting .options .ant-select-selector {
  height:50px;
  color:#fff;
  background-color: #000000;
  font-weight: 700;
  border: none;
}

.zoom-meeting-microphone-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.zoom-meeting .options option {
    background-color: #282829;
    color: white;
}

.zoom-meeting .button-group {
    display: flex;
    width:100%;
    gap: 10px;
    margin-top: 0px;
    padding: 20px 10px;
    justify-content: center;
}

.zoom-meeting-modal .ant-select .ant-select-arrow .anticon > svg {
    vertical-align: top;
    color: white;
}
/* 
.zoom-meeting .try-another,
.zoom-meeting .yes {
   
    color: white;
    border-radius: 25px;
} */

.zoom-meeting-modal.ant-modal .ant-modal-body {
    display: flex;
    justify-content: center;
}

.zoom-meeting .yes {
  background-color: #6F2DDA;
  border-color: #000000 !important;
  border-radius: 25px;
}

.zoom-meeting .try-another{
    border-color: white; 
    border-radius: 25px;

}