

.primary_btn {
  background-color: #8d57d9;
  color: #fff;
  box-shadow: 0px 1px 2px 0px #101828;
}
.page-header-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.page-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-style: normal;
    color: grey;
    margin-bottom: 15px;
}
.manage-user-wrapper {
    padding:20px
}

.page-header-title-left {
    flex-direction: row;
    display: flex;
    gap: 20px;
}

th {
    color: #000000 !important;
    white-space: nowrap;
}
.disabled-row {
    opacity: 0.5; 
    pointer-events: none;
  }
  .ant-tooltip .ant-tooltip-inner {
    background-color: white;
    color: black;
    --antd-arrow-background-color: white !important;
  }
  .ant-tooltip .ant-tooltip-arrow {
    visibility: visible;
    color: white !important;
    --antd-arrow-background-color: white !important;
  }


.bulk-btn-wrapper{
     display: flex;
    justify-content: end;
    margin-bottom: 5px;
}
.manage-user-wrapper .action-buttons {
  display: flex;
  justify-content: flex-end;
}

.add-user-btn {
  background-color: #6A29FF;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #000000;
}
