.zoom-meeting-participants-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #FFFFFF;

}

.zoom-meeting-participants-header {
  padding: 21px;
  background-color: rgba(29, 29, 29, 1);
  color: #ffffff;
}

.zoom-meeting-participants-view-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.zoom-meeting-participants-text {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Nunito Sans', sans-serif !important;  color: #ffffff;
  letter-spacing: 2.5px;
}

.zoom-meeting-participants-icons-container {
  display: flex;
  gap: 12px;
}

.zoom-meeting-participants-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.zoom-meeting-participants-window {
  flex: 1;
  overflow-y: auto;
  background-color: rgba(20, 20, 20, 1);
  margin-bottom: 100px;
}

.zoom-meeting-participants-window-waiting {
  flex: 1;
  overflow-y: auto;
  background-color: rgba(20, 20, 20, 1);
}

.zoom-meeting-participants-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 8px; */
  padding: 14px 24px;
  border-bottom: 1px solid #FFFFFF1A;
}

.zoom-meeting-participants-view-text {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}

.zoom-meeting-participants-timestamp {
  font-size: 12px;
  color: #666666;
}

.zoom-meeting-participants-input-container {
  padding: 16px;
  border-top: 1px solid #E5E5E5;
  display: flex;
  gap: 8px;
}

.zoom-meeting-participants-input-field {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  font-size: 14px;
}

.zoom-meeting-participants-input-field:focus {
  outline: none;
  border-color: #2D8CFF;
}

.zoom-meeting-participants-add-button {
  padding: 8px 16px;
  background: #2D8CFF;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.zoom-meeting-participants-add-button:hover {
  background: #2477DB;
}


/* Guest Invite Section */

.zoom-meeting-participants-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgba(20, 20, 20, 1);
  position: relative;
}

.zoom-meeting-participants-guest-wrapper {
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
}

.zoom-meeting-participants-guest-label {
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 700;
  display: block;
  /* margin-bottom: 8px; */
}

.zoom-meeting-participants-guest-section {
  background: rgba(20, 20, 20, 1);
  border-radius: 8px;
  padding: 12px;
  border: 1px solid rgba(144, 144, 144,1);
}

.zoom-meeting-participants-link-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.zoom-meeting-participants-invite-link {
  color: #909090;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 40px);
}

.zoom-meeting-participants-copy-button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 45px;
}

.zoom-meeting-participants-copy-button img {
  opacity: 0.8;
  transition: opacity 0.2s;
}

.zoom-meeting-participants-copy-button:hover img {
  opacity: 1;
}

.zoom-meeting-participants-dial-container {
  padding: 0 4px;
}

.zoom-meeting-participants-dial-label {
  color: #FFFFFF;
  font-size: 15px;
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.zoom-meeting-participants-dial-numbers {
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #909090;
  font-size: 15px;
  opacity: 0.8;
  font-weight: 400;
}

/* Copy Notification */
.zoom-meeting-participants-copy-notification {
  position: fixed;
  bottom: 200px;
  right: 40px;
  background: rgba(55, 55, 55, 1);
  color: #FFFFFF;
  padding: 8px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.2s;
}

.zoom-meeting-participants-copy-notification.visible {
  opacity: 1;
}

.zoom-meeting-participants-count {
  font-size: 16px;
  padding: 14px 18px;
}

.zoom-meeting-waiting-view {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 24px;
  border-bottom: 1px solid #FFFFFF1A;
  font-size: 14px;
  font-weight: 500;
}

.zoom-meeting-waiting-window {
  flex: 1;
  overflow-y: auto;
  background-color: rgba(20, 20, 20, 1);
  padding-bottom: 5px;
}

.zoom-meeting-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.zoom-meeting-waiting-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zoom-meeting-guest-name {
  max-width: 130px;
  word-wrap: break-word;
}

.zoom-meeting-button-container {
  display: flex;
}

.zoom-meeting-remove-button,
.zoom-meeting-allow-button {
  border-radius: 10px;
  background-color: rgba(228, 228, 228, 1);
  width: 70px;
  height: 30px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}

.zoom-meeting-remove-button {
  color: red;
  margin-right: 8px;
}

.zoom-meeting-allow-button {
  color: rgba(228, 228, 228, 1);
  background-color: rgba(111, 45, 218, 1);
  border: rgba(111, 45, 218, 1);
}

.zoom-meeting-participants-invite-link.password {
  margin-bottom: 15px;
}

.participants-notification-modal .ant-modal-content {
  width: 325px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 20px;
  position: fixed;
  bottom: 250px;
}


.zoom-meeting-notification-btn-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:10px;
}

.participants-notification-modal {
  background-color: #121212;
  border-radius: 20px;
  right: -195px;
}

.participants-notification-modal .ant-notification-close-x {
  color: #FFFFFF;
  /* display: none; */
}

.participants-notification-modal .ant-notification-notice-btn {
  width: 100%;
}

.participants-notification-modal .ant-notification-notice-content .ant-notification-notice-description{
  color: #ffffff;
}



.close:hover{
  color:red;
}

/* .ant-notification-notice-description{
  color: #ffffff;

} */
.participants-content {
  color: #ffffff;
}

/* .ant-modal-footer{
  text-align: end;
  background: transparent;
} */

.admit-button {
  background-color: #6F2DDA;
  border-radius: 100px;
  /* padding: 10px 15px; */
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
}

.see-waiting-room-button {
  background-color: #000000 !important;
  border-radius: 100px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF !important;
  width: fit-content;
  /* padding: 10px 15px; */
  display: flex;
  justify-content: center;
}

.zoom-meeting-waiting-participants {
  max-height: 200px;
}

.zoom-participants-wrapper{
  max-height: 550px;
}

button.zoom-meeting-arrow {
  background-color: transparent;
  border: transparent;
}
