.zoom-meeting.join-audio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
.zoom-meeting-modal .ant-modal-content {
    background-color: #000000 !important;
    width: 150%;
    height: 100%;
    right: 20%;
    border-radius: 20px;
    padding: 25px 10px;
    gap: 10px;
    opacity: 1;
    border:0.5px solid #242222;
    animation: none !important;
    transition: none !important;
  }
  .zoom-meeting-modal .microphone-select-modal .ant-modal-content  {
    background-color: #141414 !important;
    width: 580px;
    height: 377px;
    right: 0px;
    border-radius: 20px;
    padding: 60px;
    gap: 10px;
    opacity: 1;
    top:43px; 

  } 
 
  .zoom-meeting-modal.join-audio .ant-modal-content {
    background-color: #000000 !important;
    width: 380px;
    height: 180px;
    left: 74px;
    /* right: 50px; */
    border-radius: 20px;
    padding: 60px;
    gap: 10px;
    opacity: 1;
    top: 124px;
    animation: none !important;
    transition: none !important;
  }

  .zoom-meeting-modal .ant-modal-close {
    color: #aaa;
  }

  .zoom-meeting-modal span.ant-modal-close-x svg{
    color: #aaa;
  }

  .zoom-meeting-modal :where(.css-dev-only-do-not-override-sqaf96).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: white;
    background-color: transparent;
    border-color: transparent;
    /* transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1); */
  }

  .zoom-meeting-modal :where(.css-dev-only-do-not-override-sqaf96).ant-select:not(.ant-select-customize-input):hover .ant-select-selector {
    border-color: transparent !important;
  }
  .zoom-meeting .join-speaker
  {
    width: 210px;
    height: 34px;
    background-color: #6f2dda;
    border: 0px;
    color: #ffffff;
    /* transition: border-color 0.3s; */
    margin: 5px;
    border-radius: 25px;
    animation: none !important;
    transition: none !important;
  }

  .zoom-meeting .join-microphone {
    width: 210px;
    height: 34px;
    background-color: transparent;
    border: 0px;
    color:#6f2dda;
    /* transition: border-color 0.3s; */
    margin: 5px;
    animation: none !important;
    transition: none !important;
  }
  
  .zoom-meeting .join-speaker:hover,
  .zoom-meeting .join-microphone:hover {
    background: #6f2dda !important;
    color: white !important;
    border-radius: 25px;
  }

 
  
  
 
