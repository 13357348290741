.encoder-loader {
  text-align: center;
  margin: 10% 0;
}
.encoder-action-wrapper {
  text-align: center;
  margin: 20px 0;
}
.encoder-action-btn .ant-space-item {
  width: 60px;
}
.encoder-action-wrapper .encoder-action-btn .anticon {
  color: #cccccc;
}
.encoder-action-btn .ant-space-item button {
  background: #2c2c2c;
  border: 1px solid #2a2a2a;
}
.encoder-action-btn .ant-space-item button:hover {
  border: 1px solid #6a29ff;
  background: #6a29ff;
}
.encoder-action-btn .ant-space-item button:disabled,
.encoder-action-btn .ant-space-item button[disabled] {
  opacity: 0.5;
}
.encoder-action-btn .ant-space-item button:disabled:hover,
.encoder-action-btn .ant-space-item button[disabled]:hover {
  background: #2c2c2c;
  border: 1px solid #2a2a2a;
}
.encoder-action-btn .encoder-btn-loading {
  background: #6a29ff !important;
}
.encoder-action-label .ant-space-item {
  margin-top: 10px;
  width: 60px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
.encoder-panel-wrapper .ant-collapse {
  border: none;
  color: #ffffff;
  border-radius: 0px;
}
.encoder-panel-wrapper .ant-collapse:hover {
  background: #141414;
}
.encoder-panel-wrapper .ant-collapse .ant-collapse-header-text {
  color: #ffffff;
  font-weight: bold;
}
.encoder-panel-wrapper .ant-collapse .collapse-icon svg {
  color: #ffffff;
  font-size: 16px;
}
.encoder-panel-wrapper .ant-collapse > .ant-collapse-item {
  border: 2px solid #2a2a2a;
  background: #2c2c2c;
  margin: 5px 0;
  border-radius: 4px;
}
.encoder-panel-wrapper .ant-collapse .ant-collapse-content {
  background: #141414;
  border: none;
  color: #ffffff;
}
.encoder-panel-wrapper .ant-collapse .ant-radio-wrapper,
.encoder-device-group,
.encoder-stream-settings .ant-typography.ant-typography-secondary {
  color: #ffffff;
}
.encoder-device-item > .ant-col {
  font-size: 12px;
}
.encoder-stream-settings .ant-form-item-control-input-content {
  height: 32px;
  border-bottom: 1px solid #2a2a2a;
}
.encoder-stream-settings .ant-input,
.encoder-stream-settings .ant-select-selector {
  color: #ffffff !important;
  height: 32px;
  padding: 0;
}
.encoder-stream-settings .ant-select .ant-select-arrow {
  color: #ffffff;
  height: 20px;
  padding: 0;
}
.encoder-stream-settings .ant-select-single .ant-select-selector .ant-select-selection-item,
.encoder-stream-settings .ant-select,
.encoder-stream-settings .ant-select-selector {
  padding: 0;
  height: 32px;
  width: 100%;
}
.encoder-device-status-wrapper .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.encoder-device-status-wrapper .ant-collapse-content {
  border-radius: 0 !important;
}
.encoder-device-status-wrapper .ant-collapse-content > .ant-collapse-content-box .ant-list-header {
  padding: 0;
}
.encoder-device-details-header {
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 10px 5px;
  font-weight: bold;
}
.encoder-device-details-body {
  padding: 5px;
}
