/* Overall Stream List container */
.intermediate-container .intermediate-sidemenu .stream-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Container for all streams */
.intermediate-container .intermediate-sidemenu .stream-list .streams-container {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Individual stream item */
.intermediate-container .intermediate-sidemenu .stream-list .streams-container .stream-item {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
  visibility: hidden;
}

.intermediate-container .intermediate-sidemenu:hover .stream-list .streams-container .stream-item,
.intermediate-container .intermediate-container-open  .stream-list .streams-container .stream-item{
  visibility: visible;
}

/* .stream-item:hover {
    background-color: transparent;
} */

/* Stream icon styling */
/* .stream-icon { */
  /* width: 42px; */
  /* height: 42px; */
  /* visibility: visible !important; */
  /* opacity: 1 !important; */
/* } */

/* Stream label styling */
/* .stream-label {
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  flex: 1;
} */
