/* el viejoshowviewer */

.show-old-viewer {
  background: transparent url("../../Images/video_bg.png") 0% 0% no-repeat;
  height: calc(100vh - 56px);
  background-size: 100% calc(100vh - 56px);
  background-position: center;
}

.old-viewer-selector .chat-icon {
  background-image: url("../../Images/chat_icon.svg") !important;
  background-repeat: no-repeat;
  background-size: 20px 18px !important;
  background-position: 10px !important;
}

.old-viewer-selector .cross-icon {
  background-image: url("../../Images/cross_icon.svg") !important;
  background-repeat: no-repeat;
  background-size: 25px 13px !important;
  background-position: 10px !important;
  bottom: 5px;
}

.old-viewer-selector .ui.input>input {
  background: #3d3d3d;
  border: 1px solid white;
  color: white;
}

.old-viewer-selector .fa-2x {
  font-size: 2em;
}

.old-viewer-selector .fa {
  position: relative;
  display: table-cell;
  width: 56px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
}

.old-viewer-selector .leftBar:hover,
.old-viewer-selector nav.leftBar.expanded {
  width: 250px;
  overflow: visible;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.3) 194px, #000000 56px);
}

.old-viewer-selector .main-menu {
  background: #000000;
  position: absolute;
  top: -57px;
  bottom: 0;
  left: 0;
  width: 56px;
  overflow: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.old-viewer-selector .main-menu-right {
  background: #000000;
  position: fixed;
  top: 0px;
  bottom: 0;
  right: 0;
  float: right;
  width: 56px;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 900;
}

.old-viewer-selector .main-menu-right-chat {
  background: #1c1d1d;
  position: absolute;
  top: 56px;
  /* bottom: 142px; */
  right: 0;
  /* margin-right: 56px; */
  color: white;
  float: right;
  width: 344px;
  overflow-y: auto;
  -webkit-transition: width 0.05s linear;
  transition: all 0.5s;
  -webkit-transform: translateX(0%);
  z-index: 1001;
  max-height: calc(100vh - 231px);
  min-height: calc(100vh - 231px);
  display: flex;
  flex-direction: column;
  /* scrollbar-color: red; */
}

.old-viewer-selector .main-menu-right-chat::-webkit-scrollbar {
  height: 12px !important;
  width: 12px !important;
  background: #000 !important;
}

.old-viewer-selector .main-menu-right-chat::-webkit-scrollbar-thumb {
  background: #ffffffd1 !important;
  -webkit-border-radius: 1ex !important;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75) !important;
}

.old-viewer-selector .main-menu-right-chat::-webkit-scrollbar-corner {
  background: #000 !important;
}

.old-viewer-selector .main-menu-right-chat-users {
  background: black;
  position: absolute;
  top: 56px;
  /* bottom: 47px; */
  right: 0;
  margin-right: 0px;
  color: white;
  float: right;
  width: 344px;
  /* overflow: scroll; */
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
  padding-left: 20px;
}

.old-viewer-selector .chat-h1 {
  position: sticky;
  top: 0px;
  border-bottom: 1px solid darkgrey;
  height: 50px;
  width: 344px;
  background: #1c1d1d;
  z-index: 1;
}

.old-viewer-selector .chat-h2 {
  position: sticky;
  top: 50px;
  margin-top: 10px;
  height: 55px;
  width: 344px;
  background: #1c1d1d;
}

.old-viewer-selector .chat-text {
  font-size: 18px;
  margin-left: 23px;
  position: absolute;
  margin-top: 15px;
}

.old-viewer-selector .chat-content {
  padding-top: 10px;
  margin-left: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  /* min-height: calc(100vh - 393px); */
}

.old-viewer-selector .chat-list-item:first-child {
  margin-top: auto;
}

.old-viewer-selector .chat-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 5px 10px;
}

.old-viewer-selector .chat-image {
  background: transparent url("../../Images/logo.png") 0% 0% no-repeat padding-box;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background-size: cover !important;
  background-position: cover !important;
  border: 1px solid #fff;
  display: inline-table;
  min-width: 42px;
}

.old-viewer-selector .chat-message-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 12px;
  max-width: 250px;
}

.old-viewer-selector .chat-heading {
  margin: 0;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 300;
}

.old-viewer-selector .chat-message-grey-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  font-size: 13px;
  font-family: "IBMPlexSans-Regular";
  font-weight: 400;
  text-align: left;
  background: #454248;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}

.old-viewer-selector .annote-chat-message-grey-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 250px;
  line-height: 1.5;
  font-size: 13px;
  font-family: "IBMPlexSans-Regular";
  font-weight: 400;
  text-align: left;
  background: #454248;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}

.old-viewer-selector .annote-stream-label-grey-bg {
  word-break: break-word !important;
  white-space: pre-wrap !important;
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
  padding: 16px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: 200px !important;
  line-height: 1.5 !important;
  font-size: 13px !important;
  font-family: "IBMPlexSans-Regular" !important;
  font-weight: 400 !important;
  text-align: left !important;
  background: #454248 !important;
}

.old-viewer-selector .stream-label-edit-parent {
  display: flex;
}

.old-viewer-selector .stream-label-edit-box {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  margin-top: 3px;
  width: 125px;
}

.old-viewer-selector .chat-message-white-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  font-size: 13px;
  font-family: "IBMPlexSans-Regular";
  font-weight: 400;
  text-align: left;
  font-size: 15px;
  background: white;
  color: black;
  min-width: 250px;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}
.old-viewer-selector .chat-message-green-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  font-size: 13px;
  font-family: "IBMPlexSans-Regular";
  font-weight: 400;
  text-align: left;
  font-size: 15px;
  background: rgb(48, 97, 43);
  color: #f4f4f4;
  min-width: 250px;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}
.old-viewer-selector .chat-message-red-bg {
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  font-size: 13px;
  font-family: "IBMPlexSans-Regular";
  font-weight: 400;
  text-align: left;
  font-size: 15px;
  background: rgb(129, 12, 4);
  color: #f4f4f4;
  min-width: 250px;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}
.old-viewer-selector .chat-time {
  font-size: 10px;
}

.old-viewer-selector .chat-annote {
  font-size: 10px;
  float: right;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: purple;
  color: #000000;

}

.old-viewer-selector .chat-typing-indicator {
  padding-left: 5px;
  padding-bottom: 10px;
  font-size: 10px;
}

.old-viewer-selector .flex-prev {
  left: 0px;
  opacity: 1 !important;
  background: #000;
  height: 175px;
  bottom: 0px;
  width: 56px;
  background: transparent url("../../Images/tray-left-icon.svg") 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -999px;
  overflow: hidden;
  z-index: 1000;
  background-size: 20px 30px;
}

.old-viewer-selector .flex-next {
  right: 0px;
  opacity: 1 !important;
  background: #000;
  height: 175px;
  bottom: 0px;
  width: 56px;
  background: url("../../Images/tray-right-icon.svg") no-repeat, #000000; 
  background-position: center center, center center;
  text-indent: -999px;
  overflow: hidden;
  z-index: 1000;
  background-size:100px 50px, 100%;
}

.old-viewer-selector .tray-btns {
  position: fixed;
  cursor: pointer;
  border: none;
  outline: none;
}

.old-viewer-selector .chat-heading-user {
  margin-left: 20px;
  width: 155px;
  font-size: 15px;
  font-weight: 300;
  margin-top: 15px;
}

.old-viewer-selector .chat-message-user {
  margin-left: 20px;
  width: 155px;
  font-size: 12px;
  padding-top: 10px;
}

/* .profile_img11 {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: absolute;
  object-fit: fill;
  z-index: 1;
  margin-left: 20%;
  margin-top: 10px;
  box-shadow: 0px 0px 22px -10px #f4f4f4;
  -moz-box-shadow: 0px 0px 22px -10px #f4f4f4;
  -webkit-box-shadow:0px 0px 22px -10px #f4f4f4;
} */
.old-viewer-selector .profile_img11 {
  width: 98%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.old-viewer-selector .profile_name {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4f4f4;
  font-size: 30px;
  background-color: #141414;
  font-weight: bold;

}

.hide-profile {
  display: none;
}

.old-viewer-selector ul.chat-content li {
  height: 50px;
}

.old-viewer-selector li.main-bar a {
  cursor: pointer;
}

.old-viewer-selector .chat-msg-input {
  display: flex;
  left: 0px;
  right: 21px;
  bottom: 0px;
  margin: 0px;
  margin-top: 0;
  margin-bottom: 0px;
  align-items: flex-end;
  position: sticky;
  background-color: #141414;
}

.old-viewer-selector .chat-msg-input input[type="text"] {
  height: 35px;
  margin-top: 10px;
  margin-left: 5px;
}

.old-viewer-selector .annotation-chat-msg-input {
  display: flex;
  left: 0px;
  right: 21px;
  bottom: 40px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 10px;
  align-items: flex-end;
  position: sticky;
  background-color: #141414;
}

.old-viewer-selector .annotation-chat-msg-input input[type="text"] {
  height: 35px;
  margin-top: 10px;
  margin-left: 5px;
}

.old-viewer-selector .annotation-chat-btn {
  display: flex;
  left: 0px;
  bottom: 0px;
  margin: 0px;
  align-items: flex-end;
  position: sticky;
  background-color: #141414;
}

.old-viewer-selector .sendTxt {
  width: 330px;
}

.old-viewer-selector .editTxtArea {
  resize: none;
  border: none;
  border-radius: 10px;
  width: 250px;
  background: #454248;
  color: white;
  outline: none;
  line-height: 1.5;
  font-size: 13px;
  font-family: "IBMPlexSans-Regular";
  font-weight: 400;
  border-top-left-radius: 0;
  padding: 7px;
}

.old-viewer-selector .upload-btn {
  background: transparent url("../../Images/upload-icon.svg") 0% 0% no-repeat padding-box !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  padding: 10px !important;
  background-color: transparent !important;
  height: 20px;
  width: 20px;
  margin-top: 19px !important;
  margin-left: 5px;
  z-index: 111;
}

.old-viewer-selector .upload-btn:hover {
  cursor: pointer;
}

.old-viewer-selector .send-chat-btn {
  background: transparent url("../../Images/send-chat.svg") 0% 0% no-repeat padding-box !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 28px 34px !important;
  padding: 10px !important;
  background-color: #6435c9 !important;
  height: 33px;
  width: 29px;
  margin-top: 10px !important;
}

.old-viewer-selector .cancel-chat-btn {
  background: transparent url("../../Images/cancel-chat.svg") 0% 0% no-repeat padding-box !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 28px 34px !important;
  padding: 10px !important;
  background-color: #6435c9 !important;
  height: 33px;
  width: 29px;
  margin-top: 10px !important;
}

.old-viewer-selector .giphy-btn {
  background: transparent url("../../Images/giphy.svg") 0% 0% no-repeat padding-box !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 28px 34px !important;
  padding: 10px !important;
  background-color: transparent !important;
  height: 33px;
  width: 29px;
  margin-top: 10px !important;
  margin-right: 5px;
}

.old-viewer-selector .user-icon-text {
  color: white;
  cursor: pointer;
}

.old-viewer-selector .main-menu-right-close {
  display: none !important;
}

.old-viewer-selector .main-menu-right-show {
  display: table !important;
}

.old-viewer-selector .leftBar li,
.old-viewer-selector .main-menu-right li {
  position: relative;
  display: block;
  width: 250px;
  height: 48px;
}

.old-viewer-selector .leftBar li:first-child {
  position: relative;
  display: block;
  width: 246px;
  height: 48px;
  margin-top: 4px;
}

.old-viewer-selector .leftBar li>a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.old-viewer-selector .main-menu-right li>a {
  /* float: right; */
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.old-viewer-selector .leftBar .nav-text,
.old-viewer-selector .main-menu-right .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 250px;
  font-size: 18px;
  font-weight: 400;
  padding-left: 25px;
  font-family: "IBMPlexSans-Regular";
}

.old-viewer-selector .leftBar>ul.menu-bottom,
.old-viewer-selector .main-menu-right>ul.menu-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

.old-viewer-selector .menuBorder {
  margin: 15px auto 25px;
  width: calc(100% - 30px);
  background: #707070;
  height: 1px;
  border: 0 none;
  position: relative;
  z-index: 99;
}

.old-viewer-selector .no-touch .scrollable.hover {
  overflow-y: hidden;
}

.old-viewer-selector .no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

.old-viewer-selector a:hover,
.old-viewer-selector a:focus {
  text-decoration: none;
}

.old-viewer-selector nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.old-viewer-selector nav ul,
.old-viewer-selector nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.old-viewer-selector .leftBar li.items-list:hover>a,
.old-viewer-selector .main-menu-right li.items-list:hover>a,
.old-viewer-selector nav.leftBar li.active>a,
.old-viewer-selector .dropdown-menu>li>a:hover,
.old-viewer-selector .dropdown-menu>li>a:focus,
.old-viewer-selector .dropdown-menu>.active>a,
.old-viewer-selector .dropdown-menu>.active>a:hover,
.old-viewer-selector .dropdown-menu>.active>a:focus,
.old-viewer-selector .no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.old-viewer-selector .dashboard-page nav.dashboard-menu ul li.active a {
  color: #fff;
  background-color: #2d2935;
}

.old-viewer-selector .leftBar li.main-bar .fa:hover,
.old-viewer-selector .main-menu-right li.main-bar .fa:hover {
  /* background-color: #2d2935; */
  color: #fff;
}

.old-viewer-selector .leftBar:hover .x {
  display: inline !important;
}

.old-viewer-selector .leftBar:hover .bars {
  display: none !important;
}

.old-viewer-selector .leftBar li.items-list.selected {
  border-left: #9f5fff 3px solid;
}

.old-viewer-selector .leftBar li.items-list:hover {
  border-left: #9f5fff 3px solid;
}

.old-viewer-selector .viewerstream {
  margin-top: 0px;
  margin-left: 56px;
  background: black;
  height: calc(100vh - 56px);
  position: relative;
}

.old-viewer-selector .vieweraspectratio {
  padding-bottom: 62.5%;
  position: absolute;
}

.old-viewer-selector .viewcontainer {
  width: 100%;
  height: calc(100% - 225px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
}

.old-viewer-selector .viewcontainer .videoparticipant video {
  width: 100% !important;
}

.old-viewer-selector .viewerstream-video {
  margin: 0 auto;
  outline: none;
  height: 100%;
  width: calc(100% - 345px);
  position: absolute;
}

.old-viewer-selector .viewerstream-video-withChat {
  width: 77%;
  outline: none;
  height: 75vh;
  position: relative;
}

.old-viewer-selector .overlay-video {
  position: absolute;
  top: 4%;
  left: 43%;
  z-index: 1;
  font-family: "IBMPlexSans-Regular";
  font-size: 25px;
  padding: 5px;
  background: black;
  color: white;
}

.old-viewer-selector .videoTrayNames {
  margin-left: 50px;
  color: white;
  font-size: 15px;
  font-weight: 500;
}

.old-viewer-selector .ui.grid {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  overflow-x: scroll;
  white-space: nowrap;
  background: #000000;
  margin-top: 0px !important;
}

.old-viewer-selector .ui.grid>.row>.column>img,
.old-viewer-selector .ui.grid>.row>img {
  height: 95px !important;
  width: 205px !important;
}

.old-viewer-selector .videotray {
  /* background-color: #000000; */
  /* background: #000000; */
  display: flex;
  overflow-x: auto;
  margin-left: 56px;
  margin-right: 56px;
  bottom: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 175px;
}

.old-viewer-selector .videotray::-webkit-scrollbar {
  width: 100px;
  height: 5px;
}

.old-viewer-selector .videotray::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
}

.old-viewer-selector .videotray::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #707070;
  outline: 1px solid slategrey;
}

.old-viewer-selector .videotray::-webkit-scrollbar:vertical {
  display: none;
}

.old-viewer-selector .videotray:last-child {
  padding-right: 56px;
}

.old-viewer-selector .videoparticipant {
  position: relative;
  width: 244px;
  /* height: 165px; */
  margin: 5px;
  white-space: nowrap;
  z-index: 100;
}

.old-viewer-selector .videoparticipant:first-child {
  margin-left: 0px;
}

.old-viewer-selector .videoparticipant .audio-button {
  position: absolute;
  background-color: red;
  left: 2px;
  top: 3px;
  z-index: 101;
}

.old-viewer-selector .videoparticipant .video-button {
  position: absolute;
  background-color: red;
  left: 60px;
  top: 3px;
  z-index: 101;
}

.old-viewer-selector .bg-red {
  background-color: red !important;
}

.old-viewer-selector .bg-green {
  background-color: green !important;
}
.old-viewer-selector .prevent-textcopy{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.old-viewer-selector .videoparticipant .overlay-text {
  position: relative;
  color: #ffffff;
  z-index: 1;
  text-align: left;
  float: right;
  font-size: 1em !important;
  top: 80%;
  width: 135px;
  letter-spacing: 1px;
}

.old-viewer-selector .videoparticipant .overlay-text-red {
  position: relative;
  color: red;
  z-index: 1;
  text-align: left;
  float: right;
  font-size: 1em !important;
  top: 80%;
  width: 135px;
  letter-spacing: 1px;
  font-weight: 600;
}

.old-viewer-selector .videoparticipant .overlay-video-on-tray {
  position: relative;
  bottom: 0;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/video_on.svg") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.old-viewer-selector .videoparticipant .overlay-fullscreen-on-tray {
  position: relative;
  bottom: 0;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/fullscreen_on.svg") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.old-viewer-selector .videoparticipant .overlay-fullscreen-off-tray {
  position: relative;
  bottom: 0;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/fullscreen_off.svg") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}
.old-viewer-selector .overlay-fullscreen-off-tray:hover,.overlay-fullscreen-on-tray:hover{
  cursor: pointer;
}
.old-viewer-selector .videoparticipant .overlay-video-off-tray {
  position: relative;
  bottom: 3px;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/redvideo-off.svg") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
  background-position: center;
}
.old-viewer-selector .overlay-video-off-tray:hover, .overlay-video-on-tray:hover{
  cursor: pointer;
}
.old-viewer-selector .disabled-button{
  pointer-events: none;
}
.old-viewer-selector .videoparticipant .overlay-audio-on {
  position: relative;
  bottom: 0;
  z-index: 2000;
  margin-left: 5px;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/microphone.svg") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.old-viewer-selector .videoparticipant .overlay-audio-off {
  position: relative;
  bottom: 0;
  z-index: 2000;
  margin-left: 5px;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/redmic-off.svg") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}
.old-viewer-selector .overlay-audio-off:hover, .old-viewer-selector .overlay-audio-on:hover{
  cursor: pointer;
}

.old-viewer-selector .videoparticipant .menu-vertical {
  position: absolute;
  top: 13px;
  right: 0;
  margin-right: 5px;
  z-index: 2000;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/menu-vertical.svg") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: right;
  cursor: pointer;
}

.old-viewer-selector .videoparticipant .overlay-high-signal {
  position: relative;
  bottom: 0;
  z-index: 2000;
  margin-left: 5px;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/green-signal.png") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.old-viewer-selector .videoparticipant .overlay-medium-signal {
  position: relative;
  bottom: 0;
  z-index: 2000;
  margin-left: 5px;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/medium-strength.png") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.old-viewer-selector .videoparticipant .overlay-low-signal {
  position: relative;
  bottom: 0;
  z-index: 2000;
  margin-left: 5px;
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../Images/red-signal.png") 0% 0% no-repeat padding-box;
  background-size: 15px 15px;
  color: #cccccc;
  float: left;
  margin-left: 5px;
  top: 84%;
}

.old-viewer-selector .videoparticipant .overlay-text:first-child {
  margin-left: 5px;
}

.old-viewer-selector .videoparticipant video {
  outline: none;
  height: 100%;
  /* left: 0px; */
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
  width: 234px;
  object-fit: cover;
}

.old-viewer-selector .overlay-vtray {
  position: absolute;
  width: 242px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  height: 40px;
  bottom: 0;
  /* margin-left: 12px; */
  margin-left: 0px;
  padding-bottom: 25px;
}
.old-viewer-selector .overlay-participant-connected {
  position: absolute;
  width: 65%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-color: #5bb36f;
  border-color: #5bb36f;
  opacity: 0.6;
  z-index: 2002;
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}
.old-viewer-selector .overlay-participant-disconnected {
  position: absolute;
  width: 65%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-color: #e4404e;
  border-color: #e4404e;
  opacity: 0.6;
  z-index: 2002;
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}
  /*Header icons new css*/

.old-viewer-selector .centerMenuTop {
  margin-left: 18%;
}

.old-viewer-selector .centerMenuTop ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

.old-viewer-selector .centerMenuTop ul li {
  float: left;
  /* width: 56px; */
  /* width: 80px; */
  height: 56px;
  justify-content: center;
  display: inline-flex;
  align-content: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  padding: 15px;
  transition: 0.7s;
}

.old-viewer-selector .centerMenuTop ul li:hover {
  background-color: #454248;
}

.old-viewer-selector .centerMenuTop ul li span {
  height: 24px;
  background: #000;
  position: absolute;
  top: 0px;
  font-size: 14px;
  color: #fff;
  padding: 0 5px;
  text-transform: capitalize;
  line-height: 28px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  z-index: 10;
}

.old-viewer-selector .centerMenuTop ul li:hover span {
  bottom: -35px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
}

.old-viewer-selector .centerMenuTop ul li span::before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid black;
  content: "";
  top: -7px;
  position: absolute;
  left: 50%;
  margin-left: -5px;
}

.old-viewer-selector .centerMenuTop ul li a {
  height: 28px;
  position: absolute;
  padding: 0 25px;
  color: #fff;
}

.old-viewer-selector .centerMenuTop ul li a:hover {
  color: #b2b2b2;
}

.old-viewer-selector .dividervertical {
  height: 22px;
  background-color: #707070;
  width: 1px;
  float: left;
  margin-top: 16px;
}

.old-viewer-selector .menuTopRight {
  float: right;
  /* margin-right: 56px; */
}

.old-viewer-selector .menuTopRight ul {
  list-style-type: none;
  padding: 0px;
  margin: 0 auto;
}

.old-viewer-selector .menuTopRight ul li {
  float: left;
  width: 56px;
}

.old-viewer-selector .menuTopRight ul li a {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-bottom: 3px solid transparent;
  position: relative;
  margin-top: 3px solid transparent;
  cursor: pointer;
}

.old-viewer-selector .menuTopRight ul li a:hover,
.old-viewer-selector .menuTopRight ul li a.active {
  background-color: #454248;
  border-color: #6600ff;
}

.old-viewer-selector #invitePeoples img.closeinvite,
.old-viewer-selector #chatArea img.closeinvite,
.old-viewer-selector #annotationChatArea img.closeinvite,
.old-viewer-selector #roomCreatedcount img.closeinvite {
  display: none;
}

.old-viewer-selector #invitePeoples.active img,
.old-viewer-selector #chatArea.active img,
/* #annotationChatArea.active img, */
.old-viewer-selector #roomCreatedcount.active img {
  display: none;
}

.old-viewer-selector #roomCreatedcount.active .memberCount {
  display: none;
}

.old-viewer-selector #invitePeoples.active img.closeinvite,
.old-viewer-selector #chatArea.active img.closeinvite,
.old-viewer-selector #annotationChatArea.active img.closeinvite,
.old-viewer-selector #roomCreatedcount.active img.closeinvite {
  display: block;
}

.old-viewer-selector .memberCount {
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 3px;
  top: 2px;
  max-width: 14px;
  line-height: 15px;
}

/*leftmenu*/
/* .leftBar{
  background-color:rgba(0, 0, 0,0.8);
  width: 56px;
  position: absolute;
  left: 0;
  top: -38px;
  height: 105%;
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
} */
.old-viewer-selector .leftBar {
  background-color: rgba(0, 0, 0, 0.8);
  width: 56px;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0px;
  /* height: 598px; */
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}

.old-viewer-selector .leftBar:hover,
.old-viewer-selector .leftBar:active {
  width: 244px;
}

.old-viewer-selector .leftBar::before {
  width: 56px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #000;
}

.old-viewer-selector .left-bar-temp-width {
  width: 244px;
}

.old-viewer-selector .menuSlide {
  padding: 0;
  list-style-type: none;
  margin: 0 auto;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.old-viewer-selector .openSidebar {
  width: 244px;
}

.old-viewer-selector .menuSlide.hideMain {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.old-viewer-selector .menuSlide li {
  width: 244px;
  height: 48px;
  position: relative;
  cursor: pointer;
}

.old-viewer-selector .menuSlide li:first-child:hover {
  background-color: transparent;
}

.old-viewer-selector .menuSlide li:hover {
  background-color: #454248;
}

.old-viewer-selector .menuSlide li:active {
  background-color: #ccc;
}

.old-viewer-selector .menuSlide li:hover:after,
.old-viewer-selector .menuSlide li.active:after {
  width: 3px;
  height: 100%;
  background-color: #6600ff;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.old-viewer-selector .menuSlide li a {
  float: left;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  font-weight: normal;
  text-decoration: none;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.old-viewer-selector .menuSlide li i {
  width: 56px;
  height: 48px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}

.old-viewer-selector .menuSlide li span {
  padding-left: 10px;
}

.old-viewer-selector .slideMenuRight {
  position: fixed;
  right: -100%;
  height: 100vh;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.old-viewer-selector .slideMenuRight.showSub {
  right: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.old-viewer-selector .slideMenuRight.showSub .menuSlide {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.old-viewer-selector .menuBorder.innerArea {
  margin-bottom: 0;
  margin-top: 20px;
}

.old-viewer-selector .menuSlide span.areaTitle {
  color: #fff;
  font-size: 14px;
  width: calc(100% - 30px);
  display: block;
  padding: 5px 3px 0;
  line-height: 1;
  margin-bottom: 15px;
  position: relative;
}

.old-viewer-selector .stream-name-labels {
  height: 55vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.old-viewer-selector .menuBorder.innerArea.bottom15 {
  margin-bottom: 15px;
}

.old-viewer-selector .slider {
  position: absolute;
  z-index: 99;
  padding: 0px 50px;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
}

.old-viewer-selector .slider .flexslider.carousel {
  margin: 0 auto;
  background: #333333;
  border-color: #333;
  border-top-width: 10px;
  border-radius: 0;
}

.old-viewer-selector .slider .flex-direction-nav .flex-prev {
  left: -54px !important;
  opacity: 1 !important;
  background: #000;
  height: 165px;
  top: 10px;
  width: 56px;
  /*background-image: url(../images/chevron-left_big.svg);*/
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -999px;
  overflow: hidden;
}

.old-viewer-selector .slider .flex-direction-nav .flex-next {
  right: -54px !important;
  opacity: 1 !important;
  background: #000;
  height: 165px;
  top: 10px;
  width: 56px;
  /*background-image: url(../images/chevron-right_big.svg);*/
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: 1500px;
  overflow: hidden;
  background-size: 44px;
}

.old-viewer-selector .slider .flex-direction-nav .flex-prev::before,
.old-viewer-selector .slider .flex-direction-nav .flex-next::before {
  display: none;
}

.old-viewer-selector .slider .flexslider .slides>li {
  position: relative;
}

.old-viewer-selector .guestlist .listPeoples li {
  padding: 13px 40px;
  position: relative;
}

.old-viewer-selector .guestlist .listPeoples li .posAdmin {
  border: 0 none;
  background: none;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  margin-top: -5px;
}

.old-viewer-selector .invitePeopleHeadingLarge {
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  padding: 0px 40px;
  margin-bottom: 15px;
}

.old-viewer-selector span.subHeading {
  color: #fff;
  padding: 0px 40px;
  margin: 10px auto 40px;
  display: block;
}

.old-viewer-selector .guestlist .listPeoples li .namePeople {
  margin-left: 0px;
}

.old-viewer-selector .popDesign {
  background: #454248 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  width: 224px;
  height: 100px;
  position: absolute;
  top: 30px;
  right: 15px;
  padding: 10px 25px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
}

.old-viewer-selector .popDesign.active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
}

.old-viewer-selector .popDesign .closeBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.old-viewer-selector .popDesign ul {
  padding: 0;
}

.old-viewer-selector .guestlist .listPeoples .popDesign ul li {
  padding: 0;
}

.old-viewer-selector .popDesign ul a {
  color: #b2b2b2;
  text-decoration: none;
  font-size: 15px;
  line-height: 25px;
}

.old-viewer-selector .popDesign ul a:hover {
  color: #fff;
}

.old-viewer-selector .chatForm {
  padding: 0px 15px;
  width: 100%;
  margin-top: 0px;
  float: left;
  position: relative;
}

.old-viewer-selector .chatForm .chatForm {
  padding: 0;
  margin-top: 0;
}

.old-viewer-selector .chatForm input[type="text"] {
  width: 100%;
  height: 46px;
  background: #333;
  border: 1px solid #fff;
  margin-bottom: 10px;
  color: #fff;
  padding: 0 15px;
  font-size: 15px;
}

.old-viewer-selector .chatForm button.smileyBtn {
  position: absolute;
  background: none;
  border: 0 none;
  right: 10px;
  top: 12px;
  cursor: pointer;
}

.old-viewer-selector .chatForm input[type="submit"] {
  background: #6600ff;
  color: #fff;
  border: 0px none;
  font-size: 14px;
  height: 36px;
  float: right;
  padding: 0px 20px;
  cursor: pointer;
}

.old-viewer-selector .hide {
  display: none;
}

.old-viewer-selector .ui.popup {
  /* padding: 0px !important; */
  border-radius: 0px;
}

.old-viewer-selector .ui.popup>.ui.grid:not(.padded) {
  width: 100%;
}

.old-viewer-selector .ui.grid {
  margin: 0px !important;
}

.old-viewer-selector .ui.bottom.popup:before {
  background: #000 !important;
}

.old-viewer-selector .ui.bottom.popup {
  margin: 1.3em;
}

.old-viewer-selector .tc_image {
  width: 25px;
  height: 25px;
  margin-top: 15px;
}

.old-viewer-selector .download_tc {
  position: absolute;
  right: 20px;
}

.old-viewer-selector .cursor-pointer {
  cursor: pointer;
}

.old-viewer-selector .editPencil-img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.old-viewer-selector .editPencil-padding {
  /* padding-left: 75px !important; */
}

.old-viewer-selector .greenTick-img,
.old-viewer-selector .cancelTick-img {
  width: 27px;
  height: 27px;
}

.old-viewer-selector .stream-label-submit {
  background: none;
  width: 27px;
  height: 27px;
  border: none;
  margin: 0;
  padding: 0;
}

.old-viewer-selector .stream-label-submit:disabled {
  opacity: 0.2;
}

.old-viewer-selector .stream-label-helper-text {
  font-size: 10px;
  display: flex;
  font-weight: bold;
}

.old-viewer-selector .stream-label-error-text {
  color: red;
  font-size: 10px;
  display: flex;
  font-weight: bold;
}

.old-viewer-selector .float-right {
  float: right;
}

.old-viewer-selector .annotation-chat-btn .ui.button {
  padding: 5px 10px !important;
  line-height: 35px !important;
}

.old-viewer-selector .chat-settings {
  position: relative;
  float: right;
  display: block;
  height: 30px;
  width: 30px;
  opacity: 1;
  z-index: 1002;
  margin: 15px 15px 0 0;
}

.old-viewer-selector .clear-chat-button {
  position: relative;
  float: right;
  display: block;
  opacity: 1;
  z-index: 1002;
  margin: 15px 15px 0 0;
  cursor: pointer;
}

.old-viewer-selector .export-chat-img {
  height: 20px;
  margin: 15px 10px 0 0;
  width: 20px;
}

.old-viewer-selector .chat-settings .clear-image {
  position: relative;
  top: 4px;
}

.old-viewer-selector .annotation-chat-footer {
  position: sticky;
  bottom: 0;
  background-color: #141414;
}


.old-viewer-selector .publisher-panel-content {
  padding-top: 10px;
  /* margin-left: 10px; */
  padding-bottom: 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: calc(100vh - 393px);
  overflow-y: scroll;
}

.old-viewer-selector .publisher-panel-item {
  padding: 10px 20px;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
}

.old-viewer-selector .publisher-panel-image {
  width: 20%;
}

.old-viewer-selector .publisher-panel-user {
  width: 40%;
}

.old-viewer-selector .publisher-panel-action {
  width: 20%;
  display: flex;
  justify-content: center;
}

.old-viewer-selector .publisher-panel-status {
  width: 20%;
  display: flex;
  justify-content: center;
}

.old-viewer-selector .publisher-panel-status-circle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  /* padding: 8px; */
  background: #fff;
  display: inline-block;
}

.old-viewer-selector .publisher-actions {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

.old-viewer-selector #publisher-panel-tray .ui.secondary.pointing.menu .item {
  color: #ffffff;
}

.old-viewer-selector #publisher-panel-tray .ui.secondary.pointing.menu .active.item {
  color: #ffffff;
  border-color: #ffffff;
}

.old-viewer-selector #publisher-panel-tray .ui.tab.active,
.old-viewer-selector .ui.tab.open {
  background: #1c1d1d;
}

.old-viewer-selector #publisher-panel-tray .ui.segment {
  padding: 0 !important;
}

.old-viewer-selector #publisher-panel-tray .chat-h1 {
  position: unset !important;
}

.old-viewer-selector .publisher-panel-action-button {
  background: gray;
  border-radius: 5px;
  padding: 5px;
  border: none;
}

.old-viewer-selector .publisher-panel-tab-button {
  border: none;
  background: none;
  color: #ffffff;
  min-width: 100px;
  line-height: 40px;
  outline: none;
  cursor: pointer;
}

.old-viewer-selector .publisher-panel-tab-button.active {
  border-bottom: 2px solid #8A2BE2;
}

.old-viewer-selector #guest-invite-tray .chat-h1 {
  display: flex;
}

.old-viewer-selector #guest-invite-tray .guest-invite-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 10px;
}

.old-viewer-selector #guest-invite-tray .guest-invite-header .chat-text {
  flex-grow: 1;
  margin: unset;
  position: unset;
  display: flex;
  justify-content: center;
}

.old-viewer-selector #guest-invite-tray .guest-invite-content {
  min-height: calc(100vh - 293px);
}

.old-viewer-selector .guest-invite-action-button {
  background: #8A2BE2;
  border-radius: 5px;
  padding: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.old-viewer-selector .guest-invite-modal-trigger {
  font-size: 25px;
}

.old-viewer-selector .guest-invite-modal-trigger:hover {
  text-decoration-color: #8A2BE2 !important;
  text-decoration: underline;
  cursor: pointer;
}

.old-viewer-selector .guest-invite-modal-header {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.old-viewer-selector .guest-invite-modal-content {
  background-color: #1c1d1d !important;
  color: #ffffff;
  margin: 10px 30px;
}

.old-viewer-selector .guest-invite-modal-content .header {
  background-color: #1c1d1d;
}

.old-viewer-selector .guest-invite-modal-content .publisher-actions {
  justify-content: space-around;
}

.old-viewer-selector .guest-invite-modal-content .form-input-error-msg {
  color: red;
}

.old-viewer-selector .ui.dimmer {
  z-index: 1002;
}

.old-viewer-selector .guest-user-info {
  margin: 1em 0;
}

.old-viewer-selector .guest-invite-form .form-field {
  margin: 1em 0;
}

.old-viewer-selector .guest-invite-form .form-input-label {
  display: block;
  margin: 0 0 1em 0;
}

.old-viewer-selector .guest-invite-form .form-input {
  width: 100%;
  color: #ffffff;
  background-color: black;
  box-shadow: unset !important;
}

.old-viewer-selector .guest-invite-form input[type="text"],
input[type="number"] {
  background-color: black !important;
  color: #ffffff !important;
}

.old-viewer-selector .guest-invite-form .expiryTime .form-input-label {
  display: inline;
}

.old-viewer-selector .guest-invite-form .expiryTime .form-input {
  width: 50px;
}

.old-viewer-selector .guest-invite-content .delete_image {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.old-viewer-selector .guest-invite-content .delete_image.disabled {
  cursor: unset;
}

.old-viewer-selector input::-webkit-outer-spin-button,
.old-viewer-selector input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.old-viewer-selector input[type=number] {
  -moz-appearance: textfield;
}

.old-viewer-selector .emoji-img {
  height: 30px;
  width: 30px;
  margin-top: 12px !important;
  z-index: 111;
}

.old-viewer-selector .emoji-mart {
  max-width: 100% !important;
}

.old-viewer-selector .chat-message-body .ui.card {
  margin: 0 !important;
  width: 250px;
}

.old-viewer-selector .file-message-grey-bg .ui.card {
  background-color: #454248;
}

.old-viewer-selector .file-message-white-bg .ui.card {
  background-color: white;
}

.old-viewer-selector .file-message-grey-bg .file-name {
  color: white;
}

.old-viewer-selector .file-message-white-bg .file-name {
  color: black;
}

.old-viewer-selector .emoji-img {
  cursor: pointer;
}

.old-viewer-selector .download-file-icon {
  cursor: pointer;
}

.old-viewer-selector .chat-message-body .duplicate-icon {
  float: right !important;
  background: transparent url("../../Images/copy_white.svg") no-repeat center;
  height: 20px;
  width: 20px;
}

.old-viewer-selector .dnd-icon {
  width: 16px;
  height: 16px;
  margin-top: 0px !important;
}
.old-viewer-selector .diagnostics-icon {
  width: 16px;
  height: 16px;
  margin-top: 0px !important;
}

.old-viewer-selector .d-none {
  display: none;
}

.old-viewer-selector .dominant-speaker-view {
  margin: 0 auto;
  outline: none;
  height: 100%;
  width: calc(100% - 290px);
  position: absolute;
}

.old-viewer-selector .dominant-speaker-icon {
  height: 16px;
  width: 16px;
  margin: 0 !important;
  margin-right: 12px !important;
}

.old-viewer-selector .annotate-color-container {
  display: flex;
}

.old-viewer-selector .annotate-color-radio {
  margin: 0 3px;
}

.old-viewer-selector .annotate-color-label {
  width: 18px;
  height: 18px;
  position: relative;
  border: 1px solid #D5DADE;
  transition: all 0.4s ease;
  border-radius: 50%;
  padding: 2px;
  background-clip: content-box !important;
  display: block;
}

.old-viewer-selector .annotate-color-radio input:checked+label {
  border-color: #4898D4;
  border-width: 2px;
}

.old-viewer-selector .annotation-chat-color-btn {
  margin-left: 10px;
  border: none;
  padding: 5px 10px;
  line-height: 35px;
  cursor: pointer;
  border-radius: 50%;
}

.old-viewer-selector .hidden {
  visibility: hidden;
}

@media screen and (max-width: 995px),
screen and (max-height: 700px) {
  .old-viewer-selector .videotray {
    /* bottom: -8%; */
    height: 26vh;
  }

  .old-viewer-selector .show-viewer {
    height: 92vh;
  }
  
}
@media only screen and (max-width: 768px) {
  .old-viewer-selector .centerMenuTop ul li, .old-viewer-selector .menuTopRight ul li {
    width: 50px;
  }
}

.old-viewer-selector .fullscreen-img {
  width: 24px;
}

.old-viewer-selector .dnd-img {
  width: 24px;
}

.old-viewer-selector .timeCodePopup>.actions>:first-child {
  display: none;
}

.background-selector-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.background-selector {
  border: 2px solid #ffffff;
  display: flex;
  margin: 5px;
}

.background-selector:hover {
  border: 2px solid blue;
}
.background-selector.active {
  border: 2px solid rgb(9, 255, 0);
}

.background-selector img {
  max-height: 120px !important;
  width: 150px !important;
}
