.zoom-viewer .tray-btns {
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d1d1d;
  width: 44px;
  height: 201px;
  z-index: 101;
}

.zoom-viewer .videoTrayNames {
  margin-left: 50px;
  color: white;
  font-size: 15px;
  font-weight: 500;
}

.zoom-viewer .video-tiles-row-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  height: 200px;
  justify-content: space-between;
  bottom: 0px;
  transition: bottom 1s;
  position: absolute;
  left: 0;
}
.zoom-viewer .video-tiles-row-wrapper-collapse {
  display: flex;
  align-items: center;
  height: 200px;
  justify-content: space-between;
  bottom: 0px;
  transition: bottom 1s;
  position: absolute;
  left: 0;
  width: calc(100vw - 360px);
}
.zoom-viewer .video-tiles-row-wrapper.collapse-tray {
  bottom: -200px;
}
.zoom-viewer .video-tiles-row-wrapper-collapse.collapse-tray {
  bottom: -200px;
}
.zoom-viewer .empty-video-tiles-placeholder {
  background-color: #000;
  height: 200px;
  position: relative;
  width: 100vw;
}
.zoom-viewer .video-tiles-row-wrapper.inverse-layout.collapse-tray {
  top: -201px;
}

.zoom-viewer .video-tiles-row-wrapper-collapse.inverse-layout.collapse-tray {
  top: -201px;
}

.zoom-viewer .video-tiles-row-wrapper.inverse-layout {
  top: 0px;
  transition: top 1s;
  z-index: 10;
  position: relative !important;
  left: 0;
  margin-left: 90px;
  width: calc(100% - 90px);
}
.zoom-viewer .video-tiles-row-wrapper-collapse.inverse-layout {
  width: 100% !important;
  margin-left: 0 !important;
  top: 0px;
  transition: top 1s;
  z-index: 10;
  position: relative !important;
  left: 0;
  width: 100% !important;
}
.zoom-viewer .videotray {
  background-color: #141414;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  padding: 16px 60px;
  width: 100%;
  height: 100%;
  gap: 16px;
  position: absolute;
  z-index: 100;
}

.zoom-viewer .videotray::-webkit-scrollbar {
  width: 100px;
  height: 5px;
}

.zoom-viewer .videotray::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
}

.zoom-viewer .videotray::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #707070;
  outline: 1px solid slategrey;
}

.zoom-viewer .videotray::-webkit-scrollbar:vertical {
  display: none;
}

.zoom-viewer .videotray:last-child {
  padding-right: 56px;
}

.zoom-viewer .videoparticipant {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  background-color: #141414;
  border-radius: 8px;
  border: 4px solid #1d1d1d;
  max-width: 240px;
}

.zoom-viewer .videoparticipant:first-child {
  margin-left: 0px;
}

.zoom-viewer .videoparticipant .audio-button {
  background-color: red;
}

.zoom-viewer .videoparticipant .video-button {
  background-color: red;
}

.zoom-viewer .bg-red {
  background-color: red !important;
}

.zoom-viewer .bg-green {
  background-color: green !important;
}
.zoom-viewer .prevent-textcopy{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.zoom-viewer .videoparticipant .overlay-text {
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0 8px 0 0;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.zoom-viewer .video-cell .overlay-text-red {
  color: #fff;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: normal;
  margin: 0 8px 0 0;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zoom-viewer .backup-name {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: 'Nunito Sans', sans-serif !important;
  padding-bottom: 30px;
  color: white;
  position: absolute;
  top: 0;
  display: flex;
  z-index: 4;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0px;
  justify-content: center;
  border-radius: 4px;
  background-color: #141414;
}

// .zoom-viewer .videoparticipant .overlay-fullscreen-on-tray {
//   position: relative;
//   bottom: 0;
//   z-index: 2000;
//   height: 15px;
//   font-size: 1em !important;
//   background: transparent url("./Images/fullscreen_on.svg") 0% 0% no-repeat padding-box;
//   background-size: 15px 15px;
//   color: #cccccc;
//   float: left;
//   margin-left: 5px;
//   top: 84%;
// }

// .zoom-viewer .videoparticipant .overlay-fullscreen-off-tray {
//   display: none;
//   position: relative;
//   bottom: 0;
//   z-index: 2000;
//   height: 15px;
//   font-size: 1em !important;
//   background: transparent url("./Images/fullscreen_off.svg") 0% 0% no-repeat padding-box;
//   background-size: 15px 15px;
//   color: #cccccc;
//   float: left;
//   margin-left: 5px;
//   top: 84%;
// }
// .zoom-viewer .overlay-fullscreen-off-tray:hover,.overlay-fullscreen-on-tray:hover{
//   cursor: pointer;
// }

.zoom-viewer .overlay-video-off-tray:hover, .overlay-video-on-tray:hover{
  cursor: pointer;
}
.zoom-viewer .disabled-button{
  pointer-events: none;
}

// .zoom-viewer .overlay-audio-off:hover,.overlay-audio-on:hover{
//   cursor: pointer;
// }

// .zoom-viewer .videoparticipant .menu-vertical {
//   position: absolute;
//   top: 13px;
//   right: 0;
//   margin-right: 5px;
//   z-index: 2000;
//   height: 15px;
//   font-size: 1em !important;
//   background: transparent url("./Images/menu-vertical.svg") 0% 0% no-repeat padding-box;
//   background-size: 15px 15px;
//   color: #cccccc;
//   float: right;
//   cursor: pointer;
// }

// .zoom-viewer .videoparticipant .overlay-signal {
//   position: absolute;
//   z-index: 2;
//   top: 0px;
//   right: 0px;
//   background-size: 16px 16px;
//   height: 16px;
//   color: #ccc;

// }
// .zoom-viewer .videoparticipant .overlay-high-signal {
//   background: transparent url("./Images/green-signal.png") 0% 0% no-repeat padding-box;
// }

// .zoom-viewer .videoparticipant .overlay-medium-signal {
//   background: transparent url("./Images/medium-strength.png") 0% 0% no-repeat padding-box;
// }

// .zoom-viewer .videoparticipant .overlay-low-signal {
//   background: transparent url("./Images/red-signal.png") 0% 0% no-repeat padding-box;
// }

.zoom-viewer .videoparticipant video {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  object-fit: cover;
}

.zoom-viewer .dominant-videoparticipant video {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  object-fit: cover;
}

.zoom-viewer .overlay-vtray {
  position: absolute;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  padding: 12px;
  border-radius: 0 0 4px 4px;
  bottom: 0;
}

.zoom-viewer .expand-collapse-btn {
  width: 40px;
  height: 42px;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #FFFFFF;
  background-color: #1d1d1d;
  cursor: pointer;
  z-index: 101;
}

.zoom-viewer .expand-collapse-btn-inverse {
  margin-top: 0px;
  top: 0;
  right: 0;
}

.zoom-viewer .expand-collapse-btn .expand-collapse-icon {
  width: 20px;
  height: 20px;
}
.zoom-viewer .video-tray-collapse-bg {
  width: 100%;
  background-color: #000;
  height: 200px;
  position: absolute;
  bottom: 0;
}

.zoom-viewer .video-tray-collapse-bg.inverse-layout {
  position: absolute;
  top: 0;
}

.zoom-viewer .video-container {
  // width: 100%;
  height: 100%;
}

.zoom-viewer .video-container .video-container-wrap {
  width: 100%;
  display: flex;
  height: 100%; 
  gap: 5px;
  background-color: #141414;
}

.zoom-viewer .video-container .video-container-wrap .user-list {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  background-color:#141414;
  gap: 16px;
}

.zoom-viewer .video-container .video-container-wrap .user-list li {
  width: 200px;
  min-width: 200px;
  height: 100%;
  border: 4px solid #1d1d1d;
    border-radius: 8px;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-player-div {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video-tile {
  aspect-ratio: 16/9;
  width: 200px;
  height: auto;
}

.zoom-meeting-full-screen{
  .video-tile:first-child{
    height: 100vh !important;
    width: 100vw !important;

    .player-container{
      height: 100vh !important;
      width: 100vw !important;
    }

  }
  video#zoom-meeting-screen-share-video{
    height: 100vh !important;
    width: 100vw !important;
  }
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .zoom-avatar {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .zoom-avatar .center-name {
  align-items: center;
  background-color: #141414;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-family: Nunito Sans, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding-bottom: 30px;
  position: absolute;
  top: 0;
  width: 100%;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-controls {
  position: absolute;
  color: #FFF;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  padding: 12px;
  width: 192px;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  z-index: 4;
  align-items: center;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-controls .overlay-audio-off {
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../../Images/redmic-off.svg") 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  cursor: pointer;
  margin-left:5px;
  bottom: 0;
  float: left;
  position: relative;
  top: 84%;
  z-index: 2000;
}
.marker {
    color: #1d1d1d;
    padding: 10px;
}
.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-controls .overlay-audio-on {
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../../Images/microphone.svg") 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  cursor: pointer;
  bottom: 0;
  float: left;
  margin-left: 5px;
  position: relative;
  top: 84%;
  z-index: 2000;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-controls .fullscreen-on {
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../../Images/fullscreen_on.svg") 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  cursor: pointer; 
  bottom: 0;
  float: left;
  margin-left: 5px;
  position: relative;
  top: 84%;
  z-index: 2000;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-controls .fullscreen-off {
  visibility: hidden;
  margin:0 5px 0 5px;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-controls .virtual-bg-menu {
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../../Images/menu-vertical.svg") 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  cursor: pointer; 
  bottom: 0;
  float: right;
  margin-left: 5px;
  position: relative;
  top: 84%;
  z-index: 2000;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-controls .overlay-video-on-tray {
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../../Images/video_on.svg") 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  bottom: 0;
  float: left;
  margin-left: 5px;
  position: relative;
  top: 84%;
  z-index: 2000;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .video-controls .overlay-video-off-tray {
  height: 15px;
  font-size: 1em !important;
  background: transparent url("../../../Images/redvideo-off.svg") 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  bottom: 0;
  float: left;
  margin-left: 5px;
  position: relative;
  top: 84%;
  z-index: 2000;
}

video-player-container {
  width: 100%;
  height: 100%;
  display: flex !important;
  gap:16px;
}

video-player {
  width: 100%;
  height: 100%;
  aspect-ratio: 6/5;
  > div{
    width: 100%;
    height: 167px;
  }
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .screen-share-video {
  width: 100%;
  height: auto;
  position: absolute;
  margin-top: 15px;
  z-index: 1000;
}

.zoom-viewer .video-container .video-container-wrap .user-list .video-cell .screen-share-canvas {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  margin-top: 15px;
  z-index: 1000;
}

.full-screen-player{
  // display:flex;
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
}

.background-selector-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.background-selector {
  border: 2px solid #ffffff;
  display: flex;
  margin: 5px;
}

.background-selector:hover {
  border: 2px solid blue;
}

.background-selector.active {
  border: 2px solid rgb(9, 255, 0);
}

.background-selector img {
  max-height: 120px !important;
  width: 150px !important;
}

/* Media query for portrait orientation */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (not (max-width: 767px)) {
  .zoom-viewer .video-tiles-row-wrapper {
    position: fixed;
    z-index: 100;
  }

  .zoom-viewer .expand-collapse-btn {
    position: fixed;
  }

  .zoom-viewer .expand-collapse-btn-inverse {
    margin-top: 0px;
    top: 150px;
    position: absolute;
  }

  .zoom-viewer .video-container{
    padding: 30px;
  }

  .video-container.video-container-attech{
    padding: 0px;
  }
  .zoom-viewer .video-tiles-row-wrapper.inverse-layout{
    top: 150px;
    width: calc(100% - 90px);

  }
  .zoom-video-tray{
    background-color: black;
  }
}
