.zoom-meeting-layout-option-wrapper {
  /* height: 81px; */
  width: 300px;
  border-radius: 20px !important;
  background-color: #393939 !important;
  width: 245px;
  height: 168px;
  opacity: 1;
  z-index: 1000 !important;
  top: 5px !important;
  box-shadow: 4px 4px 20px 0px #00000080;
}
.zoom-meeting-layout-options {
  color: #fff !important;
  position: relative;
  top: 8px;
  left: 5px;
}
.layout-option-button {
  position: relative;
  right: 8px;
  top: 5px;
}
.layout-option-button:hover {
  background-color: #4C4C4C !important;
}
.gallery-custom-divider {
  width: 210px;
  height: 1px;
  position: relative;
  top: 5px;
  right: 12px;
  margin: 8px 8px 5px 5px;
  background: #ffffff26;
  opacity: 1;
  z-index: 1000 !important;
}
.zoom-meeting-layout-option-container .option-item {
  color: white;
  display: flex;
  align-items: center;
  /* gap: 12px; */
  height: 24px;
  color: white;
  /* height: 24px; */
  cursor: pointer;
}

.zoom-meeting-layout-option-container .ant-popover-inner {
  background-color: #393939 !important;
  padding: 10px;
  border-radius: 20px;
}

.zoom-meeting-layout-option-container hr {
  border: 0.2px solid #ffffff26;
  margin: 1rem 0;
}
