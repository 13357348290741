.reports {
    margin-left: 40px;
    /* font-weight: bold; */
}
.email-form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    max-width: 300px;
}
.email-form-input-label {
    /* font-weight: bold; */
    font-size: 18px;
    margin-bottom: 0.5rem;
} 
.form-input {
    padding: 0.5rem;
    font-size: 1rem;
    font-family: "IBMPlexSans-Bold";
    margin-bottom: 0.5rem;
}
  .reports-text {
    text-align: left;
    font-family: "IBMPlexSans-Bold";
    font-size: 20px;
    line-height: 52px;
    letter-spacing: 1.72px;
    color: #000000;
    margin-top: 20px;
    opacity: 1;
  }
.err-msg {
    color: red;
    font-family: "IBMPlexSans-Regular";
    font-size: 13px;
    opacity: 1;
}
 .submit-button {
    font-weight: 500;
    font-family: "IBMPlexSans-Bold";
    font-size: 16px;
    color: #fff;
    background-color: #0096db;
    border-radius: 5px;
    border-width: 0px;
    padding: .3rem .5rem;
    transition: background-color .2s ease-out;
    outline: none;
    margin: 10px;
  }
.back-button{
  position: relative;
  top: 20px;
}
th {
  color: #fff !important;
  white-space: nowrap;
}
.expanded-box-wrapper th {
  background: #778899 !important;
}
.associated-shows {
  display: flex;
  justify-content: 'space-between';
  font-size: 20px;
  font-family: 'IBMPlexSans-Regular';
  /* margin-top: 50px; */
}
.shows-table {
  width: 98%;
  position: relative;
  /* bottom: 35px; */
}
.associated-streams {
  display: flex;
  font-size: 20px;
  font-family: 'IBMPlexSans-Regular';
  justify-content: 'space-between';
  /* margin-top: 20px; */
}
.streams-table {
  width: 98%;
  position: relative;
  /* bottom: 35px; */
}
.user-details-heading {
  position: relative;
  font-family: 'IBMPlexSans-Regular';
  font-size: 20px;
  left: 600px;
  bottom: 170px;
}
.user-details-box {
  position: relative;
  left: 600px;
  bottom: 160px;
  margin-top: 1rem;
  font-size: 16px;
  font-family: Nunito Sans, sans-serif !important;
  box-sizing: border-box;
  outline: none;
  padding: 1rem;
  width: calc(25% - 0rem);
  border: 1px solid #c4c8d8;
  box-shadow: 0 2px 5px 0 #e3e5ec;
}

.table-bordered .ant-table,
.table-bordered .ant-table-thead > tr > th,
.table-bordered .ant-table-tbody > tr > td {
  border: 1px solid #ddd;
}

.table-bordered .ant-table-thead > tr > th {
  background-color: #f5f5f5;
}

.table-bordered .ant-table-tbody > tr > td {
  background-color: #fff;
}

.table-bordered .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f5f5f5;
}
:where(.css-dev-only-do-not-override-1km3mtt).ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
  border-start-start-radius: 8px;
  background-color: #262131 !important;
}
:where(.css-dev-only-do-not-override-1km3mtt).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-1km3mtt).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  background-color: transparent;
}
:where(.css-dev-only-do-not-override-sqaf96).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-sqaf96).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  width: 0px !important;
}