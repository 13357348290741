.show-new-viewer {
  .dominant-participant {
    width: 100%;
    height: 100%;
    background-color: #141414;
    position:absolute;
    // border-radius: 8px;
    margin: auto;
    border: 4px solid #1d1d1d;
    color: #fff;
    transition: transform 0.3s ease, background-color 0.3s ease;
    // &:hover {
    //   transform: scale(1.05);
    //   background-color: #1a1a1a;
    // }

    .video-container {
      video {
        width: 100%;
      }
    }
  }
}
