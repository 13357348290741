.meeting-room
  .zoom-meeting-content
  .zoom-meeting-content-wrapper
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile {
  display: flex;
  flex-direction: column;
  /* padding: 5px 5px 0px 5px; */
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  gap:0px;
  position: relative;
}

.zoom-meeting-content{
  display: grid;
  grid-template-rows: 100% auto;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .gallery-mini
  .video-tile-container
  .video-tile
  .player-container {
  width: 90%;
  height: calc(100% - 40px); 
  /* Assuming video-tile has a defined height */
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .player-container {
  width: 200px;
  height: 200px;
  /* height: calc(100% - 40px);  */
  /* Assuming video-tile has a defined height */
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .zoom-avatar {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 120px;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .zoom-avatar
  .center-name {
  align-items: center;
  background-color: #141414;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-family: Nunito Sans, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 100%;
  justify-content: center;
  margin: 0;
  /* padding-bottom: 30px; */
  position: absolute;
  top: 0;
  width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meeting-room .video-tray .video-tray-wrapper .video-tile-wrapper .video-tile-container .video-tile .video-controls{
  padding-bottom: 10px;
}
