.diagnostics-new-modal {
    width: min-content !important;

    .ant-modal-content {
        background-color: black;
        color: white;
        border: 0.5px solid #242222;

        .anticon {
            color: white;
        }
    }
}

.diagnostic-new-container {
    margin-top: 2rem;
}

.getstarted-container {
    width: 350px;
}

.check-video-container,
.check-audio-container {
    width: 850px;
    display: flex;
    gap: 38px;

    .details-container,
    .video-preview-container,
    .audio-preview-container {
        flex: 1;
    }
    .details-container{
        span{
            cursor: pointer;
        }
    }
}

.check-video-container{
    .video-preview-wrapper{

        h3{
            font-size: 14px;
        }

        padding: 28px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .video-dropdown{
            width: 100%;

            select{
                width: 100%;
                background-color: #141414;
                border: 1px solid #909090;
                color: white;
                height: 40px;
                padding: 10px;
                border-radius: 4px;
            }
        }
    }
}
.check-audio-container{
    .audio-preview-wrapper{

        h3{
            font-size: 14px;
        }

        padding: 28px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .audio-dropdown{
            width: 100%;

            select{
                width: 100%;
                background-color: #141414;
                border: 1px solid #909090;
                color: white;
                height: 40px;
                padding: 10px;
                border-radius: 4px;
            }
        }

        .audio-preview-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1rem;
            
            h3{
                margin-bottom: 0;
            }

            button{
                height: 30px;
                padding: 3px 9px;
                border-radius: 4px;
                border: none;

                &:first-child{
                    margin-right: 1rem;
                }
            }
        }

    }

    .microphone-volume-progress-container{
        display: flex;
        gap: 8px;
        align-items: center;
        margin: 24px 0 16px 0;

        .volume-parent{
            position: relative;
            flex: 1;
            height: 4px;
            background-color: rgba(225, 227, 234, 0.2);
            border-radius: 8px;

            #volume-child{
                height: 4px;
                width: 50%;
                border-radius: 8px;
                background: linear-gradient(to right , #1F51FF, #7df9ff);
                transition: all 0.2s linear;
            }

        }
    }

}

.getstarted-container, 
.details-container, 
.check-audio-container {
    h1 {
        margin-bottom: 36px;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.167;
    }
    
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 36px;
        color: white;
    }
    
    button.theme-button {
        color: #fff;
        background-color: #0263E0;
        height: 37px;
        min-width: 64px;
        padding: 6px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 600;
        font-size: 0.9rem;
        box-sizing: border-box;
    }
}

.details-container, 
.check-audio-container {
    span {
        color: #0263E0;
        font-weight: 600;
        margin-left: 2rem;
        font-size: 14px;
        font-family: Inter, sans-serif;
    }
}
