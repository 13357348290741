@font-face {
    font-family: 'IBMPlexSans-Bold';
    font-style:bold;
    font-weight: 700;
    src: local('IBMPlexSans-Bold'), url(../fonts/IBMPlexSans-Bold.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:italic;
    font-weight: 700;
    src: local('IBMPlexSans-BoldItalic'), url(../fonts/IBMPlexSans-BoldItalic.ttf) format('truetype');
  }

@font-face {
    font-family: 'IBMPlexSans';
    font-style:bold;
    font-weight: 200;
    src: local('IBMPlexSans-ExtraLight'), url(../fonts/IBMPlexSans-ExtraLight.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:normal;
    font-weight:200;
    src: local('IBMPlexSans-ExtraLightItalic'), url(../fonts/IBMPlexSans-ExtraLightItalic.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans-Italic';
    font-style:italic;
    font-weight:400;
    src: local('IBMPlexSans-Italic'), url(../fonts/IBMPlexSans-Italic.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:italic;
    font-weight:300;
    src: local('IBMPlexSans-Light'), url(../fonts/IBMPlexSans-Light.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:italic;
    font-weight:300;
    src: local('IBMPlexSans-LightItalic'), url(../fonts/IBMPlexSans-LightItalic.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans-Regular';
    font-style:normal;
    font-weight:400;
    src: local('IBMPlexSans-Regular'), url(../fonts/IBMPlexSans-Regular.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans-Medium';
    font-style:normal;
    font-weight:500;
    src: local('IBMPlexSans-Medium'), url(../fonts/IBMPlexSans-Medium.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:italic;
    font-weight:500;
    src: local('IBMPlexSans-MediumItalic'), url(../fonts/IBMPlexSans-MediumItalic.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:normal;
    font-weight:600;
    src: local('IBMPlexSans-SemiBold'), url(../fonts/IBMPlexSans-SemiBold.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:normal;
    font-weight:600;
    src: local('IBMPlexSans-SemiBoldItalic'), url(../fonts/IBMPlexSans-SemiBoldItalic.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:normal;
    font-weight:100;
    src: local('IBMPlexSans-Thin'), url(../fonts/IBMPlexSans-Thin.ttf) format('truetype');
  }
@font-face {
    font-family: 'IBMPlexSans';
    font-style:normal;
    font-weight:100;
    src: local('IBMPlexSans-ThinItalic'), url(../fonts/IBMPlexSans-ThinItalic.ttf) format('truetype');
  }
@font-face {
  font-family: 'DMSans';
  font-style:normal;
  font-weight:700;
  src: local('DMSans-Bold'), url(../fonts/DMSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSans';
  font-style:normal;
  font-weight:500;
  src: local('DMSans-Medium'), url(../fonts/DMSans-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'DMSans';
  font-style:normal;
  font-weight:400;
  src: local('DMSans-Regular'), url(../fonts/DMSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'NunitoSans-Bold';
  font-style:normal;
  font-weight:700;
  src: local('NunitoSans-Bold'), url(../fonts/NunitoSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'NunitoSans-Light';
  font-style:normal;
  font-weight:500;
  src: local('NunitoSans-Light'), url(../fonts/NunitoSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'NunitoSans-Regular';
  font-style:normal;
  font-weight:400;
  src: local('NunitoSans-Regular'), url(../fonts/NunitoSans-Regular.ttf) format('truetype');
}
