.meeting-login-container {
  background: #000000 url('../Images/video_bg.png') 0% 0% no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  backdrop-filter: blur(10px);
}
.meeting-login-container .meeting-login-div-top {
  height: 150px;
  width: 100%;
  background-color: #000000;
  position: fixed;
  top: 0px;
}
.meeting-login-container .meeting-login-div-bottom {
  height: 150px;
  width: 100%;
  background-color: #000000;
  position: fixed;
  bottom: 0px;
}

.login-modal .login-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 493px px;
  height: 468px px;
  gap: 42px;
}
.login-modal .login-modal-container .login-modal-title-text {
  width: 493px;
  height: 35px;
  gap: 0px;

  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.login-modal .ant-modal-content {
  border-radius: 20px;
  width: 613px;
  height: 588px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141414;
}

.login-modal .login-modal-container .login-modal-form {
  /* width: Fixed (400px) px;
  height: Hug (226px) px; */
  gap: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-modal .login-modal-container .terms-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 493px;
  height: 60px;
  gap: 0px;

  color: #909090;
  font-family: Nunito Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.46px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.login-modal .login-modal-container .terms-container .checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
  color: #909090;
  font-family: Nunito Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.46px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  gap: 5px;
}

.login-modal .login-modal-container .login-modal-form .login-field-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;

  width: 100%;
  color: #FFFFFF;
  /* width: Hug (400px) px;
  height: Hug (40px) px; */
}

.login-modal .login-modal-container .login-modal-form .login-form-divider {
  height: 1px;
  width: 100%;
  border-radius: 2px;
  background: #282829;
}

.login-modal .login-modal-container .login-modal-form .login-captcha-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;

  width: 70%;
  border-radius: 8px;
  border: 1px solid #909090;
  background: #141414;
  padding: 16px;
  height: 40px;
  color: #FFFFFF;
}

.login-modal .login-modal-container .login-modal-form .login-field-container .login-input-field {
  width: 100%;
  height: 40px;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid #909090;
  background: #141414;
  padding: 16px;
  color: #FFFFFF;
}

.login-modal .login-modal-container .login-modal-form .login-field-container .meeting-login-btn {
  background: #6f2dda;
  color: #ffffff;

  font-family: Nunito Sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.46px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-decoration: none;
  width: 60%;
  height: 34px;
  padding: 0px 16px 0px 16px;
  gap: 8px;
  border-radius: 25px;

  border: none;
  text-align: center;
}


/* Error Text */


.login-modal-form .input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.login-modal-form .error-message {
  position: absolute;
  left: 0;
  bottom: -24px;
  color: #ff4d4f;
  font-size: 12px;
}

.login-modal-form .terms-error {
  position: relative;
  bottom: 0;
  margin-top: 4px;
}

.login-modal-form .login-input-field.error {
  border-color: #ff4d4f !important;
}

.login-modal-form .checkbox-container.error .ant-checkbox {
  border-color: #ff4d4f !important;
}

.login-modal-form .checkbox-container.error .ant-checkbox-inner {
  border-color: #ff4d4f !important;
}

.login-modal-form .login-field-container {
  margin-bottom: 8px;
}

.login-modal-form .checkbox-container {
  position: relative;
}

/*  CAPTCHA  CSS  */
.login-modal-container .login-modal-form .login-captcha-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  width: 70%;
  border-radius: 8px;
  border: 1px solid #909090;
  background: #141414;
  padding: 16px;
  height: 40px;
  color: #FFFFFF;
}

/* Captcha CSS */
.login-modal-container .login-modal-form .login-captcha-container .captcha-error-message {
  position: absolute;
  left: 0;
  bottom: 60px;
  color: #ff4d4f;
  font-size: 12px;
  width: 100%;
  text-align: left;
}

.login-modal .login-modal-container .login-modal-form .login-field-container .meeting-login-btn:disabled {
  background-color: #666; 
  color: #999; 
  cursor: not-allowed; 
  opacity: 0.7; 
}
