.zoom-meeting-modal.waiting-room {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.waiting-room-container {
    width: 100%;
    color: white;   
    h4 {
        font-size: 20px;
        text-align: center;
    }

    .request-container {
        width: 100%;
        display: grid;
        gap: 42px;
        grid-template-columns: 224px auto;
        margin-top: 42px;

        .controls-container {
            position: relative;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

           
            .video-container{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                /* align-items: center; */
                font-size: 100px;
                // padding-bottom: 30px;
                // background-color:#6A29FF;

                video{
                    height: 157px !important;
                    object-fit: cover;
                }
            }

            .control-buttons {
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                gap: 16px;

                span {
                    height: 52px;
                    width: 52px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #141414;
                    border-radius: 8px;
                    cursor: pointer;

                }
                svg{
                    // width: 24px;
                    min-height: 25px;
                }
            }
        }

        .details-container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            h2 {
                margin: 0;
            }

            p.time-range {
                color: #888888;
            }

            span {
                color: blue;
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
        }