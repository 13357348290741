.group-card-wrapper .ant-collapse-extra {
    display: flex;
    align-self: center ;
}
.group-list-header-wrapper {
    display: flex;
    flex-direction: row;
}
.card-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.card-item-wrapper .first-child {
  margin-top: 0;
    width: 40%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.card-item-wrapper .last-child {
  margin-top: 0;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.group-card-wrapper {
    margin-top:15px;
}
.ant-collapse:hover {
    background-color: darkgray;
  }
  .user-card {
    border:1px solid #d9d9d9;
    border-radius: 5px;
    /* background-color:#f4f4f4; */
    background-color: rgba(0, 0, 0, 0.02);
    margin-top: 10px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-card > div.data {
    width: 70%;
}

.user-card:hover {
  background-color: darkgray;
}
.gear-image-icon-group {
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  margin: -3px 10px;
  float: right;
  height: 25px;
  width: 23px;
  margin-top: 0px;
  margin-left: 60px;
  cursor: pointer;
}
.edit-icon{
    font-size: 20px;
    /* margin-left: -160px; */
    margin-top: 5px;
  }
  .edit-icon:hover {
    color: blue;
    cursor: pointer;
  } 
  /* .edit-text {
    margin-left: -15px;
  } */
  .edit-text {
    border: none;
    background: none;
    font-weight: 600;
    color:black;
    width: auto;
    margin-left: -15px;
  }
  .scrollable-grp-items-container {
    overflow-y: auto;
    max-height: 720px;
  }
  .scrollable-pub-items-container {
    overflow-y: auto;
    max-height: 730px;
  }
  .invited-users-search-result-new {
    color: black;
    display: flex;
    padding: 10px 80px;
  }