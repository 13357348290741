.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .gallery-mini
  .video-tile-container
  .video-tile
  .video-controls {
  width: 100%;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .video-controls {
  position: absolute;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 10px;
  width: 200px;
  bottom: 0;
  margin: 0px 5px 0px 5px;
  border-radius: 0 4px 0px 4px;
  z-index: 4;
  align-items: center;
  grid-template-columns: auto auto auto auto auto;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .gallery-mini
  .video-tile-container
  .video-tile
  .video-controls {
    position: static;
  }

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .video-controls
  span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .video-controls
  .overlay-audio-off {
  height: 15px;
  font-size: 1em !important;
  background: transparent url('../../../../Images/redmic-off.svg') 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  cursor: pointer;
  margin-left: 5px;
  bottom: 0;
  float: left;
  /* position: relative; */
  /* top: 84%; */
  z-index: 2000;
}
/* .marker {
    color: #1d1d1d;
    padding: 10px;
} */
.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .video-controls
  .overlay-audio-on {
  height: 15px;
  font-size: 1em !important;
  background: transparent url('../../../../Images/microphone.svg') 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  cursor: pointer;
  bottom: 0;
  float: left;
  margin-left: 5px;
  margin-right: 10px;
  /* position: relative;
  top: 84%; */
  z-index: 2000;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .video-controls
  .fullscreen-on {
  height: 15px;
  font-size: 1em !important;
  background: transparent url('../../../../Images/fullscreen_on.svg') 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  cursor: pointer;
  bottom: 0;
  float: left;
  margin-left: 5px;
  /* position: relative;
  top: 84%; */
  z-index: 2000;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .fullscreen-off {
  visibility: hidden;
  margin: 0 5px 0 5px;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .video-controls
  .virtual-bg-menu {
  height: 15px;
  font-size: 1em !important;
  background: transparent url('../../../../Images/menu-vertical.svg') 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  cursor: pointer;
  bottom: 0;
  float: right;
  margin-left: 5px;
  /* position: relative;
  top: 84%; */
  z-index: 2000;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .video-controls
  .overlay-video-on-tray {
  height: 15px;
  font-size: 1em !important;
  background: transparent url('../../../../Images/video_on.svg') 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  bottom: 0;
  float: left;
  margin-left: 5px;
  /* position: relative;
  top: 84%; */
  z-index: 2000;
}

.meeting-room
  .video-tray
  .video-tray-wrapper
  .video-tile-wrapper
  .video-tile-container
  .video-tile
  .video-controls
  .overlay-video-off-tray {
  height: 15px;
  font-size: 1em !important;
  background: transparent url('../../../../Images/redvideo-off.svg') 0% 0% no-repeat padding-box;
  background-size: 13px 14px;
  color: #cccccc;
  bottom: 0;
  float: left;
  margin-left: 5px;
  /* position: relative;
  top: 84%; */
  z-index: 2000;
}

.video-option-wrapper-tile{
  /* height: 81px; */
  width: 240px;
  border-radius: 20px;
}

.video-option-container-tile .option-item{
  color: white;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 24px;
  color: white;
  height: 24px;
  cursor:pointer;
}

.video-option-container-tile .ant-popover-inner{
  background-color: #393939 !important;
  padding: 10px;
  border-radius: 20px;
}

.video-option-container-tile hr{
  border: 0.2px solid #FFFFFF26;
  margin: 1rem 0;
}
