.bar {
    height: 34px;
    width: 22.5px;
    margin-right: 7px;
    border-radius: 8px;
    border: 1px solid lightgray;
}

.bar:last-child {
    margin-right: 0px;
}

.bar.fill {
    background-color: lightgray;
}

.audio-progress {
    display: flex;
}