.zoom-meeting-modal.create-meeting .ant-modal-content {
    padding: 40px 60px;
    height: 64vh;
}

.zoom-meeting-modal.create-meeting .ant-modal-body {
    height: 100%;
}

.zoom-meeting-modal.create-meeting .create-meeting-container {
    height: 100%;
    overflow: auto;
    color: white;
}

.create-meeting-container input {
    background-color: #141414;
    border: 1px solid #909090;
    height: 40px;
    padding: 8px 16px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 24px;
    color: white;
}

.create-meeting-header {
    text-align: center;
    font-weight: 500px;
    font-size: 20px;
    background-color: #000000;
    height: 40px;
    margin-bottom: 42px;
}

.create-meeting-link-container {
    width: 100%;
    height: max-content;
    border: 1px solid #909090;
    border-radius: 8px;
    padding: 8px 16px;
    margin-bottom: 32px;
}


.create-meeting-link-container .create-meeting-link {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    color: #909090;
}

.create-meeting-link-container .create-meeting-link p{
    margin: 0;
}

.create-meeting-dialin{
    color: #909090;
}
.create-meeting-dialin h4{
    color: white;
    margin-bottom: 0;
}

.create-meeting-container .button-container{
    text-align:end;
    gap: 8px;
}

.create-meeting-cancel-button {
    border-radius: 25px;
    font-weight: 600px;
    font-size: 15px;
    height: 34px;
    width: 79px;
    background-color: #141414;
    border: 1px solid #909090;
    color: white;
    margin-right: 8px;
}

.create-meeting-start-button {
    border-radius: 25px;
    font-weight: 600px;
    font-size: 15px;
    height: 34px;
    width:126px;
    background-color:#6F2DDA ;
    border: 1px solid #6F2DDA ;
    color: white;
}

.create-meeting-start-button:disabled {
    background-color: #B09CDC; 
    border: 1px solid #303030; 
}

/* Copy Notification */

.create-meeting-copy-notification {
    position: fixed; 
    transform: translateY(-40px); 
    right: calc(42% - 100px);
    background: rgba(55, 55, 55, 1);
    color: #FFFFFF;
    padding: 4px 8px; 
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    white-space: nowrap;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.create-meeting-copy-notification.visible {
    opacity: 1;
}

